export const COLORS  = {
  purple_1: '#666699',
  green_1: '#6dbb9c',
  blue: '#008db7',
  rose: '#e73b94',
  red: '#cc3333',
  yellow: '#ffcc66',
  purple_2: '#7c6491',
  orange: '#fe863d',
  verde_2: '#c8c94d',
  brown: '#a2766e',
  grey: '#737a84'
};