import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import '../../../../styles/styles.css';
import './Pagination.css';

class Pagination extends Component {
  render() {
    const { current, handleChangeCurrent,
      handleChangePrev, handleChangeNext, list, totalPages, resource } = this.props;

    return (
      <div className='list-pagination'>
        {list && list.length && list[0] !== 0 ?
          <div className={'item-pagination arrow ' + resource}>
            <ChevronLeftIcon onClick={() => handleChangePrev()} />
          </div> : ''}
        {list.map((number) => (
          <div className={number === current ? 'item-pagination current ' + resource : 'item-pagination'}
            key={number} onClick={() => handleChangeCurrent(number)}> {number + 1} </div>
        ))}
        {list && list.length && list[list.length - 1] < (totalPages - 1) ?
          < div className={'item-pagination arrow ' + resource}>
            <ChevronRightIcon onClick={() => handleChangeNext()} />
          </div> : ''
        }
      </div>
    );
  }
}

Pagination.propTypes = {
  current: PropTypes.number,
  totalPages: PropTypes.number,
  list: PropTypes.arrayOf(PropTypes.number),
  handleChangeCurrent: PropTypes.func,
  handleChangePrev: PropTypes.func,
  handleChangeNext: PropTypes.func,
  resource: PropTypes.string,
};

export default withTranslation()(Pagination);