import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CardGalleryItem from './CardGalleryItem';
import { withRouter } from 'react-router';

import { withTranslation } from 'react-i18next';

import LogService from '../../services/LogService';
import { events, eventsDescriptions } from '../../data/data';

import '../../styles/styles.css';
import './Gallery.css';

const styles = {
  card: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
};

class Gallery extends Component {
  componentDidMount() {
    LogService.trackEvent(events["page_loaded"], {
      namePage: "gallery",
      description: eventsDescriptions["page_loaded"],
    });
  }

  render() {
    const { t } = this.props;
    const visualizations = [
      {
        image: { url: '/images/charts/1_1.jpg', title: t('BIBLE_ANALYSIS.LIST_VISUALIZATION.0.TITLE') },
        title: t('BIBLE_ANALYSIS.LIST_VISUALIZATION.0.TITLE'),
        description: t('BIBLE_ANALYSIS.LIST_VISUALIZATION.0.DESCRIPTION'),
        link: '/#/books'
      },
      {
        image: { url: '/images/charts/1_3.jpg', title: t('BIBLE_ANALYSIS.LIST_VISUALIZATION.1.TITLE') },
        title: t('BIBLE_ANALYSIS.LIST_VISUALIZATION.1.TITLE'),
        description: t('BIBLE_ANALYSIS.LIST_VISUALIZATION.1.DESCRIPTION'),
        link: '/#/treemap'
      },
      {
        image: { url: '/images/charts/2_2.jpg', title: t('BIBLE_ANALYSIS.LIST_VISUALIZATION.2.TITLE') },
        title: t('BIBLE_ANALYSIS.LIST_VISUALIZATION.2.TITLE'),
        description: t('BIBLE_ANALYSIS.LIST_VISUALIZATION.2.DESCRIPTION'),
        link: '/#/timeline_books'
      },
      {
        image: { url: '/images/charts/3_1.jpg', title: t('BIBLE_ANALYSIS.LIST_VISUALIZATION.3.TITLE') },
        title: t('BIBLE_ANALYSIS.LIST_VISUALIZATION.3.TITLE'),
        description: t('BIBLE_ANALYSIS.LIST_VISUALIZATION.3.DESCRIPTION'),
        link: '/#/maps'
      },
      {
        image: { url: '/images/charts/5_1.jpg', title: t('BIBLE_ANALYSIS.LIST_VISUALIZATION.4.TITLE') },
        title: t('BIBLE_ANALYSIS.LIST_VISUALIZATION.4.TITLE'),
        description: t('BIBLE_ANALYSIS.LIST_VISUALIZATION.4.DESCRIPTION'),
        link: '/#/tree'
      },
      {
        image: { url: '/images/charts/4_1.jpg', title: t('BIBLE_ANALYSIS.LIST_VISUALIZATION.5.TITLE') },
        title: t('BIBLE_ANALYSIS.LIST_VISUALIZATION.5.TITLE'),
        description: t('BIBLE_ANALYSIS.LIST_VISUALIZATION.5.DESCRIPTION'),
        link: '/#/cloud'
      },
      {
        image: { url: '/images/charts/4_4.jpg', title: t('BIBLE_ANALYSIS.LIST_VISUALIZATION.6.TITLE') },
        title: t('BIBLE_ANALYSIS.LIST_VISUALIZATION.6.TITLE'),
        description: t('BIBLE_ANALYSIS.LIST_VISUALIZATION.6.DESCRIPTION'),
        link: '/#/network'
      },
      {
        image: { url: '/images/charts/7_1.jpg', title: t('BIBLE_ANALYSIS.LIST_VISUALIZATION.7.TITLE') },
        title: t('BIBLE_ANALYSIS.LIST_VISUALIZATION.7.TITLE'),
        description: t('BIBLE_ANALYSIS.LIST_VISUALIZATION.7.DESCRIPTION'),
        link: '/#/prophecy'
      },
      {
        image: { url: '/images/charts/6_2.jpg', title: t('BIBLE_ANALYSIS.LIST_VISUALIZATION.8.TITLE') },
        title: t('BIBLE_ANALYSIS.LIST_VISUALIZATION.8.TITLE'),
        description: t('BIBLE_ANALYSIS.LIST_VISUALIZATION.8.DESCRIPTION'),
        link: '/#/reference#r02'
      }
    ]
    return (
      <div id='gallery'>
        <div className='content'>
          <h2 className="be-title-page"> {t('BIBLE_ANALYSIS.TITLE')} </h2>
          <p> {t('BIBLE_ANALYSIS.TEXT')} </p>

          {visualizations && visualizations.length && visualizations.map((item, id) => (
            <CardGalleryItem
              key={id}
              imageId={item.image.url}
              imageTitle={item.image.title}
              title={item.title}
              description={item.description}
              link={item.link}
            ></CardGalleryItem>
          ))}
        </div>
      </div>
    );
  }
}

Gallery.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object
};

export default withRouter(withStyles(styles)(withTranslation()(Gallery)));