import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import Header from './components/header/Header.js';
import Footer from './components/footer/Footer.js';
import Home from './screens/home/Home.js';
import About from './screens/about/About.js';
import Bible from './screens/bible/Bible.js';
import VerseBibleId from './screens/bible/VerseBibleId.js';
import Gallery from './screens/gallery/Gallery.js';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { HashRouter, Route, Switch } from "react-router-dom";

import { Provider } from 'react-redux';
import { Store } from './store';
import Visualization from './screens/gallery/Visualization';
import Search from './screens/search/Search';
import SearchHome from './screens/search/SearchHome';
import StepInitialForm from './screens/evaluate/steps/initialForm/StepInitialForm';
import StepSearchEvaluation from './screens/evaluate/steps/evaluateSearch/StepSearchEvaluation';

import { createBrowserHistory } from 'history';
import Logs from './screens/logs/Logs';
import LogsEvaluation from './screens/logs/LogsEvaluation';
import ReactGA from 'react-ga';
import LogsExplicability from './screens/logs/LogsExplicability';

ReactGA.initialize('UA-158094987-1');

const history = createBrowserHistory();

ReactDOM.render(
  <HashRouter basename='/' history={history}>
    <I18nextProvider i18n={i18n}>
      <Provider store={Store}>
        <Header></Header>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/v2" component={SearchHome} />
          <Route path="/about" component={About} />
          <Route path="/bible" component={Bible} />
          <Route path="/viz" component={Visualization} />
          <Route path="/bible/:book" component={Bible} />
          <Route path="/bible-id/:verseId" component={VerseBibleId} />
          <Route path="/gallery" component={Gallery} />
          <Route path="/search/:term" component={Search} />
          <Route path="/search/" component={Search} />
          <Route path="/project" component={Home} />
          <Route path="/home" component={Home} />
          <Route path="/evaluate" component={StepInitialForm} />
          <Route path="/evaluate-search/:term" component={StepSearchEvaluation} />
          <Route path="/logs-biblexplorer-2020/logs" component={Logs} />
          <Route path="/logs-biblexplorer-2020/evaluation" component={LogsEvaluation} />
          <Route path="/logs-biblexplorer-2020/explicability" component={LogsExplicability} />
          <Route path="/logs-biblexplorer-2020" component={LogsEvaluation} />
        </Switch>
        <Footer></Footer>
      </Provider>
    </I18nextProvider>
  </HashRouter>
  , document.getElementById('root'));

serviceWorker.unregister();
