import * as d3 from 'd3v3';
import { COLORS } from '../../theme/Colors';

const draw = (data, method, word, showResultsBySearch) => {

  const widthChart = window.innerWidth >= 653 ? 500 : (window.innerWidth - 153);

  var margin = {
    top: 15,
    right: 25,
    bottom: 15,
    left: 120
  };

  var width = widthChart - margin.left - margin.right,
    height = data.length * 20;

  var svg = d3.select("#bar-chart-component-" + method).append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

  var x = d3.scale.linear()
    .range([0, width - margin.left - margin.right])
    .domain([0, d3.max(data, function (d) {
      return d.count;
    })]);

  var y = d3.scale.ordinal()
    .rangeRoundBands([height, 0], .85)
    .domain(data.map(function (d) {
      return d.word;
    }));

  var yAxis = d3.svg.axis()
    .scale(y)
    .tickSize(20)
    .orient("left");

  svg.append("g")
    .attr("class", "y axis")
    .call(yAxis);

  const handleMouseOver = (d, i) => {
    showResultsBySearch(d, method);
  };

  const handleMouseOut = () => {
    showResultsBySearch('', method);
  };

  svg.select(".y.axis")
    .selectAll(".tick")
    .on("mouseover", handleMouseOver)
    .on("mouseout", handleMouseOut);

  var bars = svg.selectAll(".bar")
    .data(data)
    .enter()
    .append("g")

  bars.append("rect")
    .attr("class", "bar")
    .attr('fill', () => method === 'w2v' ? COLORS.purple_1 : COLORS.rose)
    .attr("y", function (d) {
      return y(d.word);
    })
    .attr("height", y.rangeBand())
    .attr("x", 0)
    .attr("width", 0)
    .transition()
    .duration(200)
    .delay(function (d, i) {
      if (!i) {
        i = 1;
      }
      return 100 / i;
    })
    .attr("width", function (d) {
      return x(d.count);
    });

  bars.append("text")
    .attr("class", "label")
    .style("font-size", function (d) {
      // return d.count / 150 + 10;
      return 14;
    })
    .attr("y", function (d) {
      return y(d.word) + y.rangeBand() / 2 + 4;
    })
    .attr("x", function (d) {
      return x(d.count) + 10;
    })
    .text(function (d) {
      return d.count;
    });
};

export default draw;