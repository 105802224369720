import React, { Component } from 'react';
import { withRouter } from 'react-router';

import { BOOKS_ID } from '../../data/data';
import { convertToSlug } from '../../utils/utils';

class VerseBibleId extends Component {
  constructor(props) {
    super(props);

    this.state = {
      verseId: ''
    };
  }


  componentWillMount() {
    const books = BOOKS_ID;
    let url = '';
    let { verseId } = this.props.match.params;

    const regexBook = /([^\s]+)/;
    let bookAbv = verseId.match(regexBook) && verseId.match(regexBook)[0] && verseId.match(regexBook)[0].replace(' ', '');
    bookAbv = convertToSlug(bookAbv);

    let book = books[bookAbv];

    const regexCap = / [^(,|:|;|\-)]+/;
    let cap = verseId.match(regexCap) && verseId.match(regexCap)[0] && verseId.match(regexCap)[0]
      .replace(' ', '').replace(',', '').replace(':', '').replace('-', '');

    const regexVers = /(,|:|;|-)[^(,|:|;|\-)]+/;
    let ver = verseId.match(regexVers) && verseId.match(regexVers)[0] && verseId.match(regexVers)[0]
      .replace(' ', '').replace(',', '').replace(':', '').replace('-', '');

    const bookNumberId = book && book.id && book.id - 1;
    url = `/bible?b=${bookNumberId}`;

    url += cap ? `&c=${cap}` : '';
    url += (cap && ver) ? `&v=${ver}` : '';

    if (!cap || !ver) {
      url = `/bible/${bookNumberId}`
    }

    this.props.history.push({ pathname: url });
    window.location.reload();
  }

  render() {

    return (
      <div id='verse-bible-id'>

      </div>
    );
  }
}

export default withRouter(VerseBibleId);