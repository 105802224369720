import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import '../../styles/styles.css';
import './AuthInput.css';

class AuthInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: ''
    };

    this.handleChangeCode = this.handleChangeCode.bind(this);
    this.sendCodeToScreen = this.sendCodeToScreen.bind(this);
  }

  handleChangeCode = (event) => {
    this.setState({ code: event.target && event.target.value });
  };

  sendCodeToScreen = () => {
    const { code } = this.state;
    const { sendCode } = this.props;
    sendCode(code);
  }

  render() {
    const { code } = this.state;

    return (
      <div className='auth-input'>
        <p> Relatórios de uso da aplicação </p>
        <input
          type='text'
          placeholder='Digite o código de autorização'
          onChange={e => this.handleChangeCode(e)}
          value={code}
        ></input>
        <button onClick={() => this.sendCodeToScreen()}> Enviar </button>
      </div>
    )
  };
};
export default withRouter(withTranslation()(AuthInput));