import React from 'react';
import { getTextByQuestionAndAnswer } from '../../../utils/utils';

import '../../../styles/styles.css';
import './AreaCharts.css';
import PieChart from '../../charts/pie/PieChart';

const convertData = (array, field, question) => array && array.length && array.map((item) => {
  const convertedDataAnswer = getTextByQuestionAndAnswer(question, item[field]);
  const labelQuestion = convertedDataAnswer && convertedDataAnswer.text;
  const valueWeight = convertedDataAnswer && convertedDataAnswer.weight;

  return {
    label: labelQuestion,
    value: item.count,
    weight: valueWeight
  }
});

const AreaCharts = ({ data }) => {
  const ages = convertData(data.ages, 'age', 'AGE') || [];
  const genders = convertData(data.genders, 'gender', 'GENDER') || [];
  const likeds = convertData(data.liked, 'liked', 'LIKE') || [];
  const trust = convertData(data.trust, 'trust', 'TRUST') || [];
  const understand = convertData(data.understand, 'understand', 'UNDERSTAND') || [];

  return (
    <div id='area-charts'>
      <h2> Informações demográficas </h2>
      <div className='area-box-charts'>
        <PieChart results={ages} id='ages' />
        <PieChart results={genders} id='genders' />
      </div>

      <h2> Você gostou dos resultados? </h2>
      <div className='area-box-charts'>
        <PieChart results={likeds} id='likeds' />
      </div>

      <h2> Você entendeu como sua consulta foi feita? </h2>
      <div className='area-box-charts'>
        <PieChart results={understand} id='understand' />
      </div>

      <h2> Você confiaria nesta aplicação para fazer novas consultas?</h2>
      <div className='area-box-charts'>
        <PieChart results={trust} id='trust' />
      </div>

    </div>
  )
};
export default AreaCharts;