import React from 'react';
import ReactWordcloud from 'react-wordcloud';
import '../../../styles/styles.css';
import './WordCloud.css';

const WordCloud = ({ data }) => {
  const options = {
    rotations: 1,
    rotationAngles: [0],
    deterministic: true,
    padding: 7,
    fontSizes: [10, 90],
    fontStyle: "normal",
    fontWeight: "normal",
  };

  return (
    <div className='area-word-cloud' style={{ height: 600, width: 1000 }}>
      <ReactWordcloud
        options={options}
        words={data} />
    </div>
  );
};

export default WordCloud;