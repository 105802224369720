import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import SearchToolBar from '../../components/searchToolbar/SearchToolBar';
import { withTranslation } from 'react-i18next';
import '../../styles/styles.css';
import './Search.css';
import queryString from 'query-string';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { clickShowAdvancedQuery } from '../../actions';

import { API_URL } from '../../config';
import { saveSearchData, saveModelData } from '../../actions';

import Results from './tabs/results/Results';
import SearchExplicability from './tabs/searchExplicability/SearchExplicability';

import LogService from '../../services/LogService';
import { events, eventsDescriptions } from '../../data/data';

import { convertToSlug, isTextBibleId } from '../../utils/utils';
import { BOOKS_BIBLE } from '../../data/data';

const clearTabs = {
  all: '',
  direct: '',
  concordance: '',
  w2v: '',
  explicability_search: '',
  explicability_model: '',
  showResults: []
};

const styles = {
  card: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
};

class Search extends Component {
  constructor(props) {
    super(props);
    const paramsQuery = this.getQueryString();

    this.state = {
      advancedQuery: paramsQuery,
      verses: [],
      infoTerm: {},
      loading: false,
      selected: {},
      query: null,
      sortOption: {
        'relevance': 'selected',
        'occurrence': ''
      },
      resourceType: {
        type: 'all',
        resource: 'all'
      },
      tab: {
        all: 'selected',
        direct: '',
        concordance: '',
        w2v: '',
        explicability_search: '',
        explicability_model: ''
      }
    };

    this.sortVersesByType = this.sortVersesByType.bind(this);
    this.showAdvanced = this.showAdvanced.bind(this);
    this.handleChangeResource = this.handleChangeResource.bind(this);
    this.handleChangeTab = this.handleChangeTab.bind(this);
  }

  getQueryString = () => {
    const strQuery = this.props.location.search;
    return (Boolean(strQuery) ? queryString.parse(strQuery) : '');
  };

  getResults = async (query) => {
    await fetch(API_URL + '/info/' + query)
      .then(response => response.json())
      .then(data => {
        this.setState({
          infoTerm: data
        });
      });
  }

  getVerses = async (query) => {
    this.setState({
      verses: []
    });

    await fetch(API_URL + '/search/' + query)
      .then(response => response.json())
      .then(data => {
        this.setState({
          verses: data,
          loading: false
        });
      });
  }

  getDataModel = (query) => {
    const { saveModelData } = this.props;
    fetch(API_URL + '/model/' + query)
      .then(response => response.json())
      .then(data => {
        saveModelData(data);
      });
  }

  categorizeResults = (verses, query) => {
    let result = {
      direct: verses && verses.length ? verses.filter((verse) =>
        verse && verse.search === query && !verse.type
      ) : [],
      concordance: verses && verses.length ? verses.filter((verse) =>
        verse && verse.type && verse.type.includes("concordance")
      ) : [],
      word2vec: verses && verses.length ? verses.filter((verse) =>
        verse && verse.search !== query && !verse.type
      ) : [],
      all: verses
    }
    return result;
  }

  replacer = (match, p1, string) => {
    return p1;
  };

  sortByRelevance = (a, b) => {
    if (a && a.hasOwnProperty('kr') && b && b.hasOwnProperty('kr')) {
      return a.kr - b.kr
    }
  };

  sortByOccurrence = (a, b) => {
    if (a && a.hasOwnProperty('obj') && a.obj.hasOwnProperty('b') &&
      b && b.hasOwnProperty('obj') && b.obj.hasOwnProperty('b') && a.obj.b !== b.obj.b) {
      return a.obj.b - b.obj.b;
    }
    if (a && a.hasOwnProperty('obj') && a.obj.hasOwnProperty('c') &&
      b && b.hasOwnProperty('obj') && b.obj.hasOwnProperty('c') && a.obj.c !== b.obj.c) {
      return a.obj.c - b.obj.c;
    }
    if (a && a.hasOwnProperty('obj') && a.obj.hasOwnProperty('v') &&
      b && b.hasOwnProperty('obj') && b.obj.hasOwnProperty('v') && a.obj.v !== b.obj.v) {
      return a.obj.v - b.obj.v;
    }
  }

  changeStyle = (type) => {
    switch (type) {
      case "relevance":
        this.setState({
          sortOption: {
            'relevance': 'selected',
            'occurrence': ''
          }
        });
        break;

      case "occurrence":
        this.setState({
          sortOption: {
            'relevance': '',
            'occurrence': 'selected'
          }
        });
        break;

      default:
        this.setState({
          sortOption: {
            'relevance': 'selected',
            'occurrence': ''
          }
        });
        break;
    }
  };

  sortByFunc = (fn) => {
    const { showResults } = this.state;

    if (showResults && showResults.length) {
      const newSortedResults = showResults.sort(fn);
      this.setState({ showResults: JSON.parse(JSON.stringify(newSortedResults)) });
    }
  };

  sortVersesByType = (type) => {
    const query = this.props.match.params.term;
    switch (type) {
      case "relevance":
        LogService.trackEvent(events["sort_results_changed_to_relevance"], {
          sortBy: type,
          term: query,
          namePage: "search",
          description: eventsDescriptions["sort_results_changed_to_relevance"],
        });
        this.changeStyle("relevance");
        this.sortByFunc(this.sortByRelevance)
        break;

      case "occurrence":
        LogService.trackEvent(events["sort_results_changed_to_occurrence"], {
          sortBy: type,
          term: query,
          namePage: "search",
          description: eventsDescriptions["sort_results_changed_to_occurrence"],
        });
        this.changeStyle("occurrence");
        this.sortByFunc(this.sortByOccurrence)
        break;

      default:
        this.changeStyle("relevance");
        this.sortByFunc(this.sortByRelevance)
        break;
    }
  };

  convertVerseIdToObject = (verseId) => {
    let stringVerse = convertToSlug(verseId);
    stringVerse = stringVerse.replace(/(^\d)-/, this.replacer);

    const partsVerse = stringVerse.split('-');
    const bookString = partsVerse[0] || 1;
    const book = BOOKS_BIBLE[bookString] && BOOKS_BIBLE[bookString].id
      && BOOKS_BIBLE[bookString].id - 1;
    const cap = partsVerse[1] || 1;
    const ver = partsVerse[2] || 1;

    return {
      b: parseInt(book),
      c: parseInt(cap),
      v: parseInt(ver)
    }
  }

  insertIndexVerses = (verses) => {
    verses && verses.length && verses.map((verse, key) => {
      if (verse && verse.id) {
        verse.kr = key;
        verse.obj = this.convertVerseIdToObject(verse.id);
        return verse;
      }
      return verse;
    });
    return verses;
  };

  getClearTabs() {
    return JSON.parse(JSON.stringify(clearTabs));
  }

  handleChangeResource = (type, resource) => {
    const { searchResult } = this.props;
    const query = this.props.match.params.term;
    let tabs = this.getClearTabs();
    tabs[type] = 'selected';

    const sv = searchResult && searchResult.verses;

    const categorizedResults = this.categorizeResults(sv, query);

    const { clickShowAdvancedQuery, show } = this.props;
    if (show !== 'results_search') {
      clickShowAdvancedQuery('results_search');
    }

    this.setState({
      resourceType: {
        type: type,
        resource: resource
      },
      tab: tabs,
      showResults: categorizedResults[resource] || []
    }, () => {
      this.sortVersesByType('relevance');
    });

    LogService.trackEvent(events[`tab_viewed_${resource}`], {
      tab: resource,
      term: query,
      namePage: "search",
      description: eventsDescriptions[`tab_viewed_${resource}`],
    });
  }

  understandQuery = async (query) => {
    const partsQuery = query.split(" ");
    partsQuery.filter((p) => p.length > 1)

    if (partsQuery.length === 1) {
      return query;
    } else {
      let result = '';
      const body = {
        words: partsQuery
      };

      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      };

      await fetch(API_URL + '/indexes/', options)
        .then(response => response.json())
        .then(data => {
          result = data;
        });
      return result;
    }
  };

  updateQuery = async (query, lang) => {
    const { saveSearchData } = this.props;
    const { resourceType } = this.state;

    saveSearchData(null);

    if (!query) {
      query = (this.props.match.params.term);
    }
    if (query) {
      this.setState({
        loading: true
      });

      const isBibleId = isTextBibleId(query);
      if (isBibleId.is) {
        this.props.history.push(`/bible/${isBibleId.value}`);
      }

      const newQuery = await this.understandQuery(query);
      const nQuery = (typeof newQuery === 'object' && newQuery.length && newQuery[0]) ? (newQuery[0] && newQuery[0].name) : query;

      this.getDataModel(nQuery);
      await this.getResults(nQuery);
      await this.getVerses(nQuery);

      let versesState = this.state.verses;
      versesState = this.insertIndexVerses(versesState);
      const objResult = {
        query: query,
        verses: versesState,
        info: this.state.infoTerm
      };

      let categorizedResults = objResult && objResult.verses
        && this.categorizeResults(objResult.verses, query);

      this.setState({ showResults: categorizedResults[resourceType.resource] || [] });

      LogService.trackEvent(events["term_searched"], {
        term: query,
        namePage: "search",
        description: eventsDescriptions["term_searched"],
      });
      saveSearchData(objResult);
      this.changeResourceTabByQueryString();
    }
  }

  componentWillMount() {
    const { searchResult, translate } = this.props;
    const query = this.props.match.params.term;
    const hasResultsQuery = searchResult && searchResult.query ? true : false;

    if (!hasResultsQuery || searchResult.query !== query) {
      this.updateQuery(query, translate);
    }
  }
  isValidAdvancedQueryTab = (tab) =>
    Boolean(tab === 'concordance' || tab === 'w2v' || tab === 'direct' || tab === 'all');

  changeResourceTabByQueryString = () => {
    const paramsQuery = this.getQueryString();

    if (Boolean(paramsQuery.tab) && this.isValidAdvancedQueryTab(paramsQuery.tab)) {
      const type = paramsQuery.tab;
      const resource = (paramsQuery.tab === 'w2v') ? 'word2vec' : paramsQuery.tab;
      this.handleChangeResource(type, resource);
    } else {
      this.handleChangeResource('all', 'all');
    }
  }

  componentDidMount() {
    const query = this.props.match.params.term;
    this.changeResourceTabByQueryString();

    LogService.trackEvent(events["page_loaded"], {
      namePage: "search",
      term: query,
      description: eventsDescriptions["page_loaded"],
    });
  }

  componentDidUpdate(prevProps) {
    const { searchResult, translate, show } = this.props;
    const query = this.props.match.params.term;
    const prevQuery = searchResult && searchResult.query;
    const { resourceType, tab } = this.state;

    if (prevProps.translate !== translate) {
      this.updateQuery(query, translate);
    }

    if (query && prevQuery && prevQuery !== query) {
      this.setState({
        loading: true
      });
      this.updateQuery(query, translate);
    }

    if (show === 'results_search' && resourceType.type === 'all' && tab.all !== 'selected') {
      this.handleChangeResource('all', 'all');
    }
  }

  getOptions() {
    const strQuery = this.props.location.search;
    const paramsQuery = Boolean(strQuery) ? queryString.parse(strQuery) : '';
    this.setState({
      advancedQuery: paramsQuery
    });
  }

  showAdvanced() {
    this.getOptions()
  }

  handleChangeTab(tab) {
    const { clickShowAdvancedQuery } = this.props;
    const query = this.props.match.params.term;

    clickShowAdvancedQuery(tab);

    let tabs = this.getClearTabs();
    tabs[tab] = 'selected';
    this.setState({
      tab: tabs
    });

    LogService.trackEvent(events["tab_viewed_explicability"], {
      tab: tab,
      term: query,
      namePage: "search",
      description: eventsDescriptions["tab_viewed_explicability"],
    });
  }

  render() {
    const { t, show, searchResult, dataModel } = this.props;
    const { sortOption, resourceType, tab, showResults } = this.state;
    let { term } = this.props.match.params;
    let urlGraph = API_URL + '/search/' + term;
    const hasErrorOnDataModel = (dataModel && dataModel.tsne && dataModel.tsne[0] && dataModel.tsne[0].error === true) || (dataModel && !dataModel.tsne) || !dataModel;
    const hasSearchResults = (searchResult && searchResult.verses && searchResult.verses.length);

    return (
      <div id='search' >
        {((searchResult && hasSearchResults) &&
          <SearchToolBar
            tab={tab}
            hasSearchResults={hasSearchResults}
            hasErrorOnDataModel={hasErrorOnDataModel}
            searchResult={searchResult}
            term={term}
            handleChangeResource={this.handleChangeResource}
            handleChangeTab={this.handleChangeTab}
          ></SearchToolBar>) || ''
        }

        {((show === 'results_search'
          && (!searchResult || !searchResult.verses)
          && <div className="loading-be">
            <img alt="imagem-ilustrativa" src="/images/loading_be.gif" />
            <p> Pesquisando a palavra <span className="be-color-blue be-featured">{term}</span> na Bíblia...</p>
            <img alt="imagem-ilustrativa" src="/images/biblia_loading.gif" />
          </div>) || '')
        }

        {
          ((show === 'explicability_search'
            && <SearchExplicability url={urlGraph} word={term}></SearchExplicability>)
            || '')
        }

        {
          ((show === 'explicability_model' && !dataModel
            && <div className="empty-state">
              <img alt="imagem-ilustrativa" src="/images/loading_be.gif" />
              <p>
                Carregando dados...
          </p>
            </div>) || '')
        }

        {
          ((show === 'explicability_model' && dataModel && hasErrorOnDataModel
            && <div className="empty-state">
              <img alt="imagem-ilustrativa" src="/images/error.gif" />
              <p>
                Ops. Um erro ocorreu!
        </p>
            </div>) || '')
        }

        {
          (((show === 'results_search' && hasSearchResults)
            && (<div className='content results'>

              <h2 className="be-title-page">
                {t('SEARCH_RESULTS.TITLE_PART_1')} <span> {term}</span> {t('SEARCH_RESULTS.TITLE_PART_2')}
              </h2>
              <p className="select-sort">
                Ordenar resultados por:
              <span className={sortOption['relevance']}
                  onClick={() => this.sortVersesByType('relevance')}>
                  Relevância
              </span> |
              <span className={sortOption['occurrence']}
                  onClick={() => this.sortVersesByType('occurrence')}>
                  Ocorrência no texto
              </span>
              </p>
              <Results verses={showResults}
                infoTerm={searchResult && searchResult.info}
                resourceType={resourceType}></Results>
            </div>)) || '')
        }
        {
          (((show === 'results_search' && searchResult && !hasSearchResults)
            && <div className="empty-state">
              <img alt="imagem-ilustrativa" src="/images/lupa.png" />
              <p>
                Nenhum resultado encontrado para a consulta "<span className="be-color-blue be-featured">{term}</span>".
            </p>
            </div>) || '')
        }
      </div >
    );
  }
}

Search.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ clickShowAdvancedQuery, saveSearchData, saveModelData }, dispatch);

const mapStateToProps = store => ({
  show: store.searchState.show,
  searchResult: store.searchState.searchResult,
  dataModel: store.searchState.dataModel
});

export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Search))));
