import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import '../../styles/styles.css';
import './Title.css';

class Title extends Component {
  render() {
    const { direction, number, text1, text2, detail, color } = this.props;

    return (
      <div>
        {direction === 'left'
          ?
          <div className='title-infographs'>
            <div className={'detail-title d' + number} ></div>
            <p className='title-text'>
              <span> {text1}
                <span className={color}> {detail} </span>
                {text2}
              </span>
            </p>
          </div>
          :
          <div className='title-infographs'>
            <p className='title-text'>
              <span> {text1}
                <span className={color}> {detail} </span>
                {text2}
              </span>
            </p>
            <div className={'detail-title d' + number} ></div>
          </div>
        }
      </div>
    );
  }
}

Title.propTypes = {
  direction: PropTypes.string,
  number: PropTypes.string,
  text1: PropTypes.string,
  detail: PropTypes.string,
  text2: PropTypes.string,
  color: PropTypes.string
};

export default withTranslation()(Title);