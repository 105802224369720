import React, { Component } from 'react';
import PropTypes from 'prop-types';
import VerseResult from '../../../../components/verseResult/VerseResult';
import { withTranslation } from 'react-i18next';

import LogService from '../../../../services/LogService';
import { events, eventsDescriptions } from '../../../../data/data';

import '../../../../styles/styles.css';
import './ResultsByPage.css';
import Pagination from './Pagination';

let timer;

class ResultsByPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      time: 0,

    }
  }

  setTime(start, verse, query) {
    let n = 1;
    let logged = false;
    if (start) {
      timer = setInterval(function () {
        n++;
        if (n > 5 && !logged) {
          logged = true;
          LogService.trackEvent(events["verse_relevant_hover"], {
            term: query,
            verse: verse && verse.id,
            namePage: "search",
            description: eventsDescriptions["verse_relevant_hover"],
          });
        }
      }, 1000);
    } else {
      clearInterval(timer);
    }
  }

  render() {
    const { current, list, handleChangeCurrent, handleChangeNext, handleChangePrev,
      lang, selected, query, resource, objPagination, listNumbers, totalPages } = this.props;

    return (
      <div className='results-by-page'>
        {
          (list && list.length) ? list.map((verse, id) => (
            verse && verse.id &&
            <div
              onMouseEnter={() => this.setTime(true, verse, query)}
              onMouseLeave={() => this.setTime(false, verse, query)}
              key={id}
            >
              <VerseResult
                key={id}
                verse={verse}
                id={id}
                lang={lang}
                selected={selected}
                query={query}
                resource={resource}
              />
            </div>
          )) : ''
        }

        {
          ((objPagination && objPagination.length) &&
            <Pagination
              current={current}
              handleChangeNext={handleChangeNext}
              handleChangePrev={handleChangePrev}
              totalPages={totalPages}
              resource={resource}
              handleChangeCurrent={handleChangeCurrent}
              list={listNumbers} />) || ''
        }
      </div>
    );
  }
}

ResultsByPage.propTypes = {
  current: PropTypes.number,
  list: PropTypes.arrayOf(PropTypes.object),
  listNumbers: PropTypes.arrayOf(PropTypes.number),
  objPagination: PropTypes.arrayOf(PropTypes.array),
  handleChangeCurrent: PropTypes.func,
  lang: PropTypes.string,
  resource: PropTypes.string,
  query: PropTypes.string,
  selected: PropTypes.object,
  handleChangePrev: PropTypes.func,
  handleChangeNext: PropTypes.func,
  totalPages: PropTypes.number,
};

export default withTranslation()(ResultsByPage);