export const convertToSlug = (text) => text;

export const data = require('../data/data');
export const BOOKS_BIBLE = data.BOOKS_BIBLE;

const BOOKS_NAME_IDS = data.BOOKS_NAME_IDS;

export const removeAccent = (str) => {
  const a = 'àáäâãèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ'
  const b = 'aaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh'
  const p = new RegExp(a.split('').join('|'), 'g')

  return str.toString().toLowerCase().trim()
    .replace(p, c => b.charAt(a.indexOf(c)))
}

export const convertTextInBibleId = (str) => {
  str = removeAccent(str, true);
  if (str) {
    const bookString = str.match(/^.* /g)[0].replace(/ /g, '').toLowerCase();
    const capId = str.match(/[a-z] .*:/)[0].match(/[0-9][0-9]*/)[0];
    const versId = str.match(/:.*$/g)[0].replace(/:/, '');
    const bookId = BOOKS_BIBLE[bookString];

    return { b: bookId, c: capId, v: versId };
  }
  return null;
};

export const getLinksByDictionaryText = (str) => {
  const n = '( *[0-9][0-9]*|[0-9][0-9]*)';
  const books = 'Genesis|Gn|Gênesis|Gn|Exodo|Ex|Êxodo|Êx|Levitico|Lv|Levítico|Lv|Numeros|Nm|Números|Nm|Deuteronomio|Dt|Deuteronômio|Dt|Josue|Js|Josué|Js|Juizes|Jz|Juízes|Jz|Rute|Rt|Rute|Rt|1 Samuel|1Sm|1 Samuel|1Sm|2 Samuel|2Sm|2 Samuel|2Sm|1 Reis|1Rs|1 Reis|1Rs|2 Reis|2Rs|2 Reis|2Rs|1 Cronicas|1Cr|1 Crônicas|1Cr|2 Cronicas|2Cr|2 Crônicas|2Cr|Esdras|Ed|Esdras|Ed|Neemias|Ne|Neemias|Ne|Ester|Et|Ester|Et|Jo|Jó|Jó|Jó|Salmos|Sl|Salmos|Sl|Proverbios|Pv|Provérbios|Pv|Eclesiastes|Ec|Eclesiastes|Ec|Cantares|Ct|Cantares|Ct|Canticos|Cânticos Isaias|Is|Isaías|Is|Jeremias|Jr|Jeremias|Jr|Lamentacoes|Lm|Lamentações|Lm|Ezequiel|Ez|Ezequiel|Ez|Daniel|Dn|Daniel|Dn|Oseias|Os|Oseias|Os|Joel|Jl|Joel|Jl|Amos|Am|Amós|Am|Obadias|Ob|Obadias|Ob|Jonas|Jn|Jonas|Jn|Miqueias|Mq|Miqueias|Mq|Naum|Na|Naum|Na|Habacuque|Hc|Habacuque|Hc|Sofonias|Sf|Sofonias|Sf|Ageu|Ag|Ageu|Ag|Zacarias|Zc|Zacarias|Zc|Malaquias|Ml|Malaquias|Ml|Mateus|Mt|Mateus|Mt|Marcos|Mc|Marcos|Mc|Lucas|Lc|Lucas|Lc|Joao|Jo|João|Jo|Atos dos Apostolos|At|Atos dos Apóstolos|At|Atos|Romanos|Rm|Romanos|Rm|1 Corintios|1Co|1 Coríntios|1Co|2 Corintios|2Co|2 Coríntios|2Co|Galatas|Gl|Gálatas|Gl|Efesios|Ef|Efésios|Ef|Filipenses|Fp|Filipenses|Fp|Colossenses|Cl|Colossenses|Cl|1 Tessalonicenses|1Ts|1 Tessalonicenses|1Ts|2 Tessalonicenses|2Ts|2 Tessalonicenses|2Ts|1 Timoteo|1Tm|1 Timóteo|1Tm|2 Timoteo|2Tm|2 Timóteo|2Tm|Tito|Tt|Tito|Tt|Filemon|Fm|Filemom|Fm|Hebreus|Hb|Hebreus|Hb|Tiago|Tg|Tiago|Tg|1 Pedro|1Pe|1 Pedro|1Pe|2 Pedro|2Pe|2 Pedro|2Pe|1 Joao|1Jo|1 João|1Jo|2 Joao|2Jo|2 João|2Jo|3 Joao|3Jo|3 João|3Jo|Judas|Jd|Judas|Jd|Apocalipse|Ap|Apocalipse|Ap';
  const final = '(?![a-z])';
  const case1 = `(${books}) ${n}(,${n}-${n})*(,${n})*(,${n}-${n})*`;
  const tag = ['<span class="link-verse-bible"> <a href="/#/bible-id/', '" atr="verse-id">', '</a> </span>'];
  const reg = new RegExp(`(${case1}${final})`, 'gi');
  const mtch = str.replace(reg, tag[0] + "$&" + tag[1] + "$&" + tag[2]);

  return mtch;
}

export const replaceAt = (str, index, chr) => {
  if (index > str.length - 1)
    return str;
  return str.substr(0, index) + chr + str.substr(index);
}

export const getWordRandom = (wordsArray, wordSelected) => {
  const arrayTest = copyObject(wordsArray);
  const n = Math.floor(Math.random() * arrayTest.length);
  const nextWord = wordSelected || arrayTest[n];

  arrayTest.splice(arrayTest.indexOf(nextWord), 1);

  return {
    nextWord: nextWord,
    wordsToEvaluate: arrayTest
  }
}

export const copyObject = (obj) => JSON.parse(JSON.stringify(obj));

export const getPageNameByUrl = (pathName) => {
  // eslint-disable-next-line
  const rgex = /\/[^\/]*/;
  const resultsRegex = pathName.match(rgex);
  return resultsRegex && resultsRegex[0] && resultsRegex[0].replace('/', '').replace('#', '');
};

export const isTextBibleId = (text) => {
  const str = text.toLowerCase();
  let foundBook = 0;
  let book = '';
  let value = '';

  Object.keys(BOOKS_NAME_IDS).forEach((keyBook) => {
    if (
      (!foundBook || (book.length < keyBook.length)) &&
      (
        str.includes(`${keyBook} `) ||
        str.includes(` ${keyBook}`) ||
        str === keyBook ||
        (keyBook.length > 2 && str.includes(`${keyBook}`))
      ) &&
      !(str.includes('cap') && keyBook === 'ap') &&
      (keyBook !== 'os')
    ) {
      foundBook = BOOKS_NAME_IDS[keyBook];
      book = keyBook;
    }
  });

  if (foundBook) {
    // eslint-disable-next-line
    const rgex = new RegExp(`${book} *((capítulo )||(capitulo )||(cap *))*(\\d)*( )*((versículo )||(versiculo )||(ver ))*(:||,||;)*( )*(\\d)*`, 'g');
    // eslint-disable-next-line
    const rgexChapterNumber = new RegExp(` (\\d)(\\d)*`, 'g');
    const rgexVerseNumber = new RegExp(`(\\d)( )*(:||,||;|| ||ver||versículo||versiculo)*( )*(\\d)(\\d)*$`, 'g');
    const rgexVerseNumberInChapter = new RegExp(`([^(\\d)])(\\d)(\\d)*$`, 'g');

    const resultsRegex = str.match(rgex);
    const foundBookAndNumber = (resultsRegex && resultsRegex[0]) || '';
    const captherNumber = (foundBookAndNumber && foundBookAndNumber.match(rgexChapterNumber) && foundBookAndNumber.match(rgexChapterNumber)[0]) ? foundBookAndNumber.match(rgexChapterNumber)[0].replace(' ', '') : null;
    const verseNumber = (captherNumber)
      ? (foundBookAndNumber && foundBookAndNumber.match(rgexVerseNumber) && foundBookAndNumber.match(rgexVerseNumber)[0] && foundBookAndNumber.match(rgexVerseNumber)[0]
        && foundBookAndNumber.match(rgexVerseNumber)[0].match(rgexVerseNumberInChapter)) && foundBookAndNumber.match(rgexVerseNumber)[0].match(rgexVerseNumberInChapter)[0]
          .replace(' ', '').replace(':', '').replace(',', '').replace(';', '')
      : '';

    value = (captherNumber)
      ? (verseNumber)
        ? `?b=${foundBook}&c=${captherNumber}&v=${verseNumber}`
        : `?b=${foundBook}&c=${captherNumber}`
      : `?b=${foundBook}`;
  }

  return {
    is: Boolean(foundBook),
    value
  };
};


export const getTextByQuestionAndAnswer = (q, a) => {
  switch (q) {
    case 'LIKE':
      switch (a) {
        case '1':
          return { text: 'Sim', weight: 1 };
        case '2':
          return { text: 'Um pouco', weight: 2 };
        case '3':
          return { text: 'Não', weight: 3 };
        default:
          return null;
      }

    case 'UNDERSTAND':
      switch (a) {
        case '1':
          return { text: 'Sim, entendi', weight: 1 };
        case '2':
          return { text: 'Entendi alguma coisa', weight: 2 };
        case '3':
          return { text: 'Não entendi muito bem', weight: 3 };
        case '4':
          return { text: 'Não faço ideia', weight: 4 };
        default:
          return null;
      }

    case 'TRUST':
      switch (a) {
        case '1':
          return { text: 'Sim', weight: 1 };
        case '2':
          return { text: 'Talvez', weight: 2 };
        case '3':
          return { text: 'Não', weight: 3 };
        default:
          return null;
      }

    case 'GENDER':
      switch (a) {
        case 'f':
          return { text: 'Mulheres' };
        case 'm':
          return { text: 'Homens' };
        default:
          return null;
      }

    case 'AGE':
      switch (a) {
        case '0-12':
          return { text: '0 à 18 anos' };
        case '18-30':
          return { text: '18 à 30 anos' };
        case '31-45':
          return { text: '31 à 45 anos' };
        case '45+':
          return { text: 'acima de 45 anos' };
        default:
          return null;
      }

    default:
      return a;
  }
}