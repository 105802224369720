import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import '../../../../styles/styles.css';
import './SearchExplicability.css';
import BarChart from '../../../../components/charts/bar/BarChart';
import ForceGraph from '../../../../components/forceGraph/ForceGraph';
import Title from '../../../../components/title/Title';

import ScatterPlotChart from '../../../../components/charts/scatterplot/ScatterPlotChart';
import BarCodeChart from '../../../../components/charts/barcode/BarCodeChart';

import Table from '../../../../components/table/Table';

const hashNodes = {
  'concordance': {},
  'w2v': {}
};
const hashW2vSimilarity = {};

class SearchExplicability extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      dataResults: []
    };
    // this.filterVersesBySearch = this.filterVersesBySearch.bind(this);
  }

  transformArrayToString(arrayItems, size) {
    let str = '';
    let sz = size || arrayItems.length;
    sz -= 1;
    arrayItems.forEach((item, k) => {
      if (k < (sz + 1)) {
        str += (k === sz) ? `e <span class="featured">${item}</span>`
          : `<span class="featured">${item}</span>,`
      }
    });
    return str;
  }

  groupBySearch = (arr1, typeGraph) => {
    let arr2;
    let hasWordSearch = false;
    let count = 0;
    const { word } = this.props;

    let result = Object.keys(arr2 = arr1.map((item) => item.search)
      .reduce((acc, curr) => {
        acc[curr] = acc[curr] + 1 || 1;
        return acc;
      }, [])).map((item, key) => {
        hashNodes[typeGraph][item] = key;
        count = key;
        if (item === word) hasWordSearch = true;
        return { id: item, size: arr2[item] }
      });

    if (!hasWordSearch) {
      count++;
      hashNodes[typeGraph][word] = count;
      result.push({ id: word, size: 2 })
    }

    return result;
  };

  buildDataBarChart = (dataOld) => {
    let newData = dataOld.map((item) => {
      return {
        count: item.occurrences || item.size || 0,
        word: item.id
      }
    });
    newData.sort((a, b) => (a.count - b.count));
    return newData;
  }

  buildDataForceChart = (objItems, typeGraph, data, word) => {
    let links = [];
    if (typeGraph === 'w2v') {
      const similarItems = data.info && data.info.w2v
        && data.info.w2v[0] && data.info.w2v[0].similares;
      const cosSims = data.info && data.info.w2v
        && data.info.w2v[0] && data.info.w2v[0].cosSims;

      similarItems && similarItems.length && similarItems.forEach((item, key) => {

        hashW2vSimilarity[item] = cosSims[key];
      });
    }

    objItems.forEach((item) => {
      if (word !== item.id && hashNodes[typeGraph][word] !== undefined
        && hashNodes[typeGraph][item.id] !== undefined)
        links.push({
          source: hashNodes[typeGraph][word],
          target: hashNodes[typeGraph][item.id],
          value: typeGraph === 'w2v' ? (hashW2vSimilarity[item.id] || 0) : 1
        })
    })

    return {
      nodes: objItems,
      links: links
    };
  };

  render() {
    const { word, searchResult, dataModel } = this.props;
    const versesLength = (searchResult && searchResult.verses && searchResult.verses.length) || 0;
    const similares = (searchResult && searchResult.info && searchResult.info.w2v
      && searchResult.info.w2v[0] && searchResult.info.w2v[0].similares) || [];
    const data = JSON.parse(JSON.stringify(searchResult));
    data.verses && data.verses.forEach((item) => {
      if (item && item.text) {
        delete item.text;
        delete item.id;
      }
    });

    const concordance = data.verses.filter((verse) => (verse && verse.type && verse.type.includes('concordance')));
    const word2Vec = data.verses.filter((verse) => (verse && !verse.type));

    const concordanceLength = (concordance && concordance.length) || 0;
    const w2vLength = (word2Vec && word2Vec.length) || 0;

    const itensConcordance = this.groupBySearch(concordance, 'concordance');
    const itensWord2Vec = this.groupBySearch(word2Vec, 'w2v');

    const dataW2v = this.buildDataForceChart(itensWord2Vec, 'w2v', data, word);
    const dataConcordance = this.buildDataForceChart(itensConcordance, 'concordance', data, word);

    const objBarChartW2v = dataW2v && dataW2v.nodes && this.buildDataBarChart(dataW2v.nodes);
    const objBarChartConcordance = dataConcordance && dataConcordance.nodes && this.buildDataBarChart(dataConcordance.nodes);

    let tsneData = ((dataModel && dataModel.tsne && dataModel.tsne.error) || ((dataModel && !dataModel.tsne) || !dataModel)) ? [] : dataModel.tsne;
    let vectorsData = dataModel && dataModel.vectors;

    const hasErrorOnVectorsData = vectorsData.find((item) => item.term === "" && item.vector === null);

    vectorsData = hasErrorOnVectorsData ? [] : vectorsData;

    const filterVersesBySearch = () => {
      return concordance
    }
    const urlModel = "/model/" + word;

    return (
      <div id='explicability' className='content'>
        {/* Consulta direta */}
        <div className='container-area area-1'>
          <div className="container-left">
            <h2 className="be-title-page"> Como cheguei neste resultado? </h2>
            <Title
              direction='left'
              number='1'
              text1='Pesquisa pela palavra '
              detail={word}
              text2=' no texto bíblico'
              color='color-1'
            />
            <p> Primeiramente, nós pesquisamos a palavra “{word}” no texto da Bíblia e esta consulta retornou um total de: </p>
            <div className="box-count-results blue">
              <span className="color"> {versesLength} </span>
              resultados.
            </div>

            <h3> Como esta consulta foi realizada? </h3>
            <p>
              Enviamos a palavra que você digitou para nossa máquina de busca e ela retornou os textos onde esta palavra foi encontrada. Nós admitimos também o retorno de textos com palavras muito semelhantes com o prefixo do termo digitado. Por exemplo, no caso dos verbos, as demais variações verbais também seriam retornadas.
            </p>
            <div className='divider'></div>
          </div>
          <div className="container-right"></div>
        </div>

        {/* Termos relacionados */}
        <div className='container-area area-2'>
          <div className='container-right'></div>
          <div className='container-left'>
            <Title
              direction='right'
              number='2'
              text1='Pesquisamos também pelos '
              detail='termos relacionados'
              text2=''
              color='color-2'
            />
            <h3>
              Então pesquisamos termos relacionados ao que você pesquisou.
            </h3>
            <p>
              Os termos ou palavras relacionados ao que você pesquisou, são palavras que em geral aparecem com alta frequência juntas em um determinado texto, ou que são encontradas nos mesmos contextos. Neste caso o “texto” que utilizamos foi todo o conjunto de páginas da Wikipédia brasileira. Ou seja, nos textos da Wikipédia, as palavras <span dangerouslySetInnerHTML={{ __html: this.transformArrayToString(similares, 5) }} /> apareceram de forma significativa em páginas onde se falava sobre <span className="featured-2">{word}</span>.
                Estes resultados são retornados a partir de modelos matemáticos que são computados e executados a partir do texto.
               <a href={urlModel}>Clique aqui</a>, se quiser saber mais detalhes sobre o modelo utilizado e como ele funciona.
            </p>
            <p>
              Bom, sabendo quais são os termos relacionados, quando você pesquisou a palavra <span className="featured-2">{word}</span> nós fizemos na verdade uma consulta expandida, pesquisando por todos os termos relacionados ao que você pesquisou.
            </p>
            <div className='divider'></div>
          </div>

        </div>
        <div className='container-flex'>
          <div className='container-left'>
            <div className="legend-force-chart">
              <p> Legenda: </p>
              <ul className="list">
                <li className="item-legend" id="azul">
                  <div className="color-item"></div>
                  <span>Termo que você pesquisou</span>
                </li>
                <li className="item-legend" id="roxo">
                  <div className="color-item"></div>
                  <span> Termo similar encontrado</span>
                </li>
                <li className="item-legend">
                  <div className="color-item"></div>
                  <span>O tamanho do círculo indica relevância nos resultados. Quanto maior o círculo, mais resultados aquele termo retornou</span>
                </li>
              </ul>
            </div>
            <ForceGraph
              data={dataW2v}
              word={word}
              method='w2v'
            >
            </ForceGraph>
            <div className="source-chart">
              <p><span>Fonte:</span> Os dados usados neste projeto para a concordância bíblica foram extraídos do site: https://www.bibliaon.com.</p>
            </div>
          </div>
          <div className='container-right chart'>
            <h3>
              O quanto cada uma destas  palavras contribuiu para os resultados retornados?
          </h3>
            <BarChart filterVersesBySearch={filterVersesBySearch} data={objBarChartW2v} method={'w2v'} word={word}></BarChart>
          </div>
        </div>

        <div className='container-block'>
          <div className='resume'>
            <p>
              Utilizando este recurso de consulta, conseguimos retornar um total de:
            </p>
            <div className="box-count-results purple">
              <span className="color"> {w2vLength} </span>
              resultados.
            </div>
          </div>
          <div className='divider'></div>
        </div>

        {/* Concordância Bíblica */}
        <div className='container-area area-3'>
          <div className='container-left'>
            <Title
              direction='left'
              number='3'
              text1='Mas o que a '
              detail='Concordância Bíblica'
              text2={` diz sobre ${word}?`}
              color='color-3'
            />
            <p>
              Então pesquisamos se esta palavra se encontra em nossa <span className='text-pink-color'> Concordância Bíblica</span>.
              Você sabe o que é uma concordância Bíblica?
            </p>
            <p>
              A Concordância Bíblica é um índice alfabético de palavras usadas na Bíblia Sagrada, mostrando ocorrências contextuais de cada palavra por todas as 66 separadas obras literárias da Bíblia. A clássica e exaustiva Concordância Bíblica para a clássica tradução inglesa da Bíblia é a <i>Concordância Exaustiva da Bíblia de Strong</i>.
            </p>
            <p>
              Nós usamos a Concordância Bíblica para retornar versículos referentes ao assunto que você pesquisou.
              Neste trabalho usamos a concordância do site <i>bibliaon.com</i>.
            </p>
            <p>
              Além de procurar pela palavra {word}, também pesquisamos por termos relacionados e verificamos se algum destes termos foi encontrado na concordância Bíblica.
              Veja as palavras que foram encontradas e o quanto cada uma delas foi relevante:
            </p>
          </div>
          <div className='container-right'></div>
        </div>
        <div className='container-flex'>
          <div className='container-left'>
            <div className="legend-force-chart">
              <p> Legenda: </p>
              <ul className="list">
                <li className="item-legend" id="azul">
                  <div className="color-item"></div>
                  <span>Termo que você pesquisou</span>
                </li>
                <li className="item-legend" id="rosa">
                  <div className="color-item"></div>
                  <span> Termo encontrado na concordância bíblica </span>
                </li>
                <li className="item-legend">
                  <div className="color-item"></div>
                  <span>O tamanho do círculo indica relevância nos resultados. Quanto maior o círculo, mais resultados aquele termo retornou</span>
                </li>
              </ul>
            </div>
            <ForceGraph
              data={dataConcordance}
              word={word}
              method='concordance'
            >
            </ForceGraph>
          </div>
          <div className='container-right chart'>
            <h3>
              O quanto cada uma destas  palavras contribuiu para os resultados retornados?
            </h3>
            <BarChart filterVersesBySearch={filterVersesBySearch} data={objBarChartConcordance} method={'concordance'} word={word}></BarChart>
          </div>
        </div>
        <div className='container-block'>
          <div className='resume'>
            <p>
              Utilizando este recurso de consulta, conseguimos retornar um total de:
            </p>
            <div className="box-count-results pink">
              <span className="color"> {concordanceLength} </span>
              resultados.
            </div>
          </div>
          <div className='divider'></div>
        </div>

        {/* Método */}
        {tsneData && tsneData.length ?
          <div>
            <div className='container-area area-4'>
              <div className='container-left'>
                <Title
                  direction='right'
                  number='4'
                  text1='Onde entra a '
                  detail='computação'
                  text2=' nesta história?'
                  color='color-4'
                />

                <h3> Sobre o modelo </h3>
                <p>
                  Nesta pesquisa trabalhamos com um método de processamento de textos chamado Word2Vec. Nosso modelo recebe um texto e consegue identificar o contexto das palavras.
                </p>
                {/* <p>
                  Veja abaixo para entender o funcionamento deste método:
                </p>
                <div className="legend-model-chart">
                  <p> Legenda: </p>
                  <ul className="list">
                    <li className="item-legend" id="cinza">
                      <div className="color-item"></div>
                      <span>Contexto da palavra</span>
                    </li>
                    <li className="item-legend" id="vermelho">
                      <div className="color-item"></div>
                      <span> Termos </span>
                    </li>
                  </ul>
                </div>
                <Context data={dataModel.contexts}>
                </Context> */}
              </div>
              <div className="container-right img-1"></div>
            </div>

            <div className='container-flex'>
              <div className='container-left'>
                <p>
                  As palavras <span dangerouslySetInnerHTML={{ __html: this.transformArrayToString(similares, 6) }} /> não são ortograficamente parecidas, mas possuem o mesmo contexto. Por isso foram consideradas similares. Para fazer isso o WordVec converte as palavras em vetores e calcula a distância entre eles em um plano cartesiano multidimentsional.
                </p>
                <p>
                  Nesta pesquisa, o texto que utilizamos para identificar similaridades entre as palavras foi o conjunto de páginas da Wikipédia Brasileira.  Com ele conseguimos identificar palavras que semanticamente relacionadas com base no contexto no qual foram encontradas.
                </p>
                <h3> Como a similaridade foi calculada </h3>
                <p>
                  Bom, todas estas palavras foram convertidas em vetores numéricos que representam cada palavra. Os vetores eram atualizados a medida que o método aprendia a representação. Ao final cada palavra teve um vetor:
                </p>
              </div>
              <div className="container-right img-2"></div>
            </div>
            <div className='container-flex'>
              <div className='container-left'>
                <h3> Vetores de representação </h3>
                <div className="legend-gradient">
                  <span> Legenda: As barras representam pesos que variam de -1 </span>
                  <div className="gradient"></div>
                  <span> 1 </span>
                </div>
                <p> A largura das barras também reforça o valor dos pesos, quanto mais fina, menor o valor, quandto mais grossa, maior.</p>
                <div className="area-bar-code">
                  <BarCodeChart data={vectorsData || {}}></BarCodeChart>
                </div>
                <div className="source-chart">
                  <p><span>Fonte:</span> Informações do Word2Vec treinado com o texto da <a href="https://dumps.wikimedia.org/ptwiki/20190620/" rel="noopener noreferrer" target="_blank">Wikipédia</a>  utilizando a biblioteca <a rel="noopener noreferrer" href="https://deeplearning4j.org/docs/latest/deeplearning4j-nlp-word2vec" target="_blank">DeepLearning4j</a>.</p>
                </div>
              </div>
            </div>
            <div className='container-flex'>
              <div className='container-left'>
                <h3> Visualizção do método </h3>
                <p>
                  Abaixo é mostrado no gráfico a similaridade entre as palavras pesquisadas.
            </p>
                <div className="legend-model-chart">
                  <p> Legenda: </p>
                  <ul className="list">
                    <li className="item-legend" id="azul">
                      <div className="color-item"></div>
                      <span>Termo que você pesquisou</span>
                    </li>
                    <li className="item-legend" id="roxo">
                      <div className="color-item"></div>
                      <span> Termo similar encontrado</span>
                    </li>
                  </ul>
                </div>
                <div>
                  <ScatterPlotChart data={tsneData} word={word}></ScatterPlotChart>
                  <div className="source-chart">
                    <p><span>Fonte:</span> Informações do Word2Vec treinado com o texto da <a rel="noopener noreferrer" href="https://dumps.wikimedia.org/ptwiki/20190620/" target="_blank">Wikipédia</a>  utilizando a biblioteca <a rel="noopener noreferrer" href="https://deeplearning4j.org/docs/latest/deeplearning4j-nlp-word2vec" target="_blank">DeepLearning4j</a>. A redução de dimensionalidade foi feita utilizando <a href="https://lvdmaaten.github.io/tsne/" rel="noopener noreferrer" target="_blank">t-SNE</a>.</p>
                  </div>
                </div>
              </div>
              <div className='container-right chart'>
                <Table data={dataModel.w2v}></Table>
              </div>
            </div>
          </div> : ''}
      </div>
    );
  }
}

SearchExplicability.propTypes = {
  t: PropTypes.func.isRequired,
  word: PropTypes.string.isRequired,
  classes: PropTypes.object
};

const mapStateToProps = store => ({
  searchResult: store.searchState.searchResult,
  dataModel: store.searchState.dataModel
});

export default withTranslation()(connect(mapStateToProps)(SearchExplicability));