import {
  CLICK_SHOW_ADVANCED_QUERY, SAVE_DATA_SEARCH,
  SAVE_DATA_MODEL
} from '../actions/actionTypes';

const initialState = {
  show: 'results_search',
  homeNumber: Math.floor(Math.random() * 2) + 1,
  searchResult: null,
  dataModel: null
};

export const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLICK_SHOW_ADVANCED_QUERY:
      return {
        ...state,
        show: action.show
      };
    case SAVE_DATA_SEARCH:
      return {
        ...state,
        searchResult: action.searchResult
      };
    case SAVE_DATA_MODEL:
      return {
        ...state,
        dataModel: action.dataModel
      };
    default:
      return state;
  }
};