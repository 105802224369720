import { Component } from 'react';
import { API_URL, trackEvents } from '../config';
import { uid } from 'rand-token';
import ReactGA from 'react-ga';

class LogService extends Component {

  static getRandomToken = () => {
    const token = uid(64) + new Date().getTime().toString();
    sessionStorage.setItem('be_token_user_id', token);
    return token;
  }

  static getUserToken = () => {
    return sessionStorage.getItem('be_token_user_id') ?
      sessionStorage.getItem('be_token_user_id') : this.getRandomToken();
  };

  static getIpAndLocation = () => {
    const urlGetIp = 'http://ip-api.com/json';

    return fetch(urlGetIp)
      .then(response => response.json())
      .catch(error => console.log('error', error));
  };

  static trackEvent = async (eventName, props = {}) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    const baseAttr = {
      tokenId: this.getUserToken(),
      ip: await this.getIpAndLocation(),
      timestamp: new Date().getTime(),
      eventType: eventName,
      device: navigator && navigator.userAgent
    };

    const event = Object.assign({}, baseAttr, props);
    // const requestOptions = {
    //   method: 'POST',
    //   headers: headers,
    //   body: JSON.stringify(event),
    //   redirect: 'follow'
    // };

    if (trackEvents) {
      if (eventName === 'PAGE_LOADED') {
        ReactGA.pageview(`/${event.namePage}`);
        if (event && (event.namePage === 'search') && event.term) {
          ReactGA.pageview(`/${event.namePage}/${event.term}`);
        }
      } else {
        if (event && event.eventType) {
          const eventArgs = {
            category: event.eventType || 'INVALID_CATEGORY',
            action: event.description,
            label: event.term,
          };
          ReactGA.event(eventArgs);
        }
      }
      // fetch(`${API_URL}/log`, requestOptions)
      //   .then(response => response.text());
    }
    // } else {
    //   fetch(`${API_URL}/log`, requestOptions)
    //     .then(response => response.text());
    // }
  }

  static getAllLogs = async () => {
    return await fetch(`${API_URL}/log`)
      .then(response => response.json());
  }

  static getLogsByEvent = async (event) => {
    return await fetch(`${API_URL}/logs/${event}`)
      .then(response => response.json());
  }

  static getLogsEvaluation = async (event) => {
    return await fetch(`${API_URL}/evaluation`)
      .then(response => response.json());
  }

  static getLogsEvaluationGrouped = async (event) => {
    return await fetch(`${API_URL}/evaluation_grouped`)
      .then(response => response.json());
  }

  static getLogsEvaluationResume = async (event) => {
    return await fetch(`${API_URL}/analysis_resume`)
      .then(response => response.json());
  }

  static getLogsExplicabilityResume = async (event) => {
    return await fetch(`${API_URL}/analysis/explicability`)
      .then(response => response.json());
  }

  static getLogsLogsResume = async (event) => {
    return await fetch(`${API_URL}/logs_resume`)
      .then(response => response.json());
  }

  static sendAuthenticationViewReports = async (data) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    const requestOptions = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(data),
    };

    return await fetch(`${API_URL}/logs_report_auth`, requestOptions)
      .then(response => response.json());
  }
};

export default LogService;