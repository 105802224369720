import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import { convertTextInBibleId, removeAccent, replaceAt } from '../../utils/utils';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

import LogService from '../../services/LogService';
import { events, eventsDescriptions } from '../../data/data';

import '../../styles/styles.css';
import './VerseResult.css';

class VerseResult extends Component {
  constructor(props) {
    super(props);
    this.getTermByDescriptionExplanation = this.getTermByDescriptionExplanation.bind(this);
    this.getFeaturedText = this.getFeaturedText.bind(this);
    this.createMarkup = this.createMarkup.bind(this);
    this.logClickVerseId = this.logClickVerseId.bind(this);
  }

  logClickVerseId = (verseStringId) => {
    const { query } = this.props;

    LogService.trackEvent(events["verse_id_clicked"], {
      verse: verseStringId,
      term: query,
      namePage: 'search',
      description: eventsDescriptions["verse_id_clicked"],
    });
  };

  getFeaturedText = (str, term) => (
    str.replace(term, `<span class='featured-term-search'>${term}</span>`))

  getTermByDescriptionExplanation(str, search) {
    let rgx = str.match(/\(:*[a-z]*/g);
    let wordPart = (rgx && rgx[0].replace(/[:(),]/g, '')) || search;
    return wordPart;
  }

  createMarkup(str) {
    return { __html: `<span>${str}</span>` }
  }

  render() {
    const { verse, id, lang, resource, query } = this.props;
    const bookCompleteObj = convertTextInBibleId(verse.id);

    const explanation = verse && verse.explanation;
    let verseText = verse && verse.text;
    if (explanation) {
      const termSearched = this.getTermByDescriptionExplanation(explanation.description, verse.search);
      if (termSearched && termSearched.length && verseText.indexOf("<span class='featured-term-search'>") === -1) {
        verseText = this.getFeaturedText(verseText, termSearched);
      }

      if (verseText.indexOf(termSearched) === -1) {
        let copyTextVerse = removeAccent(verseText);
        const positionWord = copyTextVerse.indexOf(termSearched);
        const endWord = termSearched.length + positionWord;

        if (positionWord !== -1) {
          verseText = replaceAt(verseText, endWord, "</span>");
          verseText = replaceAt(verseText, positionWord, "<span class='featured-term-search'>");
        }
      }
    }

    const bookString = bookCompleteObj.b && bookCompleteObj.b.name;
    let bookNumber = (bookCompleteObj.b && bookCompleteObj.b.id);
    bookNumber = parseInt(bookNumber) - 1;

    const verseStringId = `${bookString} ${bookCompleteObj.c}:${bookCompleteObj.v}`;
    const urlVerse = `/bible?b=${bookNumber}&c=${bookCompleteObj.c}&v=${bookCompleteObj.v}`;

    return (
      <div key={id} className={'box-verse ' + resource}>
        <p className='verse'>
          <span className='verse-text' >
            {ReactHtmlParser(verseText)}
          </span>

          <span className='verse-id'>
            <Link className='link-verse' onClick={() => this.logClickVerseId(verseStringId)} to={urlVerse}>{verseStringId}</Link>
          </span>
        </p>
        {lang === 'pt' && verse.id && <div className='area-about'>
          <div className="box-feedback">
            <div className='verse-about-box'>
              {verse.type
                ?
                verse.type === 'concordance' ?
                  <span className='verse-about-result concordance'>
                    Este versículo apareceu por causa da palavra <span className='search-id'> {verse.search} </span>, que foi encontrada na concordância Bíblica.
                       </span> :
                  <span className='verse-about-result concordance'>
                    Este versículo apareceu porque a palavra <span className='search-id'> {verse.search} </span>, encontrada na concordância Bíblica foi considerada similar ao termo que você pesquisou.
                        </span>
                :
                verse.search === query ?
                  <span className='verse-about-result direct'>
                    Este versículo é um resultado direto da sua consulta: <span className='search-id'> {verse.search} </span>.
                        </span>
                  :
                  <span className='verse-about-result w2v'>
                    Este versículo apareceu por causa do termo <span className='search-id'> {verse.search} </span>, que está relacionada ao termo que você pesquisou.
                        </span>
              }

              {/* {explanation ?
                <span> A palavra <b>{verse.search}</b> possui um peso de valor {parseFloat(explanation.value).toFixed(2)} neste verso. </span>
                : ''} */}
              {/* <br /> */}
              {/* <span> O que você achou deste resultado? </span> */}
            </div>
            {/* <div className="box-icons-like">
              <span className={'like-icon up ' + selected[verse.id + '_1']}
                onClick={() => { }}>
                <ThumbUpIcon />
              </span>
              <span className={'like-icon down ' + selected[verse.id + '_0']}
                onClick={() => { }}>
                <ThumbDownIcon />
              </span>
            </div> */}
          </div>
        </div>
        }
      </div>
    );
  }
}

VerseResult.propTypes = {
  t: PropTypes.func.isRequired,
  verse: PropTypes.object,
  id: PropTypes.number,
  resource: PropTypes.string,
  lang: PropTypes.string,
  selected: PropTypes.object
};

export default withTranslation()(VerseResult);