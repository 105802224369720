import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import '../../styles/styles.css';
// import '../../screens/search/Search.css';
import './SearchInput.css';
import queryString from 'query-string';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { clickShowAdvancedQuery } from '../../actions';
import { fade } from '@material-ui/core/styles/colorManipulator';
import SearchIcon from '@material-ui/icons/Search';
import { setQuery } from '../../actions';
import ReactFetchAutocomplete from "react-fetch-autocomplete";
import { API_URL } from '../../config';
import LogService from '../../services/LogService';
import { events, eventsDescriptions } from '../../data/data';

const styles = theme => ({
  card: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.0),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.05),
    },
    marginRight: theme.spacing.unit * 2,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit * 3,
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#999',
    right: 0,
    top: 3
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  }
});

class SearchInput extends Component {
  constructor(props) {
    super(props);
    const strQuery = this.props.location.search;
    const paramsQuery = Boolean(strQuery) ? queryString.parse(strQuery) : '';
    this.state = {
      advancedQuery: paramsQuery,
      query: '',
      value: '',
      selection: null
    }

    this.showAdvanced = this.showAdvanced.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.setValue = this.setValue.bind(this);
    this.goSearch = this.goSearch.bind(this);
    this.setSelection = this.setSelection.bind(this);
  }

  goSearch() {
    const { query } = this.state;
    const { setQuery, pathToSearch } = this.props;
    if (query && query.length) {
      const lowerQuery = query.toLowerCase();
      setQuery(lowerQuery);
      this.setState({
        query: ''
      });

      LogService.trackEvent(events["input_search_clicked"], {
        term: query,
        from: "search_home",
        description: `${eventsDescriptions["input_search_clicked"]} da home`,
      });

      const pathToGo = pathToSearch || 'search';
      this.props.history.push(`/${pathToGo}/${lowerQuery}`);
    }
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.goSearch();
    }
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  getOptions() {
    const strQuery = this.props.location.search;
    const paramsQuery = Boolean(strQuery) ? queryString.parse(strQuery) : '';
    this.setState({
      advancedQuery: paramsQuery
    });
  }

  showAdvanced() {
    this.getOptions()
  }

  setValue(valueNew) {
    const queryValue = valueNew.toLowerCase();
    this.setState({
      value: queryValue,
      query: queryValue,
    });
    setQuery(queryValue)
  }

  setSelection(selectionNew) {
    this.setState({
      selection: selectionNew
    });
  }

  render() {
    const { t, classes } = this.props;
    const { value } = this.state;
    const urlSearchApi = API_URL + '/info/';

    const suggestionParser = data => {
      let properties = [];

      if (data.dictionary && data.dictionary.length) {

        if (data.indexes && data.indexes.length) {
          properties.push({
            description: data.term,
            property: 'dictionary',
            type: data.indexes[0].type,
            id_viz: data.indexes[0].id,
            definition: data.dictionary[0].description
          });
        } else {
          properties.push({
            description: data.term,
            property: 'dictionary',
            type: 'c',
            definition: data.dictionary[0].description
          });
        }
      }

      return properties;
    }

    const fetchUrl = ({ searchQuery }) =>
      urlSearchApi + searchQuery;

    return (
      <div id="search-input">
        <ReactFetchAutocomplete
          value={value}
          onChange={this.setValue}
          onSelect={this.setSelection}
          fetchUrl={fetchUrl}
          suggestionParser={suggestionParser}
          debounce={500}
        >
          {({ inputProps, getSuggestionProps, suggestions, loading }) => {
            return (
              <div>
                <div className={classes.search + ' search'}>

                  <input className='input-react-fetch-auto-complete'
                    {...inputProps({ onKeyDown: this.handleKeyPress, placeholder: t('HEADER.SEARCH_PLACEHOLDER') })} />
                  <button className={classes.searchIcon + ' area-serch-icon'} onClick={() => this.goSearch()}>
                    <SearchIcon />
                  </button>
                </div>
              </div>
            );
          }}
        </ReactFetchAutocomplete>
      </div>
    );
  }
}

SearchInput.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  pathToSearch: PropTypes.string,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ clickShowAdvancedQuery, setQuery }, dispatch);

const mapStateToProps = store => ({
  show: store.searchState.show,
  homeNumber: store.searchState.homeNumber
});

export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SearchInput))));
