import { Component } from 'react';
import { API_URL } from '../config';
import { uid } from 'rand-token';

class EvaluateService extends Component {

  static getRandomToken = () => {
    const token = uid(64) + new Date().getTime().toString();
    sessionStorage.setItem('be_token_user_id', token);
    return token;
  }

  static getUserToken = () => {
    return sessionStorage.getItem('be_token_user_id') ?
      sessionStorage.getItem('be_token_user_id') : this.getRandomToken();
  };

  static getInfoUser = () => {
    return sessionStorage.getItem('be_info_user') ?
      JSON.parse(sessionStorage.getItem('be_info_user')) : null;
  };

  static saveEvaluation = async (query, question, answer) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    const evaluation = {
      tokenId: this.getUserToken(),
      timestamp: new Date().getTime(),
      term: query,
      device: navigator && navigator.userAgent,
      userInfo: this.getInfoUser(),
      question: question,
      answer: answer
    };

    const requestOptions = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(evaluation),
      redirect: 'follow'
    };

    fetch(`${API_URL}/evaluation`, requestOptions)
      .then(response => response.text());
  }

};

export default EvaluateService;