import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import '../../styles/styles.css';
import './Search.css';
import './SearchHome.css';
import queryString from 'query-string';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { clickShowAdvancedQuery } from '../../actions';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { setQuery } from '../../actions';
import ListBooks from '../../components/listBooks/ListBooks';
import LogService from '../../services/LogService';
import { events, eventsDescriptions } from '../../data/data';

const styles = theme => ({
  card: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.0),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.05),
    },
    marginRight: theme.spacing.unit * 2,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit * 3,
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#999',
    right: 0,
    top: 3
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  }
});

class SearchHome extends Component {
  constructor(props) {
    super(props);
    const strQuery = this.props.location.search;
    const paramsQuery = Boolean(strQuery) ? queryString.parse(strQuery) : '';
    this.state = {
      advancedQuery: paramsQuery,
      query: '',
      value: '',
      selection: null
    }

    this.showAdvanced = this.showAdvanced.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.setValue = this.setValue.bind(this);
    this.goSearch = this.goSearch.bind(this);
    this.setSelection = this.setSelection.bind(this);
    this.evaluateProject = this.evaluateProject.bind(this);
  }

  goSearch() {
    const { query } = this.state;
    const { setQuery } = this.props;
    if (query && query.length) {
      const lowerQuery = query.toLowerCase();
      setQuery(lowerQuery);
      this.setState({
        query: ''
      });

      LogService.trackEvent(events["input_search_clicked"], {
        term: query,
        from: "search_home",
        description: `${eventsDescriptions["input_search_clicked"]} da home`,
      });

      this.props.history.push('/search/' + lowerQuery);
    }
  }

  evaluateProject() {
    this.props.history.push('/evaluate/');
  }

  componentDidMount() {
    const { clickShowAdvancedQuery } = this.props;
    clickShowAdvancedQuery('results_search');

    LogService.trackEvent(events["page_loaded"], {
      namePage: "search_home",
      description: eventsDescriptions["page_loaded"],
    });
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.goSearch();
    }
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  getOptions() {
    const strQuery = this.props.location.search;
    const paramsQuery = Boolean(strQuery) ? queryString.parse(strQuery) : '';
    this.setState({
      advancedQuery: paramsQuery
    });
  }

  showAdvanced() {
    this.getOptions()
  }

  setValue(valueNew) {
    const queryValue = valueNew.toLowerCase();
    this.setState({
      value: queryValue,
      query: queryValue,
    });
    setQuery(queryValue)
  }

  setSelection(selectionNew) {
    this.setState({
      selection: selectionNew
    });
  }

  render() {
    const { homeNumber } = this.props;

    return (
      <div>
        <div className="home-presentation">
          <div id='search-home' className={`style-${homeNumber}`}>
            <div className="box-all">
              <img className='logo' src='/images/logo-azul-menor.png' alt='Biblexplorer' />

              <div className='area-indexes-book'>
                <div className='c2'><ListBooks columns={2} /> </div>
                <div className='c3'><ListBooks columns={3} /> </div>
                <div className='c4'><ListBooks columns={4} /> </div>
              </div>
              <img alt="imagem ilustrativa da biblia" src='/images/biblia.png' className="bible-home" />
            </div>
          </div>
        </div>
        <div className='insta-info'>
          <h2 className='be-title-footer'>
            Acompanhe nosso instagram&nbsp;
            <span>
              <a href="https://www.instagram.com/biblexplorer/" target="_blank" atl="biblexplorer link instagram" rel="noopener noreferrer">@biblexplorer</a>
            </span>
          </h2>
          {/* <FeedInsta /> */}
        </div>
      </div>
    );
  }
}

SearchHome.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ clickShowAdvancedQuery, setQuery }, dispatch);

const mapStateToProps = store => ({
  show: store.searchState.show,
  homeNumber: store.searchState.homeNumber
});

export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SearchHome))));
