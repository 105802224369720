import {
  CLICK_UPDATE_VALUE, HANDLE_UPDATE_QUERY,
  CLICK_SHOW_ADVANCED_QUERY, SAVE_DATA_SEARCH,
  SAVE_DATA_MODEL
} from '../actions/actionTypes';

export const clickTranslate = value => ({
  type: CLICK_UPDATE_VALUE,
  translate: value
});

export const setQuery = value => ({
  type: HANDLE_UPDATE_QUERY,
  query: value
});

export const clickShowAdvancedQuery = (value) => ({
  type: CLICK_SHOW_ADVANCED_QUERY,
  show: value
});

export const saveSearchData = (result) => ({
  type: SAVE_DATA_SEARCH,
  searchResult: result
});

export const saveModelData = (data) => ({
  type: SAVE_DATA_MODEL,
  dataModel: data
});