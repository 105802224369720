import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import Toolbar from '@material-ui/core/Toolbar';
import { withRouter } from 'react-router';
import queryString from 'query-string';

import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import '../../styles/styles.css';
import './Bible.css';
import { API_URL } from '../../config';

import LogService from '../../services/LogService';
import { events, eventsDescriptions, ARRAY_BOOKS } from '../../data/data';

import { CURRENT_BIBLE_TEXT, localStorageGetItem, localStorageSetItem } from '../../services/LocalStorageService';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
});

class Bible extends Component {
  constructor(props) {
    super(props);

    const currentReading = localStorageGetItem(CURRENT_BIBLE_TEXT);
    const bookDefault = currentReading && currentReading.b;
    const chapterDefault = currentReading && currentReading.c;

    this.state = {
      handleChanged: false,
      verses: [],
      book: bookDefault || '',
      chapter: chapterDefault || '',
      sizeBooks: [50, 40, 27, 36, 34, 24, 21, 4, 31, 24, 22, 25, 29, 36, 10, 13, 10, 42, 150, 31, 12, 8, 66, 52, 5, 48, 12, 14, 3, 9, 1, 4, 7, 3, 3, 3, 2, 14, 4, 28, 16, 24, 21, 28, 16, 16, 13, 6, 6, 4, 4, 5, 3, 6, 4, 3, 1, 13, 5, 5, 3, 5, 1, 1, 1, 22],
    };
  }

  loadTextsByQueryStrings = (paramsQuery) => {
    if (paramsQuery && paramsQuery.b) {
      this.setState({
        book: paramsQuery.b - 1,
        chapter: paramsQuery.c,
        chapters: this.createIndexesChapters(paramsQuery.b)
      }, () => {
        this.getTexts();
      });
    }
  };

  getTexts = async (params) => {
    let { book } = this.props.match.params;

    let gurl = this.props.location.pathname;
    let parts_l = gurl.split('/');
    book = parts_l[parts_l.length - 1];

    book = (Number.isInteger(parseInt(book)) && book >= 0 && book < 66) ? parseInt(book) : 0;

    const b = this.state.book || book || 0;
    const c = this.state.chapter || 1;

    const strParams = '/' + b + '/' + c;

    const { translate: lang_props } = this.props;
    const strLan = lang_props === 'en' ? '_en' : '';

    await fetch(API_URL + '/bible' + strLan + strParams)
      .then(response => response.json())
      .then(data => {
        this.setState({
          verses: data
        });
      });
  }

  sortVerses = (a, b) => {
    if (parseInt(a.ver) > parseInt(b.ver)) {
      return 1;
    }
    if (parseInt(a.ver) < parseInt(b.ver)) {
      return -1;
    }
    return 0;
  }

  componentDidMount() {
    LogService.trackEvent(events["page_loaded"], {
      namePage: "bible",
      description: eventsDescriptions["page_loaded"],
    });
  }

  componentWillMount() {
    const strQuery = this.props.location.search;
    const paramsQuery = Boolean(strQuery) ? queryString.parse(strQuery) : '';

    if (paramsQuery && paramsQuery.b) {
      this.loadTextsByQueryStrings(paramsQuery)
    } else {
      this.getTexts();
    }
    this.setState({
      chapters: this.createIndexesChapters()
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.translate !== this.props.translate) {
      this.getTexts();
    }
  }

  createIndexesBooks(t, begin, end) {
    let books = []
    for (let j = begin; j < end; j++) {
      const index = 'BOOKS_BIBLE.' + j || "";
      books.push(<option value={parseInt(j)} key={index}> {t(index)}</option>)
    }
    return books;
  };

  createIndexesChapters(chapterId) {
    const { sizeBooks } = this.state;
    const chapters = [];

    chapterId = (Number.isInteger(chapterId) === false && chapterId === '') ? 0 : parseInt(chapterId);

    for (let i = 1; i <= sizeBooks[chapterId]; i++) {
      chapters.push(<option value={i} key={i}> {i} </option>)
    }
    return chapters;
  };

  handleChangeBook = event => {
    document.getElementById("chapter-simple").options[0].selected = true;
    const value = event.target.value;

    this.persistsReading(1, value);
    this.setState({
      book: value,
      chapter: 1,
      chapters: this.createIndexesChapters(value),
      handleChanged: true
    }, () => {
      this.getTexts();
    });

    LogService.trackEvent(events["text_bible_selected"], {
      text: {
        book: ARRAY_BOOKS[value].simpleName,
        chapter: "1"
      },
      namePage: "bible",
      description: eventsDescriptions["text_bible_selected"],
    });
  };

  handleChange = event => {
    const { book } = this.state;

    this.persistsReading(event.target.value, book);
    this.setState({ [event.target.name]: event.target.value, handleChanged: true }, () => {
      this.getTexts();
    });
    const b = book || 0;
    LogService.trackEvent(events["text_bible_selected"], {
      text: {
        book: ARRAY_BOOKS[b].simpleName,
        chapter: event.target.value || "1"
      },
      namePage: "bible",
      description: eventsDescriptions["text_bible_selected"],
    });
  };

  persistsReading(chapter, book) {
    localStorageSetItem(CURRENT_BIBLE_TEXT, { b: book, c: chapter })
  }

  render() {
    let { book: bookUrl } = this.props.match.params;
    const { t, classes } = this.props;
    let { verses, book, chapters, handleChanged } = this.state;
    const bookId = verses && verses.length && verses[0].livro;
    const strBookName = 'BOOKS_BIBLE.' + bookId;
    const chapterId = verses && verses.length && verses[0].cap;

    let gurl = this.props.location.pathname;
    let parts_l = gurl.split('/');
    bookUrl = parts_l[parts_l.length - 1];
    bookUrl = (Number.isInteger(parseInt(bookUrl)) && bookUrl >= 0 && bookUrl < 66) ? parseInt(bookUrl) : 0;
    const bookChapters = parseInt(book) || bookUrl || 0;

    chapters = this.createIndexesChapters(bookChapters);

    const strQuery = this.props.location.search;

    const currentBibleText = localStorageGetItem(CURRENT_BIBLE_TEXT);

    const paramsQuery = (Boolean(strQuery) ? queryString.parse(strQuery) : '');
    const selectedVerse = (!handleChanged ? (paramsQuery && paramsQuery.v) : ((currentBibleText && currentBibleText.v) ? currentBibleText.v : ''));

    if (!currentBibleText) {
      this.persistsReading(chapterId, bookChapters);
    }

    return (
      <div id='bible'>

        {(verses && verses.length)
          ?
          <div>
            <Toolbar className='tool-bar'>
              <div className='content-form'>
                <form className={classes.root} autoComplete="off">
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="book-simple">{t('BOOK')}</InputLabel>
                    <NativeSelect
                      onChange={this.handleChangeBook}
                      defaultValue={this.state.book || 0}
                      inputProps={{
                        name: 'book',
                        id: 'book-simple',
                      }}
                    >
                      <option value={0}>
                        {t('BOOKS_BIBLE.0')}
                      </option>
                      {this.createIndexesBooks(t, 1, 66)}
                    </NativeSelect>
                  </FormControl>
                  {bookChapters !== undefined && chapters && chapters.length && <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="chapter-simple">{t('CHAPTER')}</InputLabel>
                    <NativeSelect
                      defaultValue={this.state.chapter || 1}
                      onChange={this.handleChange}
                      inputProps={{
                        name: 'chapter',
                        id: 'chapter-simple',
                      }}
                    >
                      {chapters}
                    </NativeSelect>
                  </FormControl>}
                </form>
              </div>
            </Toolbar>
            <div className='read-content'>
              <h2 className="be-title-page">
                {t(strBookName)}, <span>{t('CHAPTER')} {chapterId}</span>
              </h2>
              {(verses && verses.length) ? verses.sort(this.sortVerses).map((verse, i) => {
                return (
                  <p key={i} className={(selectedVerse && parseInt(selectedVerse) === verse.ver) ? 'selected-verse' : ''}>
                    <span className='id-verse'> {verse.ver} </span>
                    {verse.texto}
                  </p>
                )
              }) : ""}
            </div>
          </div>
          :
          <div className='loading-be'>
            <img alt="imagem-ilustrativa" src="/images/loading_be.gif" />
            <p>
              Carregando texto...
            </p>
          </div>
        }
      </div>
    );
  }
}

Bible.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object
};

const mapStateToProps = store => ({
  translate: store.headerState.translate
});

export default withRouter(withStyles(styles)(withTranslation()(connect(mapStateToProps)((Bible)))));