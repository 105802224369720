import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import drawBarCode from './Draw';
import '../../../styles/styles.css';
import './BarCodeChart.css';

class BarCodeChart extends Component {

  componentDidMount() {
    const { data, word } = this.props;
    data &&
      drawBarCode(data, word);
  }

  render() {
    return (
      <div>
        <div className="bar-code-chart-component" id='area-bar-code-chart-component'>
        </div>
      </div>
    );
  }
}

BarCodeChart.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object,
  data: PropTypes.arrayOf(PropTypes.object),
  word: PropTypes.string
};

export default withTranslation()(BarCodeChart);