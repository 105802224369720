import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import LogService from '../../services/LogService';
import { events, eventsDescriptions } from '../../data/data';

import { withTranslation } from 'react-i18next';
import '../../styles/styles.css';
import './Gallery.css';

const styles = {
  card: {
    maxWidth: 345,
  }
};

class CardGalleryItem extends Component {
  logVisualization() {
    const { title, link } = this.props;
    LogService.trackEvent(events["vis_loaded"], {
      visualization: {
        name: title,
        link: link
      },
      namePage: "viz",
      description: eventsDescriptions["vis_loaded"],
    });
  }
  render() {
    const { t, classes, imageId, imageTitle, title, description, link } = this.props;
    return (
      <div className='card-gallery-item'>
        <Link to={{
          pathname: '/viz',
          state: {
            link: link
          }
        }}
          onClick={() => this.logVisualization()}
        >
          <Card className={classes.card + ' be-card'}>
            <CardActionArea>
              <CardMedia
                className='area-media'
                image={imageId}
                title={imageTitle}
              />
              <CardContent className='area-content'>
                <Typography gutterBottom variant="h5" component="h2">
                  {title}
                </Typography>
                <Typography component="p">
                  {description}
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button size="small" color="primary">
                {t('BIBLE_ANALYSIS.LEARN_MORE')}
              </Button>
            </CardActions>
          </Card>
        </Link>
      </div>
    );
  }
}

CardGalleryItem.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object,
  imageId: PropTypes.string,
  imageTitle: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string
};

export default withStyles(styles)(withTranslation()(CardGalleryItem));