import React from 'react';
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import '../../../styles/styles.css';
import './Card.css';

const Card = ({ number, label }) => (
  <div className='card-dashboard'>
    <p className='featured-number'> {number} </p>
    <p className='label-number'> {label} </p>
  </div>
);
export default withRouter(withTranslation()(Card));