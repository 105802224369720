import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import TextInfo from '../../../../components/textInfo/TextInfo';
import '../../../../styles/styles.css';
import '../../Search.css';
import AreaResults from './AreaResults';

import LogService from '../../../../services/LogService';
import { events, eventsDescriptions } from '../../../../data/data';

const styles = {
  card: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
};

class Results extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      selected: {}
    };

    this.logClickSimilarTerm = this.logClickSimilarTerm.bind(this);
  }

  logClickSimilarTerm = (simlarTerm) => {
    const query = this.props.match.params.term;

    LogService.trackEvent(events["similar_term_clicked"], {
      simlarTerm: simlarTerm,
      term: query,
      namePage: "search",
      description: eventsDescriptions["similar_term_clicked"],
    });
  };

  handleChangeLike = (verseId, type) => {
    const { selected } = this.state;
    const typeButton = type ? 1 : 0;
    const typeInverse = type ? 0 : 1;
    let newSelected = JSON.parse(JSON.stringify(selected));
    newSelected[verseId + '_' + typeButton] = !selected[verseId] ? 'selected' : '';
    newSelected[verseId + '_' + typeInverse] = '';

    this.setState({
      selected: newSelected
    })
  }

  createLinksToTerms(terms) {
    let arrayTerms = [];
    let last = terms.length - 1;
    terms.map((term, i) => {
      const termSlug = term.replace('-', '');
      const url = '/search/' + termSlug;
      if (i < last) {
        arrayTerms.push(<span key={i} onClick={this.updateAll}> <Link className='link-term' onClick={() => this.logClickSimilarTerm(term)} to={url}>{term}</Link>,</span>);
      } else {
        arrayTerms.push(<span key={i} onClick={this.updateAll}> <Link className='link-term' onClick={() => this.logClickSimilarTerm(term)} to={url}>{term}</Link></span>);
      }
      return true;
    })
    return arrayTerms;
  };

  getUrlVisByTypeAndId(type, id) {
    const baseUrl = 'https://be-iframes.firebaseapp.com/';
    /* switch (type) {
      case 'p':
        return baseUrl + '#/tree/' + id;
      case 'lo':
        return baseUrl + '#/maps/ot/' + id;
      default:
        return baseUrl + '#/books';
    } */

    if (type === 'p') {
      return baseUrl + '#/tree/' + id;
    }

    return null;
  }

  render() {
    const { t, classes, translate: lang, verses, infoTerm, resourceType, hideCards } = this.props;
    const query = this.props.match.params.term;
    let { selected } = this.state;
    let versesCategorized = verses;

    return (
      <div id='results'>
        <div>
          { /* Dictionary */
            resourceType && resourceType.resource === 'all' && !hideCards
              && infoTerm && infoTerm.dictionary && infoTerm.dictionary.length && lang === 'pt' ?
              <Card className={classes.card + ' card-be card-dictionary'}>
                <CardContent>
                  {infoTerm.dictionary[0] &&
                    <div>
                      <h2 className='dictionary-title'> {infoTerm.dictionary[0].name} </h2>
                      {/* <p> {infoTerm.dictionary[0].description} </p> */}
                      <TextInfo str={infoTerm.dictionary[0].description}> </TextInfo>
                    </div>
                  }
                </CardContent>
              </Card> : ''
          }

          { /* Indexes */
            resourceType && resourceType.resource === 'all' && !hideCards
              && infoTerm && infoTerm.indexes && infoTerm.indexes.length && infoTerm.indexes[0] && infoTerm.indexes[0].type !== ""
              && infoTerm.indexes[0].type === "p" && lang === 'pt' ?
              <Card className={classes.card + ' card-be card-index'}>
                <CardContent className='area-index'>
                  {infoTerm.indexes[0] &&
                    <div>
                      <iframe title="Visualization" src={this.getUrlVisByTypeAndId(infoTerm.indexes[0].type, infoTerm.indexes[0].id)}></iframe>
                    </div>
                  }
                </CardContent>
              </Card> : ''
          }

          { /* Synonyms */
            resourceType && resourceType.resource === 'all'
              && infoTerm && infoTerm.w2v && infoTerm.w2v
              && infoTerm.w2v.similares.length && lang === 'pt'
              ?
              <p className='info-conections'>
                {t('SEARCH_RESULTS.SIMILAR_TERMS')}:
                  {this.createLinksToTerms(infoTerm.w2v.similares)}
              </p> : ''
          }

          <AreaResults
            verses={versesCategorized}
            query={query}
            lang={lang}
            resource={resourceType && resourceType.type}
            selected={selected}>
          </AreaResults>
        </div>

        {
          verses && verses.concordance && !verses.concordance.length && verses && verses.lucene && !verses.lucene.length &&
          <div className='empty-state'>
            <p>
              {t('SEARCH_PROCESS.EMPTY_STATE')}
            </p>
          </div>
        }
      </div>
    );
  }
}

Results.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  verses: PropTypes.arrayOf(PropTypes.object),
  infoTerm: PropTypes.object,
  resourceType: PropTypes.object,
  hideCards: PropTypes.bool
};

const mapStateToProps = store => ({
  translate: store.headerState.translate,
  query: store.headerState.query
});

export default withStyles(styles)(withRouter(withTranslation()(connect(mapStateToProps)(Results))));
