export const books = {
    "genesis": 1,
    "exodo": 2,
    "levitico": 3,
    "numeros": 4,
    "deuteronomio": 5,
    "josue": 6,
    "juizes": 7,
    "rute": 8,
    "1samuel": 9,
    "2samuel": 10,
    "1reis": 11,
    "2reis": 12,
    "1cronicas": 13,
    "2cronicas": 14,
    "esdras": 15,
    "neemias": 16,
    "ester": 17,
    "jo": 43,
    "salmos": 19,
    "proverbios": 20,
    "eclesiastes": 21,
    "cantares": 22,
    "cantico": 23,
    "canticos": 24,
    "isaias": 23,
    "jeremias": 24,
    "lamentacoes": 25,
    "lamentacões": 28,
    "lamentações": 25,
    "ezequiel": 26,
    "daniel": 27,
    "oseias": 28,
    "joel": 29,
    "amos": 30,
    "obadias": 31,
    "jonas": 32,
    "miqueias": 33,
    "naum": 34,
    "habacuque": 35,
    "sofonias": 36,
    "ageu": 37,
    "zacarias": 38,
    "malaquias": 39,
    "mateus": 40,
    "marcos": 41,
    "lucas": 42,
    "joao": 43,
    "atos": 44,
    "atosdosapostolos": 49,
    "romanos": 45,
    "1corintios": 46,
    "2corintios": 47,
    "galatas": 48,
    "efesios": 49,
    "filipenses": 50,
    "colossenses": 51,
    "1tessalonicenses": 52,
    "2tessalonicenses": 53,
    "1timoteo": 54,
    "2timoteo": 55,
    "tito": 56,
    "filemon": 57,
    "hebreus": 58,
    "tiago": 59,
    "1pedro": 60,
    "2pedro": 61,
    "1joao": 62,
    "2joao": 63,
    "3joao": 64,
    "judas": 65,
    "apocalipse": 66,
    "gênesis": 1,
    "êxodo": 2,
    "levítico": 3,
    "números": 4,
    "deuteronômio": 5,
    "josué": 6,
    "juízes": 7,
    "1 samuel": 9,
    "2 samuel": 10,
    "1 reis": 11,
    "2 reis": 12,
    "1 crônicas": 13,
    "2 crônicas": 14,
    "jó": 18,
    "provérbios": 20,
    "cântico dos cânticos": 22,
    "isaías": 23,
    "oséias": 28,
    "amós": 30,
    "miquéias": 33,
    "joão": 43,
    "atos dos apóstolos": 44,
    "2 coríntios": 47,
    "gálatas": 48,
    "efésios": 49,
    "1 tessalonicenses": 52,
    "2 tessalonicenses": 53,
    "1 timóteo": 54,
    "2 timóteo": 55,
    "gn": 1,
    "ex": 2,
    "lv": 3,
    "nm": 4,
    "dt": 5,
    "js": 6,
    "jz": 7,
    "rt": 8,
    "1sm": 9,
    "2sm": 10,
    "1rs": 11,
    "2rs": 12,
    "1cr": 13,
    "2cr": 14,
    "ed": 15,
    "ne": 16,
    "et": 17,
    "sl": 19,
    "pv": 20,
    "ec": 21,
    "ct": 22,
    "is": 23,
    "jr": 24,
    "lm": 25,
    "ez": 26,
    "dn": 27,
    "os": 28,
    "jl": 29,
    "am": 30,
    "ob": 31,
    "jn": 32,
    "mq": 33,
    "na": 34,
    "hc": 35,
    "sf": 36,
    "ag": 37,
    "zc": 38,
    "ml": 39,
    "mt": 40,
    "mc": 41,
    "lc": 42,
    "at": 44,
    "rm": 45,
    "1co": 46,
    "2co": 47,
    "gl": 48,
    "ef": 49,
    "fp": 50,
    "cl": 51,
    "1ts": 52,
    "2ts": 53,
    "1tm": 54,
    "2tm": 55,
    "tt": 56,
    "fm": 57,
    "hb": 58,
    "tg": 59,
    "1pe": 60,
    "2pe": 61,
    "1jo": 62,
    "2jo": 63,
    "3jo": 64,
    "jd": 65,
    "ap": 66,
    "apc": 66,
    "1 co": 46,
    "1 cor": 46,
    "1 corintios": 46,
    "1 coríntios": 46,
    "1 corinthians": 46,
    "2 corinthians": 46,
    "1 cr": 13,
    "1 cro": 13,
    "1 cronicas": 13,
    "1 jo": 62,
    "1 joao": 62,
    "1 joão": 62,
    "1 pe": 60,
    "1 pedro": 60,
    "1 rs": 11,
    "1 sm": 9,
    "1 timoteo": 54,
    "1 tm": 54,
    "1 ts": 52,
    "2 co": 47,
    "2 cor": 47,
    "2 corintios": 47,
    "2 cr": 14,
    "2 cro": 14,
    "2 cronicas": 14,
    "2 jo": 63,
    "2 joao": 63,
    "2 joão": 63,
    "2 pe": 61,
    "2 pedro": 61,
    "2 rs": 12,
    "2 sm": 10,
    "2 timoteo": 55,
    "2 tm": 55,
    "2 ts": 53,
    "3 jo": 64,
    "3 joao": 64,
    "3 joão": 64,
};