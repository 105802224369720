import React from 'react';
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import Card from '../card/Card';

import '../../../styles/styles.css';
import './AreaCards.css';

const getCount = (listQuestion) => {
  return listQuestion && listQuestion.reduce((total, item) => {
    return (total + item.count)
  }, 0);
};

const AreaCards = ({ data }) => {
  const showExplicability = ((data.sizeShowExplicability / data.sizeEvaluations * 100).toFixed(1) + '%') || '';

  const sizeLikedAnswer = getCount(data.liked);
  const sizeUnderstandAnswer = getCount(data.understand);
  const sizeTrustAnswer = getCount(data.trust);
  const sizeCommentAnswer = data.comments && data.comments.length;

  return (<div id='area-cards'>
    <div className='box-cards'>
      {data.sizeEvaluations ? <Card number={data.sizeEvaluations} label={'avaliações realizadas'}></Card> : ''}
      {data.sizeTerms ? <Card number={data.sizeTerms} label={'termos avaliados'}></Card> : ''}
      {(Boolean(showExplicability) && data.sizeEvaluations) ? <Card number={showExplicability} label={'viram a explicação'}></Card> : ''}
    </div>

    <div className='box-cards'>
      {sizeLikedAnswer ? <Card number={sizeLikedAnswer} label={'Responderam se gostaram dos resultados'}></Card> : ''}
      {sizeUnderstandAnswer ? <Card number={sizeUnderstandAnswer} label={'Responderam se entenderam as consultas'}></Card> : ''}
      {sizeTrustAnswer ? <Card number={sizeTrustAnswer} label={'Responderam se confiam dos resultados'}></Card> : ''}
      {sizeCommentAnswer ? <Card number={sizeCommentAnswer} label={'Deixaram um comentário'}></Card> : ''}
    </div>
  </div>);
};
export default withRouter(withTranslation()(AreaCards));