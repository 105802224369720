import { CLICK_UPDATE_VALUE, HANDLE_UPDATE_QUERY } from '../actions/actionTypes';

const local_lang_be = localStorage.getItem("language_be") || 'pt';
const url = window.location.href;
let localUrl = "";

if (url.indexOf('/search/') !== -1) {
  let partUrl = url.split("/");
  let queryUrl = partUrl[partUrl.length - 1];
  localUrl = queryUrl;
}

const initialState = {
  translate: local_lang_be,
  query: localUrl
};

export const headerReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLICK_UPDATE_VALUE:
      return {
        ...state,
        translate: action.translate
      };
    case HANDLE_UPDATE_QUERY:
      return {
        ...state,
        query: action.query
      };
    default:
      return state;
  }
};