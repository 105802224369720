import React from 'react';
import '../../../styles/styles.css';
import './Comments.css';

const Comments = ({ comments }) => (
  <div className='comments'>
    <h3> Comentários</h3>

    {comments && comments.length && comments.map((item, key) => {
      return (!(item.comment).toLowerCase().includes('teste'))
        ? (
          <div key={key} className='comment'>
            {item.comment}
          </div>
        )
        : (
          ''
        )
    })}
  </div>
);
export default Comments;