import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import * as d3 from 'd3v3';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import '../../../styles/styles.css';
import './PieChart.css';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
});

const draw = (results, idChard) => {
  var dataset = results;
  var width = 280;
  var height = 280;
  var radius = 110;
  var aColor = [
    '#6dbb9c',
    '#ffcc66',
    '#008db7',
    '#666699',
    '#e73b94',
    '#cc3333',
    '#fe863d',
    '#c8c94d',
    '#a2766e',
    '#737a84',
  ];

  d3.select(`#pie-chart-${idChard}`).selectAll("*").remove();
  var svg = d3.select(`#pie-chart-${idChard}`)
    .append("svg:svg").attr("width", width).attr("height", height)
    .append("svg:g")
    .attr("transform", "translate(" + radius + "," + radius + ")");

  var arc = d3.svg.arc()
    .outerRadius(radius)
    .innerRadius(70);

  var pie = d3.layout.pie()
    .sort(null)
    .value(function (d) { return d.value; });

  var g = svg.selectAll(".fan")
    .data(pie(dataset))
    .enter()
    .append("g")
    .attr("class", "fan")

  var total = 0;
  dataset.forEach((item) => {
    total += item.value;
  });

  g.append("path")
    .attr("d", arc)
    .attr("fill", function (d, i) { return aColor[i]; })

  g.append("text")
    .attr("transform", function (d) {
      d.innerRadius = 100; /* Distance of label to the center*/
      d.outerRadius = radius;
      return "translate(" + arc.centroid(d) + ")";
    }
    )
    .attr("text-anchor", "middle")
    .text(function (d, i) { return Number(dataset[i].value / total * 100).toFixed(1) + '%'; })
    ;
};

class PieChart extends Component {

  componentDidMount() {
    const { results, id } = this.props;
    draw(results, id);
  }

  componentDidUpdate(prevProps) {
    const { results, id } = this.props;

    if (prevProps.results !== results) {
      draw(results, id);
    }
  }

  render() {
    const { results, id } = this.props;
    console.log(results);
    results.sort((a, b) => {
      return a.weight - b.weight
    })

    return (
      <div className='area-pie-chart'>
        <div id={`pie-chart-${id}`}>
        </div>
        <div className='legend'>
          {results && results.map((item, key) => (
            <div key={key} className={`item-pie-${key + 1}`}>
              <div className='color-item-pie'></div>
              <span>{item.label}</span>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

PieChart.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object,
  results: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
};

export default withRouter(withStyles(styles)(withTranslation()(PieChart)));