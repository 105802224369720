import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';

import '../../styles/styles.css';
import './Gallery.css';

const styles = {
    card: {
        maxWidth: 345,
    }
};

class Visualization extends Component {

    render() {
        const { t } = this.props;
        const { link } = this.props.location.state
        const baseUrl = 'https://be-iframes.firebaseapp.com/';

        return (
            <div id="visualiation-page" className='page'>
                <Toolbar className='tool-bar'>
                    <Link className='link-page' to='/gallery'> {t('HEADER.MENU.VISUALIZATIONS')} </Link>
                    <span className='message'> *{t('MESSAGES.NO_TRANSLATE')} </span>
                </Toolbar>
                <iframe src={baseUrl + link} title='Visualization'></iframe>
            </div>
        );
    }
}

Visualization.propTypes = {
    t: PropTypes.func.isRequired,
    link: PropTypes.string
};

export default withRouter(withStyles(styles)(withTranslation()(Visualization)));