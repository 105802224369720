import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getLinksByDictionaryText } from '../../utils/utils';

import { withTranslation } from 'react-i18next';

import '../../styles/styles.css';
import './TextInfo.css';

class TextInfo extends Component {

  getIndexBible = (text) => {

  }

  render() {
    const { str } = this.props;

    return (
      <div>
        <p dangerouslySetInnerHTML={{ __html: getLinksByDictionaryText(str) }}></p>
      </div>
    );
  }
}

TextInfo.propTypes = {
  str: PropTypes.string
};

export default withTranslation()(TextInfo);