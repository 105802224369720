import * as d3 from 'd3v3';
import { COLORS } from '../theme/Colors';

const draw = (graph, areaId, word) => {
  const nodesArray = graph && graph.nodes;
  const linksArray = graph && graph.links;
  const maxNode = d3.max(nodesArray, (d) => d.size);
  const maxLink = d3.max(linksArray, (d) => d.value);
  const sizeScale = d3.scale.linear().domain([0, maxNode]).range([1, 6]);
  const strokeLink = d3.scale.linear().domain([0, maxLink]).range([.4, 2]);
  const distLink = d3.scale.linear().domain([1, maxLink]).range([50, 300]);

  graph.nodes && graph.nodes.forEach((d) => {
    d.occurrences = d.size;
    if (d.id === word) {
      d.size = 5
    } else {
      d.size = sizeScale(d.size)
    }
    if (d.id === word) {
      d.color = COLORS.blue;
    } else if (areaId === 'w2v') {
      d.color = COLORS.purple_1;
    } else {
      d.color = COLORS.rose;
    }
  });

  const linkDistance = (d) => {
    if (areaId === 'concordance') {
      return 200;
    }
    // const dis = (d.value * 10) + 20;
    return distLink(d.value);
  }

  let w = 1000;
  let h = 600;
  let keyc = true, keys = true, keyt = true, keyr = true, keyx = true, keyd = true,
    keyl = true, keym = true, keyh = true, key1 = true, key2 = true, key3 = true, key0 = true;
  let focus_node = null, highlight_node = null;
  const text_center = false;
  const min_score = 0;
  const max_score = 1;
  const color = d3.scale.linear()
    .domain([min_score, (min_score + max_score) / 2, max_score])
    .range(['lime', 'yellow', 'red']);
  const highlight_color = 'white';
  const highlight_trans = 0.1;
  const size = d3.scale.pow().exponent(1)
    .domain([1, 100])
    .range([8, 24]);
  const force = d3.layout.force()
    .linkDistance(linkDistance)
    .charge(-500)
    .size([w, h]);

  const default_link_color = '#888';
  const nominal_base_node_size = 8;
  const nominal_text_size = 10;
  const max_text_size = 24;
  const nominal_stroke = 1.5;
  const max_stroke = 4.5;
  const max_base_node_size = 36;
  const min_zoom = 0.1;
  const max_zoom = 7;
  const svg = d3.select('#force-graph-component-' + areaId).append('svg');
  const zoom = d3.behavior.zoom().scaleExtent([min_zoom, max_zoom])
  const g = svg.append('g');

  svg.style('cursor', 'move');

  const linkedByIndex = {};

  graph.links && graph.links.forEach((d) => {
    linkedByIndex[d.source + ',' + d.target] = true;
  });

  const isConnected = (a, b) =>
    (linkedByIndex[a.index + ',' + b.index] || linkedByIndex[b.index + ',' + a.index] || a.index === b.index);

  const hasConnections = (a) => {
    for (let property in linkedByIndex) {
      const s = property.split(',');
      if ((s[0] === a.index || s[1] === a.index) && linkedByIndex[property])
        return true;
    }
    return false;
  };

  force
    .nodes(graph.nodes)
    .links(graph.links)
    .start();

  svg.append('svg:defs').selectAll('marker')
    .data(['end'])
    .enter().append('svg:marker')
    .attr('id', String)
    .attr('viewBox', '0 -5 10 10')
    .attr('refX', 0)
    .attr('refY', 0)
    .attr('markerWidth', 4)
    .attr('markerHeight', 4)
    .attr('orient', 'auto')
    .append('svg:path')
    .attr('d', 'M0,-5L10,0L0,5');

  const link = g.selectAll('.link')
    .data(graph.links)
    .enter().append('svg:path')
    .attr('class', 'link')
    .style('stroke-width', (d) => strokeLink(d.value))
    .style('stroke', (d) => {
      if (isNumber(d.score) && d.score >= 0) return color(d.score);
      else return default_link_color;
    })
    .attr('class', function (d) { return 'link ' + d.type; })
    .attr('marker-end', 'url(#end)');

  let node = g.selectAll('.node')
    .data(graph.nodes)
    .enter().append('g')
    .attr('class', 'node')
    .call(force.drag)

  node.on('dblclick.zoom', (d) => {
    d3.event.stopPropagation();
    let dcx = (w / 2 - d.x * zoom.scale());
    let dcy = (h / 2 - d.y * zoom.scale());
    zoom.translate([dcx, dcy]);
    g.attr('transform', 'translate(' + dcx + ',' + dcy + ')scale(' + zoom.scale() + ')');
  });


  let towhite = 'stroke';

  let circle = node.append("circle")
    .attr("r", function (d) {
      return d.size * 4;
    })
    .attr('fill', (d) => d.color)
    .style(towhite, 'white')
    .call(force.drag);

  let text = g.selectAll('.text')
    .data(graph.nodes)
    .enter().append('text')
    .attr('dy', '.35em')
    .style('font-size', nominal_text_size + 'px')

  if (text_center)
    text.text((d) => d.id)
      .style('text-anchor', 'middle');
  else
    text.attr('dx', (d) => (size(d.size) || nominal_base_node_size))
      .text((d) => '\u2002' + d.id);

  node
    .on('mouseover', (d) => {
      set_highlight(d);
    })
    .on('mousedown', (d) => {
      d3.event.stopPropagation();
      focus_node = d;
      set_focus(d)
      if (highlight_node === null)
        set_highlight(d)
    })
    .on('mouseout', (d) => {
      exit_highlight();
    });

  d3.select(window)
    .on('mouseup', () => {
      if (focus_node !== null) {
        focus_node = null;
        if (highlight_trans < 1) {
          circle.style('opacity', 1);
          text.style('opacity', 1);
          link.style('opacity', 1);
        }
      }
      if (highlight_node === null)
        exit_highlight();
    });

  const exit_highlight = () => {
    highlight_node = null;
    if (focus_node === null) {
      svg.style('cursor', 'move');
      if (highlight_color !== 'white') {
        circle.style(towhite, 'white');
        text.style('font-weight', 'normal');
        link.style('stroke', (o) => (isNumber(o.score) && o.score >= 0) ? color(o.score) : default_link_color);
      }
    }
  };

  const set_focus = (d) => {
    if (highlight_trans < 1) {
      circle.style('opacity', (o) => isConnected(d, o) ? 1 : highlight_trans);
      text.style('opacity', (o) => isConnected(d, o) ? 1 : highlight_trans);
      link.style('opacity', (o) => o.source.index === d.index || o.target.index === d.index ? 1 : highlight_trans);
    }
  };

  const set_highlight = (d) => {
    svg.style('cursor', 'pointer');
    if (focus_node !== null)
      d = focus_node;
    highlight_node = d;

    if (highlight_color !== 'white') {
      circle
        .style(towhite, (o) => isConnected(d, o) ? highlight_color : 'white');
      text
        .style('font-weight', (o) => isConnected(d, o) ? 'bold' : 'normal');
      link
        .style('stroke', (o) =>
          o.source.index === d.index || o.target.index === d.index ? highlight_color : ((isNumber(o.score) && o.score >= 0) ? color(o.score) : default_link_color)
        );
    }
  };

  zoom.on('zoom', () => {
    let stroke = nominal_stroke;
    if (nominal_stroke * zoom.scale() > max_stroke)
      stroke = max_stroke / zoom.scale();

    link.style('stroke-width', (d) => strokeLink(d.value));
    circle.style('stroke-width', stroke);

    let base_radius = nominal_base_node_size;
    if (nominal_base_node_size * zoom.scale() > max_base_node_size)
      base_radius = max_base_node_size / zoom.scale();

    circle.attr('d', d3.svg.symbol()
      .size((d) => Math.PI * Math.pow(size(d.size) * base_radius / nominal_base_node_size || base_radius, 2))
      .type((d) => d.type))

    if (!text_center) text.attr('dx', (d) => (size(d.size) * base_radius / nominal_base_node_size || base_radius));

    let text_size = nominal_text_size;
    if (nominal_text_size * zoom.scale() > max_text_size) text_size = max_text_size / zoom.scale();
    text.style('font-size', text_size + 'px');

    g.attr('transform', 'translate(' + d3.event.translate + ')scale(' + d3.event.scale + ')');
  });

  const resize = () => {
    let width = w, height = h;
    svg.attr('width', width).attr('height', height);

    force
      .size([
        force.size()[0] + (width - w) / zoom.scale(),
        force.size()[1] + (height - h) / zoom.scale()
      ])
      .resume();

    w = width;
    h = height;
  };

  const keydown = () => {
    if (d3.event.keyCode === 32) {
      force.stop();
    } else if (d3.event.keyCode >= 48 && d3.event.keyCode <= 90
      && !d3.event.ctrlKey && !d3.event.altKey && !d3.event.metaKey) {

      switch (String.fromCharCode(d3.event.keyCode)) {
        case 'C': keyc = !keyc; break;
        case 'S': keys = !keys; break;
        case 'T': keyt = !keyt; break;
        case 'R': keyr = !keyr; break;
        case 'X': keyx = !keyx; break;
        case 'D': keyd = !keyd; break;
        case 'L': keyl = !keyl; break;
        case 'M': keym = !keym; break;
        case 'H': keyh = !keyh; break;
        case '1': key1 = !key1; break;
        case '2': key2 = !key2; break;
        case '3': key3 = !key3; break;
        case '0': key0 = !key0; break;
        default: key0 = !key0; break;
      }

      link
        .style('display', (d) => {
          let flag = vis_by_type(d.source.type) && vis_by_type(d.target.type)
            && vis_by_node_score(d.source.score) && vis_by_node_score(d.target.score) && vis_by_link_score(d.score);

          linkedByIndex[d.source.index + ',' + d.target.index] = flag;
          return flag ? 'inline' : 'none';
        });
      node
        .style('display', (d) =>
          (key0 || hasConnections(d)) && vis_by_type(d.type) && vis_by_node_score(d.score) ? 'inline' : 'none'
        );
      text
        .style('display', (d) =>
          (key0 || hasConnections(d)) && vis_by_type(d.type) && vis_by_node_score(d.score) ? 'inline' : 'none'
        );

      if (highlight_node !== null) {
        if ((key0 || hasConnections(highlight_node))
          && vis_by_type(highlight_node.type) && vis_by_node_score(highlight_node.score)) {
          if (focus_node !== null)
            set_focus(focus_node);
          set_highlight(highlight_node);
        } else {
          exit_highlight();
        }
      }
    }
  };

  // function tick() {
  //   link.attr('d', linkArc);
  //   circle.attr('transform', transform);
  //   text.attr('transform', transform);
  // }

  function tick() {
    link.attr("d", function (d) {
      var dx = d.target.x - d.source.x,
        dy = d.target.y - d.source.y,
        dr = Math.sqrt(dx * dx + dy * dy);
      return "M" + d.source.x + "," + d.source.y + "A" + dr + "," + dr + " 0 0,1 " + d.target.x + "," + d.target.y;
    });
    link.attr("d", function (d) {
      var pl = this.getTotalLength(),
        r = (d.target.size) * 4 + 6,
        m = this.getPointAtLength(pl - r);

      var dx = m.x - d.source.x,
        dy = m.y - d.source.y,
        dr = Math.sqrt(dx * dx + dy * dy);
      return "M" + d.source.x + "," + d.source.y + "A" + dr + "," + dr + " 0 0,1 " + m.x + "," + m.y;
    });
    circle.attr("transform", transform);
    text.attr("transform", transform);
  }

  function transform(d) {
    return 'translate(' + d.x + ',' + d.y + ')';
  }

  // function linkArc(d) {
  //   var dx = d.target.x - d.source.x,
  //     dy = d.target.y - d.source.y,
  //     dr = Math.sqrt(dx * dx + dy * dy);
  //   return 'M' + d.source.x + ',' + d.source.y + 'A' + dr + ',' + dr + ' 0 0,1 ' + d.target.x + ',' + d.target.y;
  // }

  svg.call(zoom);
  resize();

  d3.select(window)
    .on('resize', resize)
    .on('keydown', keydown);


  force.on('tick', tick);

  function vis_by_type(type) {
    switch (type) {
      case 'circle': return keyc;
      case 'square': return keys;
      case 'triangle-up': return keyt;
      case 'diamond': return keyr;
      case 'cross': return keyx;
      case 'triangle-down': return keyd;
      default: return true;
    }
  }

  function vis_by_node_score(score) {
    if (isNumber(score)) {
      if (score >= 0.666)
        return keyh;
      else if (score >= 0.333)
        return keym;
      else if (score >= 0)
        return keyl;
    }
    return true;
  }

  function vis_by_link_score(score) {
    if (isNumber(score)) {
      if (score >= 0.666)
        return key3;
      else if (score >= 0.333)
        return key2;
      else if (score >= 0)
        return key1;
    }
    return true;
  }

  function isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }
};

export default draw;