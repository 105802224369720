import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import LogService from '../../../../services/LogService';
import { events, eventsDescriptions } from '../../../../data/data';

import '../../../../styles/styles.css';
import '../../Search.css';
import './AreaResults.css';

import ResultsByPage from './ResultsByPage';

class AreaResults extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPagination: 0,
      listNumbers: [],
      currentListNumbers: [],
      objPagination: [],
      positionListNumber: 0
    }

    this.changeCurrentPagination = this.changeCurrentPagination.bind(this);
    this.changeListPrev = this.changeListPrev.bind(this);
    this.changeListNext = this.changeListNext.bind(this);
    this.buildObjPagination = this.buildObjPagination.bind(this);
  }

  changeCurrentPagination = (number) => {
    const { query } = this.props;
    this.setState({ currentPagination: number });

    LogService.trackEvent(events["item_pagination_clicked"], {
      page: number,
      term: query,
      namePage: "search",
      description: `${eventsDescriptions["item_pagination_clicked"]} ${number}`,
    });
  };

  changeListNext = () => {
    const { positionListNumber, listNumbers } = this.state;
    const ln = JSON.parse(JSON.stringify(listNumbers));
    const pn = JSON.parse(JSON.stringify(positionListNumber + 1));
    const { query } = this.props;

    if (pn < listNumbers.length) {
      this.setState({ currentListNumbers: ln[pn], positionListNumber: pn }, () => {
        LogService.trackEvent(events["next_page_clicked"], {
          page: ln[pn],
          term: query,
          namePage: "search",
          description: eventsDescriptions["next_page_clicked"],
        });
      });
    }
  }

  changeListPrev = () => {
    const { positionListNumber, listNumbers } = this.state;
    const ln = JSON.parse(JSON.stringify(listNumbers));
    const pn = JSON.parse(JSON.stringify(positionListNumber - 1));
    const { query } = this.props;

    if (pn < listNumbers.length) {
      this.setState({ currentListNumbers: ln[pn], positionListNumber: pn }, () => {
        LogService.trackEvent(events["back_page_clicked"], {
          page: ln[pn],
          term: query,
          namePage: "search",
          description: eventsDescriptions["back_page_clicked"],
        });
      });
    }
  }

  buildObjPagination(verses) {
    let objPagination = [];
    let listN = [];
    let numPagination = 0;
    let numPage = 0;

    const versesCopy = verses ? JSON.parse(JSON.stringify(verses)) : [];
    versesCopy.forEach((ver, key) => {
      numPagination = Math.floor(key / 20);

      if (!objPagination[numPagination]) objPagination[numPagination] = [];
      objPagination[numPagination].push(ver);
    });

    objPagination.forEach((item, key) => {
      numPage = Math.floor(key / 5);
      if (!listN[numPage]) listN[numPage] = [];
      listN[numPage].push(key);
    });

    this.setState({
      listNumbers: listN,
      currentPagination: 0,
      positionListNumber: 0,
      objPagination: objPagination,
      currentListNumbers: listN[0]
    });
  }

  componentDidMount() {
    const { verses } = this.props;
    this.buildObjPagination(verses);
  }

  componentDidUpdate(prevProps) {
    const { verses } = this.props;

    if ((prevProps.verses && prevProps.verses.length && prevProps.verses[0]) !== (verses.length && verses[0])) {
      this.buildObjPagination(verses);
    }
  }

  render() {
    const { verses, resource, lang, selected, query } = this.props;
    const { currentPagination, currentListNumbers, objPagination } = this.state;

    return (
      <div className='area-results'>
        {/* {resource === 'direct' &&
          <h4 className="direct">
            Resultados retornados pela <span className="resource">consulta direta</span>
          </h4>
        }
        {resource === 'concordance' &&
          <h4 className="concordance">
            Resultados retornados pela <span className="resource">Concordância Bíblica</span>
          </h4>
        }
        {resource === 'w2v' &&
          <h4 className="w2v">
            Resultados retornados pelos <span className="resource">termos relacionados</span>
          </h4>
        } */}

        <ResultsByPage current={currentPagination}
          list={objPagination[currentPagination]}
          listNumbers={currentListNumbers}
          handleChangeCurrent={this.changeCurrentPagination}
          lang={lang}
          query={query}
          resource={resource}
          objPagination={objPagination}
          selected={selected}
          handleChangeNext={this.changeListNext}
          handleChangePrev={this.changeListPrev}
          totalPages={objPagination.length}
        />

        {(verses && !verses.length) ?
          <div className="empty-specific-results">
            <p> Nenhum resultado encontrado. </p>
          </div>
          : ''}
      </div>

    );
  }
}

AreaResults.propTypes = {
  t: PropTypes.func.isRequired,
  verses: PropTypes.arrayOf(PropTypes.object),
  lang: PropTypes.string,
  resource: PropTypes.string,
  query: PropTypes.string,
  selected: PropTypes.object
};

export default withTranslation()(AreaResults);