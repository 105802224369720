import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router'

import LogService from '../../services/LogService';
import { events, eventsDescriptions } from '../../data/data';
import { Link } from 'react-router-dom';

import '../../styles/styles.css';
import './Logs.css';
import { copyObject } from '../../utils/utils';
import AreaChartsLogs from '../../components/dashboard/areaChartsLogs/AreaChartsLogs';
import AreaWordCloud from '../../components/dashboard/areaWordCloud/AreaWordCloud';
import AuthInput from '../../components/authInput/AuthInput';

class Logs extends Component {
  constructor(props) {
    super(props);
    const authBiblexplorer = sessionStorage.getItem('authBiblexplorerReports');

    this.state = {
      logs: [],
      resume: {},
      hasAuthorization: Boolean(authBiblexplorer),
    }
    this.getField = this.getField.bind();
    this.sendCode = this.sendCode.bind(this);
  }

  getField(event) {
    const str = event && event.eventType && event.eventType.toLowerCase();

    if (!str) {
      return ''
    }
    if (str.includes('page_')) {
      return event && (event.namePage || event.page)
    }
    if (str.includes('vis_')) {
      return event && event.vis
    }
    if (str.includes('tab_')) {
      return event && event.tab
    }
    if (str.includes('book_')) {
      return event && event.book
    }
    if (str.includes('verse_')) {
      return event && (event.verseId || event.verse)
    }
    if (str.includes('input_')) {
      return event && event.from
    }
    if (str.includes('pagination_')) {
      return event && event.page
    }
    if (str.includes('sort_')) {
      return event && ('ordenar por' + event.sortBy)
    }
    if (str.includes('term_')) {
      return event && event.term
    }
    if (str.includes('text_')) {
      return event && event.text && (event.text.book + ' ' + event.text.chapter)
    }

    return '';
  }

  componentWillMount = async () => {
    const logs = await LogService.getLogsByEvent(events.term_searched);
    const resume = await LogService.getLogsLogsResume();
    this.setState({ logs: logs, resume: resume });
  }

  sendCode = async (code) => {
    if (code !== '') {
      const result = await LogService.sendAuthenticationViewReports({ code });
      const success = result && result.success;
      if (success) {
        sessionStorage.setItem('authBiblexplorerReports', true);
        this.setState({ hasAuthorization: true })
      }
    }
  }

  render() {
    const { logs, resume, hasAuthorization } = this.state;
    return (
      <div id='logs'>
        <div className='logo-logs'>
          <img className='logo' src='/images/logo-rosa.png' alt='Biblexplorer' />
          {hasAuthorization
            ? <div className='header-logs-biblexplorer'>
              <Link to="/logs-biblexplorer-2020/logs"> <div className='item-header-logs'>
                Relatório de uso
              </div> </Link>
              <Link to="/logs-biblexplorer-2020/evaluation"> <div className='item-header-logs'>
                Relatório de avaliações
              </div></Link>
              <Link to="/logs-biblexplorer-2020/explicability"> <div className='item-header-logs'>
                Análises
              </div></Link>
            </div> : ''
          }
        </div>
        {hasAuthorization
          ?
          <div className='box-logs-dashboard'>
            <div className='box-charts'>
              <AreaChartsLogs data={resume} />
            </div>

            <div className='box-word-searches'>
              <AreaWordCloud data={resume.search} title={'O que as pessoas estão pesquisando '} />
            </div>
            <div className='box-logs-last-searches'>
              <h3>Últimas pesquisas na ferramenta</h3>
              {(logs && logs.length)
                ? logs.map((event, k) => {
                  const idEvent = event && event.eventType && event.eventType.toLowerCase();
                  const description = eventsDescriptions ? eventsDescriptions[idEvent] : '';
                  const resumeToken = event && event.tokenId && event.tokenId.substr(0, 8);

                  const field = this.getField(copyObject(event));

                  return idEvent
                    ?
                    <div className='row-log' key={k}>
                      Usuário <span className='destaque'>  {resumeToken}</span> {description} <span className='destaque destaque-2'> {field} </span>
                    </div>
                    :
                    <div key={k}></div>;
                })
                : <center> Não há logs de uso registrados. </center>}
            </div>
          </div>
          :
          <AuthInput sendCode={this.sendCode}></AuthInput>}
      </div>
    );
  }
}

Logs.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object
};

export default withRouter(withTranslation()(Logs));