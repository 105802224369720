import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";

import { withTranslation } from 'react-i18next';

import '../../styles/styles.css';
import './SocialNetworks.css';

class SocialNetworks extends Component {
  render() {
    return (
      <div className="social-networks">
        <div className="area-footer">
          <div className="box-icons">
            <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/biblexplorer/">
              <div className="icon" id="icon-02">
              </div>
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://www.tiktok.com/@biblexplorer">
              <div className="icon" id="icon-05">
              </div>
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/biblexplorerbr">
              <div className="icon" id="icon-01">
              </div>
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/biblexplorer_br">
              <div className="icon" id="icon-03">
              </div>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

SocialNetworks.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object
};

export default withRouter(withTranslation()(SocialNetworks));