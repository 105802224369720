const hashData = require('./booksNames');

const BOOKS_BIBLE = {
  "genesis": {
    "name": "Gênesis",
    "id": 1
  },
  "exodo": {
    "name": "Êxodo",
    "id": 2
  },
  "levitico": {
    "name": "Levítico",
    "id": 3
  },
  "numeros": {
    "name": "Números",
    "id": 4
  },
  "deuteronomio": {
    "name": "Deuteronômio",
    "id": 5
  },
  "josue": {
    "name": "Josué",
    "id": 6
  },
  "juizes": {
    "name": "Juízes",
    "id": 7
  },
  "rute": {
    "name": "Rute",
    "id": 8
  },
  "1samuel": {
    "name": "1 Samuel",
    "id": 9
  },
  "2samuel": {
    "name": "2 Samuel",
    "id": 10
  },
  "1reis": {
    "name": "1 Reis",
    "id": 11
  },
  "2reis": {
    "name": "2 Reis",
    "id": 12
  },
  "1cronicas": {
    "name": "1 Crônicas",
    "id": 13
  },
  "2cronicas": {
    "name": "2 Crônicas",
    "id": 14
  },
  "esdras": {
    "name": "Esdras",
    "id": 15
  },
  "neemias": {
    "name": "Neemias",
    "id": 16
  },
  "ester": {
    "name": "Ester",
    "id": 17
  },
  "jo": {
    "name": "Jó",
    "id": 18
  },
  "salmos": {
    "name": "Salmos",
    "id": 19
  },
  "proverbios": {
    "name": "Provérbios",
    "id": 20
  },
  "eclesiastes": {
    "name": "Eclesiastes",
    "id": 21
  },
  "cantares": {
    "name": "Cântico dos Cânticos",
    "id": 22
  },
  "cantico": {
    "name": "Cântico dos Cânticos",
    "id": 22
  },
  "canticos": {
    "name": "Cântico dos Cânticos",
    "id": 22
  },
  "isaias": {
    "name": "Isaías",
    "id": 23
  },
  "jeremias": {
    "name": "Jeremias",
    "id": 24
  },
  "lamentacoes": {
    "name": "Lamentações",
    "id": 25
  },
  "lamentacões": {
    "name": "Lamentações",
    "id": 25
  },
  "lamentações": {
    "name": "Lamentações",
    "id": 25
  },
  "ezequiel": {
    "name": "Ezequiel",
    "id": 26
  },
  "daniel": {
    "name": "Daniel",
    "id": 27
  },
  "oseias": {
    "name": "Oséias",
    "id": 28
  },
  "joel": {
    "name": "Joel",
    "id": 29
  },
  "amos": {
    "name": "Amós",
    "id": 30
  },
  "obadias": {
    "name": "Obadias",
    "id": 31
  },
  "jonas": {
    "name": "Jonas",
    "id": 32
  },
  "miqueias": {
    "name": "Miquéias",
    "id": 33
  },
  "naum": {
    "name": "Naum",
    "id": 34
  },
  "habacuque": {
    "name": "Habacuque",
    "id": 35
  },
  "sofonias": {
    "name": "Sofonias",
    "id": 36
  },
  "ageu": {
    "name": "Ageu",
    "id": 37
  },
  "zacarias": {
    "name": "Zacarias",
    "id": 38
  },
  "malaquias": {
    "name": "Malaquias",
    "id": 39
  },
  "mateus": {
    "name": "Mateus",
    "id": 40
  },
  "marcos": {
    "name": "Marcos",
    "id": 41
  },
  "lucas": {
    "name": "Lucas",
    "id": 42
  },
  "joao": {
    "name": "João",
    "id": 43
  },
  "atos": {
    "name": "Atos dos Apóstolos",
    "id": 44
  },
  "atosdosapostolos": {
    "name": "Atos dos Apóstolos",
    "id": 44
  },
  "romanos": {
    "name": "Romanos",
    "id": 45
  },
  "1corintios": {
    "name": "1 Coríntios",
    "id": 46
  },
  "2corintios": {
    "name": "2 Coríntios",
    "id": 47
  },
  "galatas": {
    "name": "Gálatas",
    "id": 48
  },
  "efesios": {
    "name": "Efésios",
    "id": 49
  },
  "filipenses": {
    "name": "Filipenses",
    "id": 50
  },
  "colossenses": {
    "name": "Colossenses",
    "id": 51
  },
  "1tessalonicenses": {
    "name": "1 Tessalonicenses",
    "id": 52
  },
  "2tessalonicenses": {
    "name": "2 Tessalonicenses",
    "id": 53
  },
  "1timoteo": {
    "name": "1 Timóteo",
    "id": 54
  },
  "2timoteo": {
    "name": "2 Timóteo",
    "id": 55
  },
  "tito": {
    "name": "Tito",
    "id": 56
  },
  "filemon": {
    "name": "Filemon",
    "id": 57
  },
  "hebreus": {
    "name": "Hebreus",
    "id": 58
  },
  "tiago": {
    "name": "Tiago",
    "id": 59
  },
  "1pedro": {
    "name": "1 Pedro",
    "id": 60
  },
  "2pedro": {
    "name": "2 Pedro",
    "id": 61
  },
  "1joao": {
    "name": "1 João",
    "id": 62
  },
  "2joao": {
    "name": "2 João",
    "id": 63
  },
  "3joao": {
    "name": "3 João",
    "id": 64
  },
  "judas": {
    "name": "Judas",
    "id": 65
  },
  "apocalipse": {
    "name": "Apocalipse",
    "id": 66
  }
};

const NAMES_BOOKS = ["genesis", "exodo", "levitico", "numeros", "deuteronomio", "josue", "juizes", "rute", "1samuel", "2samuel", "1reis", "2reis", "1cronicas", "2cronicas", "esdras", "neemias", "ester", "jo", "salmos", "proverbios", "eclesiastes", "cantares", "cantico", "canticos", "isaias", "jeremias", "lamentacoes", "lamentacões", "lamentações", "ezequiel", "daniel", "oseias", "joel", "amos", "obadias", "jonas", "miqueias", "naum", "habacuque", "sofonias", "ageu", "zacarias", "malaquias", "mateus", "marcos", "lucas", "joao", "atos", "atosdosapostolos", "romanos", "1corintios", "2corintios", "galatas", "efesios", "filipenses", "colossenses", "1tessalonicenses", "2tessalonicenses", "1timoteo", "2timoteo", "tito", "filemon", "hebreus", "tiago", "1pedro", "2pedro", "1joao", "2joao", "3joao", "judas", "apocalipse"];

const ARRAY_BOOKS = [
  {
    "simpleName": "genesis",
    "name": "Gênesis",
    "id": 1
  }, {
    "simpleName": "exodo",
    "name": "Êxodo",
    "id": 2
  }, {
    "simpleName": "levitico",
    "name": "Levítico",
    "id": 3
  }, {
    "simpleName": "numeros",
    "name": "Números",
    "id": 4
  }, {
    "simpleName": "deuteronomio",
    "name": "Deuteronômio",
    "id": 5
  }, {
    "simpleName": "josue",
    "name": "Josué",
    "id": 6
  }, {
    "simpleName": "juizes",
    "name": "Juízes",
    "id": 7
  }, {
    "simpleName": "rute",
    "name": "Rute",
    "id": 8
  }, {
    "simpleName": "1samuel",
    "name": "1 Samuel",
    "id": 9
  }, {
    "simpleName": "2samuel",
    "name": "2 Samuel",
    "id": 10
  }, {
    "simpleName": "1reis",
    "name": "1 Reis",
    "id": 11
  }, {
    "simpleName": "2reis",
    "name": "2 Reis",
    "id": 12
  }, {
    "simpleName": "1cronicas",
    "name": "1 Crônicas",
    "id": 13
  }, {
    "simpleName": "2cronicas",
    "name": "2 Crônicas",
    "id": 14
  }, {
    "simpleName": "esdras",
    "name": "Esdras",
    "id": 15
  }, {
    "simpleName": "neemias",
    "name": "Neemias",
    "id": 16
  }, {
    "simpleName": "ester",
    "name": "Ester",
    "id": 17
  }, {
    "simpleName": "jo",
    "name": "Jó",
    "id": 18
  }, {
    "simpleName": "salmos",
    "name": "Salmos",
    "id": 19
  }, {
    "simpleName": "proverbios",
    "name": "Provérbios",
    "id": 20
  }, {
    "simpleName": "eclesiastes",
    "name": "Eclesiastes",
    "id": 21
  }, {
    "simpleName": "cantares",
    "name": "Cântico dos Cânticos",
    "id": 22
  }, {
    "simpleName": "isaias",
    "name": "Isaías",
    "id": 23
  }, {
    "simpleName": "jeremias",
    "name": "Jeremias",
    "id": 24
  }, {
    "simpleName": "lamentacoes",
    "name": "Lamentações",
    "id": 25
  }, {
    "simpleName": "ezequiel",
    "name": "Ezequiel",
    "id": 26
  }, {
    "simpleName": "daniel",
    "name": "Daniel",
    "id": 27
  }, {
    "simpleName": "oseias",
    "name": "Oséias",
    "id": 28
  }, {
    "simpleName": "joel",
    "name": "Joel",
    "id": 29
  }, {
    "simpleName": "amos",
    "name": "Amós",
    "id": 30
  }, {
    "simpleName": "obadias",
    "name": "Obadias",
    "id": 31
  }, {
    "simpleName": "jonas",
    "name": "Jonas",
    "id": 32
  }, {
    "simpleName": "miqueias",
    "name": "Miquéias",
    "id": 33
  }, {
    "simpleName": "naum",
    "name": "Naum",
    "id": 34
  }, {
    "simpleName": "habacuque",
    "name": "Habacuque",
    "id": 35
  }, {
    "simpleName": "sofonias",
    "name": "Sofonias",
    "id": 36
  }, {
    "simpleName": "ageu",
    "name": "Ageu",
    "id": 37
  }, {
    "simpleName": "zacarias",
    "name": "Zacarias",
    "id": 38
  }, {
    "simpleName": "malaquias",
    "name": "Malaquias",
    "id": 39
  }, {
    "simpleName": "mateus",
    "name": "Mateus",
    "id": 40
  }, {
    "simpleName": "marcos",
    "name": "Marcos",
    "id": 41
  }, {
    "simpleName": "lucas",
    "name": "Lucas",
    "id": 42
  }, {
    "simpleName": "joao",
    "name": "João",
    "id": 43
  }, {
    "simpleName": "atos",
    "name": "Atos dos Apóstolos",
    "id": 44
  }, {
    "simpleName": "romanos",
    "name": "Romanos",
    "id": 45
  }, {
    "simpleName": "1corintios",
    "name": "1 Coríntios",
    "id": 46
  }, {
    "simpleName": "2corintios",
    "name": "2 Coríntios",
    "id": 47
  }, {
    "simpleName": "galatas",
    "name": "Gálatas",
    "id": 48
  }, {
    "simpleName": "efesios",
    "name": "Efésios",
    "id": 49
  }, {
    "simpleName": "filipenses",
    "name": "Filipenses",
    "id": 50
  }, {
    "simpleName": "colossenses",
    "name": "Colossenses",
    "id": 51
  }, {
    "simpleName": "1tessalonicenses",
    "name": "1 Tessalonicenses",
    "id": 52
  }, {
    "simpleName": "2tessalonicenses",
    "name": "2 Tessalonicenses",
    "id": 53
  }, {
    "simpleName": "1timoteo",
    "name": "1 Timóteo",
    "id": 54
  }, {
    "simpleName": "2timoteo",
    "name": "2 Timóteo",
    "id": 55
  }, {
    "simpleName": "tito",
    "name": "Tito",
    "id": 56
  }, {
    "simpleName": "filemon",
    "name": "Filemon",
    "id": 57
  }, {
    "simpleName": "hebreus",
    "name": "Hebreus",
    "id": 58
  }, {
    "simpleName": "tiago",
    "name": "Tiago",
    "id": 59
  }, {
    "simpleName": "1pedro",
    "name": "1 Pedro",
    "id": 60
  }, {
    "simpleName": "2pedro",
    "name": "2 Pedro",
    "id": 61
  }, {
    "simpleName": "1joao",
    "name": "1 João",
    "id": 62
  }, {
    "simpleName": "2joao",
    "name": "2 João",
    "id": 63
  }, {
    "simpleName": "3joao",
    "name": "3 João",
    "id": 64
  }, {
    "simpleName": "judas",
    "name": "Judas",
    "id": 65
  }, {
    "simpleName": "apocalipse",
    "name": "Apocalipse",
    "id": 66
  }
];

const BOOKS_ID = {
  "gn": {
    "id": 1,
    "name": "genesis"
  },
  "ex": {
    "id": 2,
    "name": "exodo"
  },
  "lv": {
    "id": 3,
    "name": "levitico"
  },
  "nm": {
    "id": 4,
    "name": "numeros"
  },
  "dt": {
    "id": 5,
    "name": "deuteronomio"
  },
  "js": {
    "id": 6,
    "name": "josue"
  },
  "jz": {
    "id": 7,
    "name": "juizes"
  },
  "rt": {
    "id": 8,
    "name": "rute"
  },
  "1sm": {
    "id": 9,
    "name": "1samuel"
  },
  "2sm": {
    "id": 10,
    "name": "2samuel"
  },
  "1rs": {
    "id": 11,
    "name": "1reis"
  },
  "2rs": {
    "id": 12,
    "name": "2reis"
  },
  "1cr": {
    "id": 13,
    "name": "1cronicas"
  },
  "2cr": {
    "id": 14,
    "name": "2cronicas"
  },
  "ed": {
    "id": 15,
    "name": "esdras"
  },
  "ne": {
    "id": 16,
    "name": "neemias"
  },
  "et": {
    "id": 17,
    "name": "ester"
  },
  "jo": {
    "id": 43,
    "name": "joao"
  },
  "sl": {
    "id": 19,
    "name": "salmos"
  },
  "pv": {
    "id": 20,
    "name": "proverbios"
  },
  "ec": {
    "id": 21,
    "name": "eclesiastes"
  },
  "ct": {
    "id": 22,
    "name": "cantares"
  },
  "is": {
    "id": 23,
    "name": "isaias"
  },
  "jr": {
    "id": 24,
    "name": "jeremias"
  },
  "lm": {
    "id": 25,
    "name": "lamentacoes"
  },
  "ez": {
    "id": 26,
    "name": "ezequiel"
  },
  "dn": {
    "id": 27,
    "name": "daniel"
  },
  "os": {
    "id": 28,
    "name": "oseias"
  },
  "jl": {
    "id": 29,
    "name": "joel"
  },
  "am": {
    "id": 30,
    "name": "amos"
  },
  "ob": {
    "id": 31,
    "name": "obadias"
  },
  "jn": {
    "id": 32,
    "name": "jonas"
  },
  "mq": {
    "id": 33,
    "name": "miqueias"
  },
  "na": {
    "id": 34,
    "name": "naum"
  },
  "hc": {
    "id": 35,
    "name": "habacuque"
  },
  "sf": {
    "id": 36,
    "name": "sofonias"
  },
  "ag": {
    "id": 37,
    "name": "ageu"
  },
  "zc": {
    "id": 38,
    "name": "zacarias"
  },
  "ml": {
    "id": 39,
    "name": "malaquias"
  },
  "mt": {
    "id": 40,
    "name": "mateus"
  },
  "mc": {
    "id": 41,
    "name": "marcos"
  },
  "lc": {
    "id": 42,
    "name": "lucas"
  },
  "at": {
    "id": 44,
    "name": "atosdos-apostolos"
  },
  "rm": {
    "id": 45,
    "name": "romanos"
  },
  "1co": {
    "id": 46,
    "name": "1corintios"
  },
  "2co": {
    "id": 47,
    "name": "2corintios"
  },
  "gl": {
    "id": 48,
    "name": "galatas"
  },
  "ef": {
    "id": 49,
    "name": "efesios"
  },
  "fp": {
    "id": 50,
    "name": "filipenses"
  },
  "cl": {
    "id": 51,
    "name": "colossenses"
  },
  "1ts": {
    "id": 52,
    "name": "1tessalonicenses"
  },
  "2ts": {
    "id": 53,
    "name": "2tessalonicenses"
  },
  "1tm": {
    "id": 54,
    "name": "1timoteo"
  },
  "2tm": {
    "id": 55,
    "name": "2timoteo"
  },
  "tt": {
    "id": 56,
    "name": "tito"
  },
  "fm": {
    "id": 57,
    "name": "filemon"
  },
  "hb": {
    "id": 58,
    "name": "hebreus"
  },
  "tg": {
    "id": 59,
    "name": "tiago"
  },
  "1pe": {
    "id": 60,
    "name": "1pedro"
  },
  "2pe": {
    "id": 61,
    "name": "2pedro"
  },
  "1jo": {
    "id": 62,
    "name": "1joao"
  },
  "2jo": {
    "id": 63,
    "name": "2joao"
  },
  "3jo": {
    "id": 64,
    "name": "3joao"
  },
  "jd": {
    "id": 65,
    "name": "judas"
  },
  "ap": {
    "id": 66,
    "name": "apocalipse"
  }
};

const TESTSET_WORDS = ['amor', 'deus', 'jesus', 'bíblia', 'oração',
  'educação', 'crime',
  'esporte', 'amizade', 'filho', 'casamento', 'governo', 'tatuagem', 'alimentos'];


const events = {
  page_loaded: "PAGE_LOADED",
  vis_loaded: "VIS_LOADED",
  evaluation_started: "EVALUATION_STARTED",
  next_step_evaluation: "NEXT_STEP_EVALUATION",
  evaluation_finished: "EVALUATION_FINISHED",
  evaluation_put_user_info: 'EVALUATION_PUT_USER_INFO',
  evaluation_send_comment: 'EVALUATION_SEND_COMMENT',
  evaluation_send_answer: 'EVALUATION_SEND_ANSWER',
  book_link_clicked: "BOOK_LINK_CLICKED",
  text_bible_selected: "cc",
  text_bible_viewd: "TEXT_BIBLE_VIEWD",
  next_text_clicked: "NEXT_TEXT_CLICKED",
  back_text_clicked: "BACK_TEXT_CLICKED",
  term_searched: "TERM_SEARCHED",
  tab_viewed: "TAB_VIEWED",
  tab_viewed_direct: "TAB_VIEWED_DIRECT",
  tab_viewed_concordance: "TAB_VIEWED_CONCORDANCE",
  tab_viewed_word2vec: "TAB_VIEWED_WORD2VEC",
  tab_viewed_explicability: "TAB_VIEWED_EXPLICABILITY",
  evaluation_tab_viewed_direct: "EVALUATION_TAB_VIEWED_DIRECT",
  evaluation_tab_viewed_concordance: "EVALUATION_TAB_VIEWED_CONCORDANCE",
  evaluation_tab_viewed_word2vec: "EVALUATION_TAB_VIEWED_WORD2VEC",
  evaluation_tab_viewed_explicability: "EVALUATION_TAB_VIEWED_EXPLICABILITY",
  explanation_viewed: "EXPLANATION_VIEWED",
  verse_id_clicked: "VERSE_ID_CLICKED",
  verse_card_clicked: "VERSE_CARD_CLICKED",
  similar_term_clicked: "SIMILAR_TERM_CLICKED",
  sort_results_changed: "SORT_RESULTS_CHANGED",
  sort_results_changed_to_relevance: "SORT_RESULTS_CHANGED_TO_RELEVANCE",
  sort_results_changed_to_occurrence: "SORT_RESULTS_CHANGED_TO_OCCURRENCE",
  item_pagination_clicked: "ITEM_PAGINATION_CLICKED",
  next_page_clicked: "NEXT_PAGE_CLICKED",
  back_page_clicked: "BACK_PAGE_CLICKED",
  input_search_clicked: 'INPUT_SEARCH_CLICKED',
  verse_relevant_hover: 'VERSE_RELEVANT_HOVER'
};

const eventsDescriptions = {
  page_loaded: "acessou página",
  vis_loaded: "acessou visualização",
  evaluation_started: "iniciou a avaliação",
  next_step_evaluation: "passou para a próxima etapa da avaliação",
  evaluation_finished: "concluiu a avaliação",
  evaluation_put_user_info: "preencheu seus dados de idade e gênero",
  evaluation_send_comment: "enviou um comentário na avaliação",
  evaluation_send_answer: "responde uma pergunta da avaliação",
  book_link_clicked: "clicou no link para ler o livro",
  text_bible_selected: "selecionou para ler, o texto",
  next_text_clicked: "quer ler o próximo capítulo",
  back_text_clicked: "quer ler o capítulo anterior",
  term_searched: "pesquisou o termo",
  tab_viewed: "viu a aba",
  tab_viewed_direct: "abriu a aba de resultados diretos",
  tab_viewed_concordance: "abriu a aba de concordancia biblica",
  tab_viewed_word2vec: "abriu a aba de termos relacionados",
  tab_viewed_explicability: "abriu a aba de explicação",
  evaluation_tab_viewed_explicability: "abriu a aba de explicação do modelo na avaliação",
  evaluation_tab_viewed_word2vec: "abriu a aba de word2vec do modelo na avaliação",
  evaluation_tab_viewed_concordance: "abriu a aba de concordancia bíblica do modelo na avaliação",
  evaluation_tab_viewed_direct: "abriu a aba de resultados diretos do modelo na avaliação",
  explanation_viewed: "viu a explicação do método",
  verse_id_clicked: "clicou para ler o versículo",
  verse_card_clicked: "clicou no card do versículo",
  similar_term_clicked: "clicou no termo relacionado,",
  sort_results_changed: "alterou a forma de ordenação dos resultados para",
  sort_results_changed_to_relevance: "ordenou os resultados por relevância",
  sort_results_changed_to_occurrence: "ordenou os resultados por ocorrência no texto",
  item_pagination_clicked: "clicou em outra página de resultados, a página",
  next_page_clicked: "passou para a próxima página de resultados",
  back_page_clicked: "quer ver a página de resultados anterior",
  input_search_clicked: 'pesquisou um termo a partir',
  verse_relevant_hover: 'considerou relevante o versículo'
};

const questions = [
  {
    id: 1,
    type: 'LIKE',
    text: 'Você gostou dos resultados?'
  },
  {
    id: 2,
    type: 'UNDERSTAND',
    text: 'Você entendeu como sua consulta foi feita?'
  },
  {
    id: 3,
    type: 'TRUST',
    text: 'Você confiaria nesta aplicação para fazer novas consultas?'
  },
  {
    id: 4,
    type: 'COMMENT',
    text: 'Você gostaria de fazer algum comentário?'
  },
];

exports.BOOKS_BIBLE = BOOKS_BIBLE;
exports.NAMES_BOOKS = NAMES_BOOKS;
exports.ARRAY_BOOKS = ARRAY_BOOKS;
exports.TESTSET_WORDS = TESTSET_WORDS;
exports.BOOKS_ID = BOOKS_ID;
exports.BOOKS_NAME_IDS = hashData.books;

exports.events = events;
exports.eventsDescriptions = eventsDescriptions;
exports.questions = questions;