import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import draw from './Draw';
import '../../../styles/styles.css';
import './BarChart.css';

class BarChart extends Component {

  componentDidMount() {
    const { data, method, word, filterVersesBySearch } = this.props;
    data && data.length &&
      draw(data, method, word, filterVersesBySearch);
  }

  render() {
    const { method } = this.props;
    return (
      <div className="bar-chart-component" id={'bar-chart-component-' + method}>
      </div>
    );
  }
}

BarChart.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object,
  data: PropTypes.arrayOf(PropTypes.object),
  method: PropTypes.string,
  word: PropTypes.string,
  filterVersesBySearch: PropTypes.func
};

export default withTranslation()(BarChart);