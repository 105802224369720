import React, { Component } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import PropTypes from 'prop-types';

import EvaluateService from '../../services/EvaluateService';

import './FormEvaluation.css';

class FormEvaluation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gender: '',
      age: '',
      oftenReadBible: ''
    }
  }

  handleChange = (event, field) => {
    this.setState({ [field]: event.target.value }, () => {
      const { age, gender } = this.state;
      const { done } = this.props;

      if (age && gender) {
        sessionStorage.setItem('be_info_user', JSON.stringify({
          age: age,
          gender: gender
        }));
        EvaluateService.saveEvaluation('', 'DATA', '');
        done({
          age: age,
          gender: gender,
        });
      }
    });
  };

  render() {
    const { gender, age } = this.state;

    return (
      <div id="form-evaluation">
        <div className='box-step-evaluation'>
          <h3> Primeiro passo - um pouco sobre você </h3>
          <div id="info-demo">
            <FormControl component="fieldset" className='form-control'>
              <FormLabel component="legend"> Gênero </FormLabel>
              <RadioGroup aria-label="gender" name="gender" value={gender} onChange={e => this.handleChange(e, 'gender')}>
                <FormControlLabel value="f" control={<Radio />} label="Feminino" />
                <FormControlLabel value="m" control={<Radio />} label="Masculino" />
              </RadioGroup>
            </FormControl>

            <FormControl component="fieldset" className='form-control'>
              <FormLabel component="legend"> Idade </FormLabel>
              <RadioGroup aria-label="age" name="age" value={age} onChange={e => this.handleChange(e, 'age')}>
                <FormControlLabel value="0-12" control={<Radio />} label="0 à 18 anos" />
                <FormControlLabel value="18-30" control={<Radio />} label="18 à 30 anos" />
                <FormControlLabel value="31-45" control={<Radio />} label="31 à 45 anos" />
                <FormControlLabel value="45+" control={<Radio />} label="acima de 45 anos" />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </div>
    )
  }
}

FormEvaluation.propTypes = {
  done: PropTypes.func
};

export default FormEvaluation;