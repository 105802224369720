import React, { Component } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import NotesIcon from '@material-ui/icons/Notes';
import ListIcon from '@material-ui/icons/List';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ReorderIcon from '@material-ui/icons/Reorder';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import './SearchToolBar.css';

class SearchToolBar extends Component {
  render() {
    const { tab, hasSearchResults, hasErrorOnDataModel, searchResult, term, t,
      handleChangeTab, handleChangeResource, hideSpecificResults } = this.props;
    return (
      <div id='search-tool-bar'>
        <div className='tool-bar'>
          <Toolbar className='content'>
            {
              (searchResult && searchResult.query === term && hasSearchResults
                && <div className={'link-page link-featured all ' + tab.all} onClick={() => handleChangeResource('all', 'all')}>
                  <div className='icon-tab-item all-tab'> <ReorderIcon /> </div>
                  <span> Todos os resultados </span>
                </div>)
              || ''
            }
            {
              (searchResult && searchResult.query === term && hasSearchResults && !hideSpecificResults
                && <div className={'link-page link-featured direct ' + tab.direct} onClick={() => handleChangeResource('direct', 'direct')}>
                  <div className='icon-tab-item direct-tab'> <ArrowRightAltIcon /> </div>
                  <span> Consulta direta </span>
                </div>)
              || ''
            }
            {
              (searchResult && searchResult.query === term && hasSearchResults && !hideSpecificResults
                && <div className={'link-page link-featured concordance ' + tab.concordance} onClick={() => handleChangeResource('concordance', 'concordance')}>
                  <div className='icon-tab-item concordance-tab'> <NotesIcon /> </div>
                  <span> concordância Bíblica </span>
                </div>)
              || ''
            }
            {
              (searchResult && searchResult.query === term && hasSearchResults && !hideSpecificResults
                &&
                <div className={'link-page link-featured w2v ' + tab.w2v} onClick={() => handleChangeResource('w2v', 'word2vec')}>
                  <div className='icon-tab-item terms-tab'> <ListIcon /> </div>
                  <span> Termos relacionados </span>
                </div>)
              || ''
            }
            {
              (searchResult && searchResult.query === term && hasSearchResults && !hasErrorOnDataModel
                && <div className={'link-page link-featured explicability-search ' + tab.explicability_search} onClick={() => handleChangeTab('explicability_search')}>
                  <div className='icon-tab-item help-tab'> <HelpOutlineIcon /> </div>
                  <span> *{t('SEARCH_PROCESS.ADVANCED_SEARCH')} </span>
                </div>)
              || ''
            }

            {
              (searchResult && searchResult.query === term && hasSearchResults && hasErrorOnDataModel
                && <div className={'link-page link-featured disable-button-explicability explicability-search '}>
                  <div className='icon-tab-item help-tab'> <HelpOutlineIcon /> </div>
                  <span> *{t('SEARCH_PROCESS.ADVANCED_SEARCH')} </span>
                </div>)
              || ''
            }

          </Toolbar>
        </div>
      </div>
    )
  }
};

SearchToolBar.props = {
  tab: PropTypes.object,
  hasSearchResults: PropTypes.bool,
  hasErrorOnDataModel: PropTypes.bool,
  hideSpecificResults: PropTypes.bool,
  searchResult: PropTypes.object,
  term: PropTypes.string,
  t: PropTypes.func.isRequired,
  handleChangeResource: PropTypes.func,
  handleChangeTab: PropTypes.func
};

export default withTranslation()(SearchToolBar);