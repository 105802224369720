import * as d3 from 'd3v3';
import { COLORS } from '../../theme/Colors';

const drawBarCode = (dataArray, word) => {
  const widthChart = window.innerWidth >= 1150 ? 960 : (window.innerWidth - 190);
  const marginLeft = window.innerWidth >= 1150 ? 10 : 0;
  const marginLeftBar = window.innerWidth >= 1150 ? 0 : 120;

  if (dataArray) {
    const elementParent = document.getElementById("area-bar-code-chart-component");
    let strDivs = '';

    dataArray.forEach((d, i) => {
      strDivs += '<div id="bar-code-chart-' + i + '"></div>';
    });
    elementParent.innerHTML = strDivs;
    const totalHeight = 40;
    var margin = { top: 20, left: marginLeft, bottom: 30, right: 40 },
      width = widthChart - margin.left - margin.right,
      height = totalHeight - margin.top - margin.bottom;

    const ticksSize = 20;
    const colorMin = -0.3;
    const colorMax = 0.3;
    const itemMaxSize = 10;

    dataArray.forEach((idata, ikey) => {
      let data = idata.vector;
      if (data) {
        const color = d3.scale.linear().domain([colorMax, colorMin])
          .interpolate(d3.interpolateHcl)
          .range([d3.rgb(COLORS.purple_1), d3.rgb(COLORS.red)]);

        const xScale = d3.scale.linear().range([0, width]);
        const xAxis = d3.svg.axis()
          .scale(xScale)
          .ticks(ticksSize)
          .orient("bottom")
          .innerTickSize(-height)
          .outerTickSize(0)
          .tickPadding(10);

        const yScale = d3.scale.linear().range([height, 0]);
        const yAxis = d3.svg.axis()
          .scale(yScale)
          .ticks(ticksSize)
          .orient("left")
          .innerTickSize(-width)
          .outerTickSize(0)
          .tickPadding(10);

        const itemScale = d3.scale.linear().range([0, itemMaxSize]);

        var svg = d3.select("#bar-code-chart-" + ikey)
          .append("svg")
          .attr("width", width + margin.left + margin.right)
          .attr("height", height + margin.top + margin.bottom)
          .append("g")
          .attr("transform", "translate(" + margin.left + "," + 0 + ")")

        const g = svg.append("g")
          .attr("transform", "translate(" + margin.left + marginLeftBar + "," + margin.top + ")");

        svg.append("text")
          .attr("class", "term-text")
          .attr("x", 0)
          .attr("y", totalHeight - (totalHeight / 100) * 10)
          .text(idata.term)

        d3.select("body").append("div")
          .attr("class", "tooltip")
          .style("opacity", 0);

        // xScale.domain([d3.min(data) - 1, d3.max(data) + 1]);
        xScale.domain([0, 99]);
        yScale.domain([0, 100]);
        itemScale.domain([-0.5, 0.5]);

        g.append("g")
          .attr("class", "x axis")
          .attr("transform", "translate(0," + height + ")")
          .call(xAxis)
          .append("text")
          .attr("class", "label")
          .attr("x", width)
          .attr("y", -6)
          .style("text-anchor", "end");

        g.append("g")
          .attr("class", "y axis")
          .call(yAxis)
          .append("text")
          .attr("class", "label")
          .attr("transform", "rotate(-90)")
          .attr("y", 6)
          .attr("dy", ".71em")
          .style("text-anchor", "end");

        g.selectAll(".bar")
          .data(data)
          .enter().append("rect")
          .attr("x", (d, i) => d + (i * itemMaxSize))
          .attr("width", (d) => itemScale(-d))
          // .attr("width", itemMaxSize / 1.5)
          .attr("height", 20)
          .attr("fill", (d) => color(d))
      }
    })
  }
};

export default drawBarCode;