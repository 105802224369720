import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import draw from './Draw';
import '../../styles/styles.css';
import './ForceGraph.css';

class ForceGraph extends Component {

  componentDidMount() {
    const { data, method, word } = this.props;

    data && data.links && data.links.length &&
      draw(data, method, word);
  }

  render() {
    const { method } = this.props;
    return (
      <div className="force-graph-component" id={'force-graph-component-' + method}>
      </div>
    );
  }
}

ForceGraph.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object,
  data: PropTypes.object.isRequired,
  word: PropTypes.string.isRequired,
  method: PropTypes.string.isRequired
};

export default withTranslation()(ForceGraph);