import * as d3 from 'd3v3';
import { COLORS } from '../../theme/Colors';

const drawScatterPlot = (data, word) => {
  const widthChart = window.innerWidth >= 906 ? 800 : window.innerWidth - 126;
  const dimension = window.innerWidth >= 906 ? 300000 : 500000;

  word = word + " ";
  const ticksSize = 20;
  const rSize = 8;

  const getDistorcion = () =>
    Math.floor(Math.random() * 4);

  const getColor = (d) =>
    d.term === word ?
      COLORS.blue : COLORS.purple_1;

  var margin = { top: 20, right: 100, bottom: 30, left: 40 },
    width = widthChart - margin.left - margin.right,
    height = 500 - margin.top - margin.bottom;

  const xValue = (d) => d.x;
  const xScale = d3.scale.linear().range([0, width]);
  const xMap = (d) => xScale(xValue(d));
  const xMapDistorcion = (d) => (xScale(xValue(d)) + getDistorcion());

  const xAxis = d3.svg.axis()
    .scale(xScale)
    .ticks(ticksSize)
    .orient("bottom")
    .innerTickSize(-height)
    .outerTickSize(0)
    .tickPadding(10);

  const yValue = (d) => d.y;
  const yScale = d3.scale.linear().range([height, 0]);
  const yMap = (d) => yScale(yValue(d));
  const yMapDistorcion = (d) => (yScale(yValue(d)) + getDistorcion());

  const yAxis = d3.svg.axis()
    .scale(yScale)
    .ticks(ticksSize)
    .orient("left")
    .innerTickSize(-width)
    .outerTickSize(0)
    .tickPadding(10);

  var svg = d3.select("#scatterplot-chart-component").append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

  d3.select("body").append("div")
    .attr("class", "tooltip")
    .style("opacity", 0);

  data.forEach(function (d) {
    d.x = +d.x;
    d.y = +d.y;
  });

  xScale.domain([-dimension, dimension]);
  yScale.domain([-dimension, dimension]);

  svg.append("g")
    .attr("class", "x axis")
    .attr("transform", "translate(0," + height + ")")
    .call(xAxis)
    .append("text")
    .attr("class", "label")
    .attr("x", width)
    .attr("y", -6)
    .style("text-anchor", "end");

  svg.append("g")
    .attr("class", "y axis")
    .call(yAxis)
    .append("text")
    .attr("class", "label")
    .attr("transform", "rotate(-90)")
    .attr("y", 6)
    .attr("dy", ".71em")
    .style("text-anchor", "end");


  svg.selectAll(".dot")
    .data(data)
    .enter().append("circle")
    .attr("class", "dot")
    .attr("r", rSize)
    .attr("cx", xMap)
    .attr("cy", yMap)
    .style("fill", (d) => getColor(d))
    .on("mouseover", function (d) {

    })
    .on("mouseout", function (d) {

    });

  svg.selectAll(".dot-stroke")
    .data(data)
    .enter().append("circle")
    .attr("class", "dot-stroke")
    .attr("r", rSize)
    .attr("cx", xMapDistorcion)
    .attr("cy", yMapDistorcion)

  svg.selectAll(".legend")
    .data(data)
    .enter().append("text")
    .attr("class", "txt")
    .attr("r", 3.5)
    .attr("x", (d) => xScale(d.x) + 15)
    .attr("y", yMap)
    // .style("fill", function (d) { return color(cValue(d)); })
    .text((d) => d.term);
};

export default drawScatterPlot;