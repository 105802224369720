import React from 'react';
import '../../../styles/styles.css';
import './AreaWordCloud.css';
import WordCloud from '../../charts/wordCloud/WordCloud';

const convertData = (array, field) => array && array.length && array.map((item) => ({
  text: item[field],
  value: item.count * 6,
}));

const AreaWordCloud = ({ data, title }) => {
  const terms = convertData(data, 'term');
  return (
    <div id='area-word-cloud'>
      <h2> {title} </h2>
      <WordCloud data={terms} />
    </div>
  )
};
export default AreaWordCloud;