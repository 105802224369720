import React from 'react';

import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router'

import '../../styles/styles.css';
import './Home.css';

const Home = () => {
  return (
    <div id='home'>
      <div className='content'>
        <span className='slogan-project'>
          Bora ler e estudar a Bíblia
        </span>
        <p> Em bereve uma nova ferramenta para vocês!</p>
        <img className='banner' src='/images/biblexplorer2.gif' alt='Biblexplorer' />
      </div >
    </div>
  )
};

export default withRouter(withTranslation()(Home));
