import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import '../../styles/styles.css';
import './Table.css';

class Table extends Component {
  render() {
    const { data } = this.props;
    const similarTerms = data && data.similares;
    const hasCosSim = data.cosSims && data.cosSims.length && data.cosSims[0];
    data.cosSims = data.cosSims && data.cosSims.length && data.cosSims.map((value) =>
      parseFloat(value).toFixed(6)
    );
    return (
      <div id='table-component'>
        {(similarTerms && similarTerms.length && hasCosSim) ?
          <div>
            <h4 className="be-subsubtitle-page"> Coeficientes de similaridade </h4>
            <table className="table">
              <tbody>
                <tr className="th">
                  <td className="terms"> Termo similar</td>
                  <td className="cos-sims"> Similaridade com o termo <span className="be-color-pink be-featured">{data.termId}</span> </td>
                </tr>
                {similarTerms.map((term, key) => (
                  <tr key={key}>
                    <td className="terms"> {term} </td>
                    <td className="cos-sims"> {data.cosSims[key]}</td>
                  </tr>
                ))
                }
              </tbody>
            </table>
          </div> : ''}
      </div>
    );
  }
}

Table.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object,
  data: PropTypes.object,
};

export default withTranslation()(Table);