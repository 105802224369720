import i18n from 'i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
import pt from "./locales/pt.json";
import en from "./locales/en.json";

const local_lang_be = localStorage.getItem("language_be") || 'pt';

const options = {
  interpolation: {
    escapeValue: false, // not needed for react!!
  },

  debug: true,

  // lng: 'en',

  resources: {
    pt: {
      common: pt,
    },
    en: {
      common: en,
    },
  },

  fallbackLng: local_lang_be,

  ns: ['common'],

  defaultNS: 'common',

  react: {
    wait: false,
    bindI18n: 'languageChanged loaded',
    bindStore: 'added removed',
    nsMode: 'default'
  },
};

localStorage.setItem('language', options.fallbackLng);
i18n
  .use(local_lang_be)
  .init(options);

export default i18n;