import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router'

import LogService from '../../services/LogService';
import { events, eventsDescriptions } from '../../data/data';

import { getPageNameByUrl } from '../../utils/utils';

import '../../styles/styles.css';
import './ListBooks.css';

class ListBooks extends Component {

  clickedOnBook = (book) => {
    const pathName = this.props.location.pathname;
    LogService.trackEvent(events["book_link_clicked"], {
      book: book,
      namePage: (getPageNameByUrl(pathName) === '') ? 'search_home' : getPageNameByUrl(pathName),
      description: eventsDescriptions["book_link_clicked"],
    });
  };

  createIndexesBooks(t, begin, end) {
    let books = []
    for (let j = begin; j < end; j++) {
      const index = 'BOOKS_BIBLE.' + j;
      books.push(<Link className='link-bible' key={j}><p> {t(index)}</p></Link>)
    }
    return books;
  };

  render() {
    const { t, columns } = this.props;
    const n = columns || 2;
    return (
      <div id='list-books'>
        <div className='content'>
          <div className={`box-index-bible box-index-size-${n}`}>
            <div className={`box-testament box-size-${n}`}>
              <h3 className='title-testament'> {t('OLD_TESTAMENT')} </h3>
              <div className={`books-bible columns-${n}`}>
                {this.createIndexesBooks(t, 0, 39)}
              </div>
            </div>
            <div className={`box-testament box-size-${n}`}>
              <h3 className='title-testament'> {t('NEW_TESTAMENT')} </h3>
              <div className={`books-bible columns-${n}`}>
                {this.createIndexesBooks(t, 39, 66)}
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

ListBooks.propTypes = {
  t: PropTypes.func.isRequired,
  columns: PropTypes.number
};

export default withRouter(withTranslation()(ListBooks));
