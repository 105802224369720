import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { clickTranslate } from '../../actions';
import { clickShowAdvancedQuery } from '../../actions';
import { setQuery } from '../../actions';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { withRouter } from "react-router-dom";

import LogService from '../../services/LogService';
import { events, eventsDescriptions } from '../../data/data';
import { getPageNameByUrl } from '../../utils/utils';

import '../../styles/styles.css';
import './Header.css';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.01),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.05),
    },
    marginRight: theme.spacing.unit * 2,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit * 3,
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#999',
    right: -34,
    top: 5
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: 24,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
});

class Header extends Component {

  constructor(props) {
    super(props)
    this.state = {
      query: ''
    };

    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.evaluateProject = this.evaluateProject.bind(this);
    this.getSearchTerm = this.getSearchTerm.bind(this);
  }

  sendEventSearch = () => {
    const { query } = this.state;
    const pathName = this.props.location.pathname;
    LogService.trackEvent(events["input_search_clicked"], {
      term: query,
      from: "header",
      namePage: getPageNameByUrl(pathName),
      description: `${eventsDescriptions["input_search_clicked"]} do header`,
    });
  }

  getSearchTerm = () => {
    const { query } = this.state;
    const { setQuery } = this.props;
    const lowerQuery = query.toLowerCase();
    const { clickShowAdvancedQuery } = this.props;

    if (query && query.length) {
      setQuery(lowerQuery);
      clickShowAdvancedQuery('results_search');
      this.setState({
        query: ''
      });
      this.sendEventSearch();
      this.props.history.push('/search/' + lowerQuery);
    }
  };

  handleKeyPress(event) {
    const { query } = this.state;

    if (event.key === 'Enter' && query !== '') {
      this.getSearchTerm();
    }
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  changeLanguage = (lan) => {
    localStorage.setItem('language_be', lan);
    i18n.changeLanguage(lan);
  };

  evaluateProject() {
    this.props.history.push('/evaluate/');
  }

  render() {
    const { classes, t } = this.props;
    const { query } = this.state;
    const showSearch = false;

    return (
      <div id='head'>
        <AppBar position="static">
          <div className="content-area">
            <Toolbar>
              <Link to="/" className='logo'>
                <img className='logo-image' src='/images/logo-branco-menor.png' alt='Biblexplorer' />
              </Link>
              <div className='header-menu-navigation'>
                <Link className='link-page' to='/bible'> {t('HEADER.MENU.READ_BIBLE')} </Link>
                <Link className='link-page' to='/about'> {t('HEADER.MENU.ABOUT')} </Link>
              </div>

              {showSearch &&
                <div className='content'>
                  <div className={classes.search + ' search'}>
                    <InputBase
                      placeholder={t('HEADER.SEARCH_PLACEHOLDER')}
                      onChange={this.handleChange('query')}
                      onKeyPress={this.handleKeyPress}
                      value={query}
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      }}
                    />
                    <div className={classes.searchIcon + ' area-serch-icon'} onClick={this.getSearchTerm}>
                      <SearchIcon />
                    </div>
                  </div>
                </div>
              }
            </Toolbar>
          </div>
        </AppBar>
      </div >
    );
  }
}

Header.propTypes = {
  i18n: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ clickTranslate, setQuery, clickShowAdvancedQuery }, dispatch);

const mapStateToProps = store => ({
  translate: store.headerState.translate,
  query: store.headerState.query,
  show: store.searchState.show
});

export default withStyles(styles)(withRouter(withTranslation()(connect(mapStateToProps, mapDispatchToProps)((Header)))));
