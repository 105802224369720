import React, { Component } from 'react';

import './StepInitialForm.css';
import FormEvaluation from '../../../../components/formEvaluation/FormEvaluation';
import SearchInput from '../../../../components/searchInput/SearchInput';
import EvaluateHeader from '../../EvaluateHeader';
import { withRouter } from "react-router-dom";
import { getWordRandom } from '../../../../utils/utils';
import { TESTSET_WORDS } from '../../../../data/data';

import LogService from '../../../../services/LogService';
import EvaluateService from '../../../../services/EvaluateService';
import { events, eventsDescriptions } from '../../../../data/data';

class StepInitialForm extends Component {
  constructor(props) {
    super(props);
    const userData = EvaluateService.getInfoUser();
    this.state = {
      hasUserInfo: Boolean(userData && userData.age),
      user: userData,
    }

    this.finishedForm = this.finishedForm.bind(this);
  };

  finishedForm = (userInfo) => {
    LogService.trackEvent(events["evaluation_put_user_info"], {
      userInfo: JSON.stringify(userInfo),
      description: eventsDescriptions["evaluation_put_user_info"],
    });
    this.setState({ hasUserInfo: true });
  }

  goToEvaluation = () => {
    const nextEvaluation = getWordRandom(TESTSET_WORDS);
    this.props.history.push('/evaluate-search/' + nextEvaluation.nextWord);
  }

  componentDidMount() {
    LogService.trackEvent(events["page_loaded"], {
      namePage: "evaluation_initial_step",
      description: eventsDescriptions["page_loaded"],
    });
  }

  render() {
    const { hasUserInfo } = this.state;

    return (
      <div id="step-initial-form">
        <EvaluateHeader />
        <div className="presentation" id="presentation">
        </div>
        <div className="slider">
          <div className="content" id="intro_01">
            <label> Faça parte </label>

            <h2 className="be-title-page"> Avalie nosso sistema </h2>
            <h3 className="be-subtitle-page"> A sua opinião nos ajuda a melhorar nossos resultados!! </h3>
            <p>
              Este trabalho utiliza uma série de mecanismos para retornar resultados de consultas. Gostaríamos de saber sua opinião sobre esses resultados. Faça consultas e responda as perguntas sobre o quanto você está satisfeito com os resultados.
            </p>
          </div>
        </div>

        {!hasUserInfo && <div className="content">
          <h2 className="be-title-page"> Questionário de avaliação </h2>
          <p> Responda as perguntas abaixo </p>

          <FormEvaluation done={() => this.finishedForm()}></FormEvaluation>
        </div>}

        {hasUserInfo ? <div className="slider-search">
          <div className="content evaluate">
            <h2 className="be-title-page"> Vamos lá ... </h2>
            <p> Pesquise uma palavra </p>

            <SearchInput pathToSearch='evaluate-search'></SearchInput>
          </div>
        </div> : ''}

        {/* {hasUserInfo ?
          <div className='box-step-evaluation-final'>
            <div className="content">
              <h3> Você está pronto? </h3>
              <Button className='button-evaluation' onClick={this.goToEvaluation}>
                Iniciar avaliação
            </Button>
            </div>
          </div> : ''} */}

        <div id='footer'>
          <p> © 2022 Biblexplorer. Todos os direitos reservados. </p>
        </div>
      </div>
    )
  }
}

export default withRouter(StepInitialForm);