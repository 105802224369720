import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router'

import LogService from '../../services/LogService';
import { events, eventsDescriptions } from '../../data/data';

import '../../styles/styles.css';
import './About.css';

class About extends Component {

  componentDidMount() {
    LogService.trackEvent(events["page_loaded"], {
      namePage: "about",
      description: eventsDescriptions["page_loaded"],
    });
  }

  render() {
    const { t } = this.props;

    return (
      <div id='about'>
        <div className="slider">
          <div className="content" id="intro_01">
            <label> {t('ABOUT.THUMB')} </label>

            <h2 className="be-title-page"> {t('ABOUT.TITLE_1')} </h2>
            <h3 className="be-subtitle-page"> {t('ABOUT.SUBTITLE')} </h3>

            <h4> {t('ABOUT.ITEM_LIST_1')} </h4>
            <p>
              {t('ABOUT.TEXT_1')}
            </p>
            <h4> {t('ABOUT.ITEM_LIST_2')} </h4>
            <p>
              {t('ABOUT.TEXT_2')}
            </p>
          </div>
        </div>
        <div className="content last">
          <h2 className="be-title-page"> {t('ABOUT.TITLE_2')} </h2>

          <p> {t('ABOUT.TEXT_LICENSE')} </p>

          <ul>
            <li>{t('ABOUT.TITLE_VERSION')}: Bíblia Livre</li>
            <li>{t('ABOUT.SOURCE')}: <a href="http://sites.google.com/site/biblialivre/" rel="noopener noreferrer" target="_blank">http://sites.google.com/site/biblialivre/</a></li>
            <li>{t('ABOUT.LICENSE')}: Creative Commons Atribuição 3.0 Brasil</li>
          </ul>

          <p> {t('ABOUT.RESUME_LINCENSE')}
            <a target="_blank" rel="noopener noreferrer" href="http://creativecommons.org/licenses/by/3.0/br/">http://creativecommons.org/licenses/by/3.0/br/</a> e a versão oficial se encontra em <a href="http://creativecommons.org/licenses/by/3.0/br/legalcode">http://creativecommons.org/licenses/by/3.0/br/legalcode</a>.
          </p>
        </div>
      </div>
    );
  }
}

About.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object
};

export default withRouter(withTranslation()(About));