import React from 'react';

import '../../../styles/styles.css';
import './AreaChartsLogs.css';
import PieChart from '../../charts/pie/PieChart';

const convertData = (array, field) => array && array.length && array.map((item) => ({
  label: item[field],
  value: item.count,
}));

const AreaChartsLogs = ({ data }) => {
  const pages = convertData(data.pageViews, 'page') || [];

  return (
    <div id='area-charts-logs'>
      <h2> Visualização das páginas </h2>
      <div className='area-box-charts'>
        <PieChart results={pages} id='ages' />
      </div>
    </div>
  )
};
export default AreaChartsLogs;