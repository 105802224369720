import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router'

import { Link } from 'react-router-dom';
import LogService from '../../services/LogService';

import '../../styles/styles.css';
import './LogsEvaluation.css';

import AreaCards from '../../components/dashboard/areaCards/AreaCards';
import AreaCharts from '../../components/dashboard/areaCharts/AreaCharts';
import Comments from '../../components/dashboard/comments/Comments';
import AreaWordCloud from '../../components/dashboard/areaWordCloud/AreaWordCloud';
import AuthInput from '../../components/authInput/AuthInput';

class LogsEvaluation extends Component {
  constructor(props) {
    super(props);
    const authBiblexplorer = sessionStorage.getItem('authBiblexplorerReports');

    this.state = {
      resume: [],
      logsGrouped: [],
      hasAuthorization: Boolean(authBiblexplorer),
    }

    this.getField = this.getField.bind();
    this.sendCode = this.sendCode.bind(this);
  }

  getField(event) {
    const str = event && event.eventType && event.eventType.toLowerCase();

    if (!str) {
      return ''
    }
    if (str.includes('page_')) {
      return event && (event.namePage || event.page)
    }
    if (str.includes('vis_')) {
      return event && event.vis
    }
    if (str.includes('tab_')) {
      return event && event.tab
    }
    if (str.includes('book_')) {
      return event && event.book
    }
    if (str.includes('verse_')) {
      return event && (event.verseId || event.verse)
    }
    if (str.includes('input_')) {
      return event && event.from
    }
    if (str.includes('pagination_')) {
      return event && event.page
    }
    if (str.includes('sort_')) {
      return event && ('ordenar por' + event.sortBy)
    }
    if (str.includes('term_')) {
      return event && event.term
    }
    if (str.includes('text_')) {
      return event && event.text && (event.text.book + ' ' + event.text.chapter)
    }

    return '';
  }

  sendCode = async (code) => {
    if (code !== '') {
      const result = await LogService.sendAuthenticationViewReports({ code });
      const success = result && result.success;
      if (success) {
        sessionStorage.setItem('authBiblexplorerReports', true);
        this.setState({ hasAuthorization: true })
      }
    }
  }

  groupBy = (xs, key) => {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  componentDidMount = async () => {
    const resume = await LogService.getLogsEvaluationResume();
    this.setState({ resume: resume });
  }

  render() {
    const { resume, hasAuthorization } = this.state;

    return (
      <div id='logs-evaluation'>
        <div className='logo-logs'>
          <img className='logo' src='/images/logo-roxo.png' alt='Biblexplorer' />
          {hasAuthorization ?
            <div className='header-logs-biblexplorer'>
              <Link to="/logs-biblexplorer-2020/logs"> <div className='item-header-logs'>
                Relatório de uso
              </div> </Link>
              <Link to="/logs-biblexplorer-2020/evaluation"> <div className='item-header-logs'>
                Relatório de avaliações
              </div></Link>
              <Link to="/logs-biblexplorer-2020/explicability"> <div className='item-header-logs'>
                Análises
              </div></Link>
            </div>
            : ''}
        </div>

        {hasAuthorization ?
          <div className='box-logs-dashboard'>
            <AreaCards data={resume} />
            <AreaCharts data={resume} />

            <AreaWordCloud data={resume.terms} title={'O que as pessoas estão pesquisando durante a avaliação '} />

            <Comments comments={resume.comments} />
          </div>
          : <AuthInput sendCode={this.sendCode}></AuthInput>}
      </div>
    );
  }
}

LogsEvaluation.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object
};

export default withRouter(withTranslation()(LogsEvaluation));