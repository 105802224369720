import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { clickTranslate } from '../../actions';
import { clickShowAdvancedQuery } from '../../actions';
import { setQuery } from '../../actions';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { withRouter } from "react-router-dom";
import '../../styles/styles.css';
import './EvaluateHeader.css';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.01),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.05),
    },
    marginRight: theme.spacing.unit * 2,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit * 3,
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#999',
    right: -34,
    top: 5
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: 24,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
});

class EvaluateHeader extends Component {

  constructor(props) {
    super(props)
    this.state = {
      query: ''
    };

    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  handleKeyPress(event) {
    const { query } = this.state;
    const { setQuery } = this.props;
    if (event.key === 'Enter' && query !== '') {
      const lowerQuery = query.toLowerCase();
      setQuery(lowerQuery);
      const { clickShowAdvancedQuery } = this.props;
      clickShowAdvancedQuery('results_search');
      this.setState({
        query: ''
      });
      this.props.history.push('/search/' + lowerQuery);
    }
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  changeLanguage = (lan) => {
    localStorage.setItem('language_be', lan);
    i18n.changeLanguage(lan);
  };

  render() {
    return (
      <div id='evaluate-header'>
        <Link className='link-page' to='/'>
          {/* <ArrowBackIosIcon className='icon-button' /> */}
          Acessar a ferramenta
        </Link>
        <AppBar position="static">
          <Toolbar>

            <Link to="/">
              <img className='logo' src='/images/logo-azul.png' alt='Biblexplorer' />
            </Link>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

EvaluateHeader.propTypes = {
  i18n: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ clickTranslate, setQuery, clickShowAdvancedQuery }, dispatch);

const mapStateToProps = store => ({
  translate: store.headerState.translate,
  query: store.headerState.query,
  show: store.searchState.show
});

export default withStyles(styles)(withRouter(withTranslation()(connect(mapStateToProps, mapDispatchToProps)((EvaluateHeader)))));
