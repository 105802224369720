import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import SearchToolBar from '../../../../components/searchToolbar/SearchToolBar';
import { withTranslation } from 'react-i18next';
import '../../../../styles/styles.css';
import '../../../search/Search.css';
import './StepSearchEvaluation.css';
import queryString from 'query-string';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { API_URL } from '../../../../config';
import { saveSearchData, saveModelData, clickShowAdvancedQuery } from '../../../../actions';

import Results from '../../../search/tabs/results/Results';
import SearchExplicability from '../../../search/tabs/searchExplicability/SearchExplicability';

import { convertToSlug, copyObject, getWordRandom } from '../../../../utils/utils';
import { BOOKS_BIBLE, TESTSET_WORDS } from '../../../../data/data';
import EvaluateHeader from '../../EvaluateHeader';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';

import LogService from '../../../../services/LogService';
import EvaluateService from '../../../../services/EvaluateService';
import { events, questions, eventsDescriptions } from '../../../../data/data';

const clearTabs = {
  all: '',
  direct: '',
  concordance: '',
  w2v: '',
  explicability_search: '',
  explicability_model: ''
};

const styles = {
  card: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
};

class StepSearchEvaluation extends Component {
  constructor(props) {
    super(props);
    const strQuery = this.props.location.search;
    const paramsQuery = Boolean(strQuery) ? queryString.parse(strQuery) : '';
    this.state = {
      advancedQuery: paramsQuery,
      verses: [],
      infoTerm: {},
      loading: false,
      selected: {},
      query: null,
      sortOption: {
        'relevance': 'selected',
        'occurrence': ''
      },
      resourceType: {
        type: 'all',
        resource: 'all'
      },
      tab: {
        all: 'selected',
        direct: '',
        concordance: '',
        w2v: '',
        explicability_search: '',
        explicability_model: ''
      },
      likedAnswer: '',
      understandExplanation: '',
      trustInMethod: '',
      comment: '',
      question: 0,
      wordsToEvaluate: []
    };

    this.sortVersesByType = this.sortVersesByType.bind(this);
    this.showAdvanced = this.showAdvanced.bind(this);
    this.handleChangeResource = this.handleChangeResource.bind(this);
    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeComment = this.handleChangeComment.bind(this);
    this.sendComment = this.sendComment.bind(this);
  }

  starNewEvaluation = () => {
    this.setState({ question: 0 }, () => {
      this.props.history.push({
        pathname: '/evaluate',
      });
    });
  };

  buildSetTest = () => {
    let resturnEvaluation;
    const stateProps = this.props.history.location.state;
    let queryScreen = this.props.match.params.term;
    if (!queryScreen) {
      resturnEvaluation = getWordRandom(copyObject(TESTSET_WORDS));
      queryScreen = resturnEvaluation.nextWord;
    }

    const reponseLocalEvaluation = getWordRandom(copyObject(TESTSET_WORDS), queryScreen);
    const hasWordsEvaluated = (stateProps && stateProps.wordsToEvaluate) || (resturnEvaluation && resturnEvaluation.wordsToEvaluate) || (reponseLocalEvaluation.wordsToEvaluate);
    this.setState({ wordsToEvaluate: hasWordsEvaluated, query: 0 })
    return queryScreen;
  }

  next = () => {
    this.setState((prevState) => ({ question: prevState.question + 1 }));
  };

  handleChange = (event, field) => {
    const query = this.props.match.params.term;
    const v = event && event.target && event.target.value;

    this.setState((prevState) => ({ [field]: event.target, question: prevState.question + 1 }), () => {
      const { question } = this.state;
      const qn = questions[question - 1] && questions[question - 1].type;

      EvaluateService.saveEvaluation(query, qn, v);
      LogService.trackEvent(events['evaluation_send_answer'], {
        term: query,
        question: qn,
        answer: v,
        namePage: "evaluation_search_step",
        description: eventsDescriptions['evaluation_send_answer'],
      });
    });
  };

  handleChangeComment = (event) => {
    this.setState({ comment: event.target && event.target.value });
  };

  sendComment = (value, field) => {
    const query = this.props.match.params.term;

    this.setState((prevState) => ({ [field]: value, question: prevState.question + 1 }), () => {
      const { question } = this.state;
      const qn = questions[question - 1] && questions[question - 1].type;
      EvaluateService.saveEvaluation(query, qn, value);
    });

    LogService.trackEvent(events['evaluation_send_comment'], {
      term: query,
      namePage: "evaluation_search_step",
      description: eventsDescriptions['evaluation_send_comment'],
    });
  };

  getResults = async (query) => {
    await fetch(API_URL + '/info/' + query)
      .then(response => response.json())
      .then(data => {
        this.setState({
          infoTerm: data
        });
      });
  }

  getVerses = async (query) => {
    this.setState({
      verses: []
    });

    await fetch(API_URL + '/search/' + query)
      .then(response => response.json())
      .then(data => {
        this.setState({
          verses: data,
          loading: false
        });
      });
  }

  getDataModel = (query) => {
    const { saveModelData } = this.props;
    fetch(API_URL + '/model/' + query)
      .then(response => response.json())
      .then(data => {
        saveModelData(data);
      });
  }

  categorizeResults = (verses, query) => {
    let result = {
      direct: verses && verses.length ? verses.filter((verse) =>
        verse && verse.search === query && !verse.type
      ) : [],
      concordance: verses && verses.length ? verses.filter((verse) =>
        verse && verse.type && verse.type.includes("concordance")
      ) : [],
      word2vec: verses && verses.length ? verses.filter((verse) =>
        verse && verse.search !== query && !verse.type
      ) : [],
      all: verses
    }

    return result;
  }

  replacer = (match, p1, string) => {
    return p1;
  };

  sortByRelevance = (a, b) => {
    if (a && a.hasOwnProperty('kr') && b && b.hasOwnProperty('kr')) {
      return a.kr - b.kr
    }
  };

  sortByOccurrence = (a, b) => {
    if (a && a.hasOwnProperty('obj') && a.obj.hasOwnProperty('b') &&
      b && b.hasOwnProperty('obj') && b.obj.hasOwnProperty('b') && a.obj.b !== b.obj.b) {
      return a.obj.b - b.obj.b;
    }
    if (a && a.hasOwnProperty('obj') && a.obj.hasOwnProperty('c') &&
      b && b.hasOwnProperty('obj') && b.obj.hasOwnProperty('c') && a.obj.c !== b.obj.c) {
      return a.obj.c - b.obj.c;
    }
    if (a && a.hasOwnProperty('obj') && a.obj.hasOwnProperty('v') &&
      b && b.hasOwnProperty('obj') && b.obj.hasOwnProperty('v') && a.obj.v !== b.obj.v) {
      return a.obj.v - b.obj.v;
    }
  }

  changeStyle = (type) => {
    switch (type) {
      case "relevance":
        this.setState({
          sortOption: {
            'relevance': 'selected',
            'occurrence': ''
          }
        });
        break;

      case "occurrence":
        this.setState({
          sortOption: {
            'relevance': '',
            'occurrence': 'selected'
          }
        });
        break;

      default:
        this.setState({
          sortOption: {
            'relevance': 'selected',
            'occurrence': ''
          }
        });
        break;
    }
  };

  sortByFunc = (fn) => {
    const { searchResult } = this.props;
    if (searchResult && searchResult.verses) {

      const sortedVerses = searchResult.verses.sort(fn);
      searchResult.verses = sortedVerses;
    }
  };

  sortVersesByType = (type) => {
    switch (type) {
      case "relevance":
        this.changeStyle("relevance");
        this.sortByFunc(this.sortByRelevance)
        break;

      case "occurrence":
        this.changeStyle("occurrence");
        this.sortByFunc(this.sortByOccurrence)
        break;

      default:
        this.changeStyle("relevance");
        this.sortByFunc(this.sortByRelevance)
        break;
    }
  };

  convertVerseIdToObject = (verseId) => {
    let stringVerse = convertToSlug(verseId);
    stringVerse = stringVerse.replace(/(^\d)-/, this.replacer);

    const partsVerse = stringVerse.split('-');
    const bookString = partsVerse[0] || 1;
    const book = BOOKS_BIBLE[bookString] && BOOKS_BIBLE[bookString].id
      && BOOKS_BIBLE[bookString].id - 1;
    const cap = partsVerse[1] || 1;
    const ver = partsVerse[2] || 1;

    return {
      b: parseInt(book),
      c: parseInt(cap),
      v: parseInt(ver)
    }
  }

  insertIndexVerses = (verses) => {
    verses && verses.length && verses.map((verse, key) => {
      if (verse && verse.id) {
        verse.kr = key;
        verse.obj = this.convertVerseIdToObject(verse.id);
        return verse;
      }
      return verse;
    });
    return verses;
  };

  getClearTabs() {
    return JSON.parse(JSON.stringify(clearTabs));
  }

  handleChangeResource = (type, resource) => {
    const query = this.props.match.params.term;
    let tabs = this.getClearTabs();
    tabs[type] = 'selected';

    const { clickShowAdvancedQuery, show } = this.props;
    if (show !== 'results_search') {
      clickShowAdvancedQuery('results_search');
    }

    this.setState({
      resourceType: {
        type: type,
        resource: resource
      },
      tab: tabs
    });

    LogService.trackEvent(events[`evaluation_tab_viewed_${type}`], {
      tab: resource,
      term: query,
      namePage: "evaluation_search_step",
      description: eventsDescriptions[`evaluation_tab_viewed_${type}`],
    });
  }

  understandQuery = async (query) => {
    const partsQuery = query.split(" ");
    partsQuery.filter((p) => p.length > 1)

    if (partsQuery.length === 1) {
      return query;
    } else {
      let result = '';
      const body = {
        words: partsQuery
      };

      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      };

      await fetch(API_URL + '/indexes/', options)
        .then(response => response.json())
        .then(data => {
          result = data;
        });
      return result;
    }
  };

  updateQuery = async (query, lang) => {
    const { saveSearchData } = this.props;
    saveSearchData(null);
    if (!query) {
      query = (this.props.match.params.term);
    }
    if (query) {
      this.setState({
        loading: true
      });

      const newQuery = await this.understandQuery(query);
      const nQuery = (typeof newQuery === 'object' && newQuery.length && newQuery[0]) ? (newQuery[0] && newQuery[0].name) : query;

      this.getDataModel(nQuery);
      await this.getResults(nQuery);
      await this.getVerses(nQuery);

      let versesState = this.state.verses;
      versesState = this.insertIndexVerses(versesState);
      const objResult = {
        query: query,
        verses: versesState,
        info: this.state.infoTerm
      };
      saveSearchData(objResult);
      this.handleChangeResource('all', 'all');
    }
  }

  componentWillMount() {
    const queryEvaluation = this.buildSetTest();
    const { searchResult, translate } = this.props;
    const query = this.props.match.params.term || queryEvaluation;
    const hasResultsQuery = searchResult && searchResult.query ? true : false;

    if (!hasResultsQuery || searchResult.query !== query) {
      this.updateQuery(query, translate);
    }
  }

  componentDidMount() {
    LogService.trackEvent(events["page_loaded"], {
      namePage: "evaluation_search_step",
      description: eventsDescriptions["page_loaded"],
    });
  }

  componentDidUpdate(prevProps) {
    const { searchResult, translate, show } = this.props;
    const query = this.props.match.params.term;
    const prevQuery = searchResult && searchResult.query;
    const { resourceType, tab } = this.state;

    if (prevProps.translate !== translate) {
      this.updateQuery(query, translate);
    }

    if (query && prevQuery && prevQuery !== query) {
      this.buildSetTest();
      this.setState({
        loading: true
      });
      this.updateQuery(query, translate);
    }

    if (show === 'results_search' && resourceType.type === 'all' && tab.all !== 'selected') {
      this.handleChangeResource('all', 'all');
    }
  }

  getOptions() {
    const strQuery = this.props.location.search;
    const paramsQuery = Boolean(strQuery) ? queryString.parse(strQuery) : '';
    this.setState({
      advancedQuery: paramsQuery
    });
  }

  showAdvanced() {
    this.getOptions()
  }

  handleChangeTab(tab) {
    const { clickShowAdvancedQuery } = this.props;
    const query = this.props.match.params.term;
    clickShowAdvancedQuery(tab);

    let tabs = this.getClearTabs();
    tabs[tab] = 'selected';
    this.setState({
      tab: tabs
    });

    LogService.trackEvent(events[`evaluation_tab_viewed_explicability`], {
      tab: tab,
      info: 'view_explicability',
      term: query | '',
      namePage: "evaluation_search_step",
      description: eventsDescriptions[`evaluation_tab_viewed_explicability`],
    });
  }

  render() {
    const { t, show, searchResult, dataModel } = this.props;
    const { resourceType, tab,
      likedAnswer, trustInMethod, understandExplanation, question, wordsToEvaluate, comment } = this.state;
    let { term } = this.props.match.params;
    let urlGraph = API_URL + '/search/' + term;
    const hasErrorOnDataModel = (dataModel && dataModel.tsne && dataModel.tsne[0] && dataModel.tsne[0].error === true) || (dataModel && !dataModel.tsne) || !dataModel;
    const hasSearchResults = (searchResult && searchResult.verses && searchResult.verses.length);

    const classAreaEvaluate = ['results', 'explanation', 'trust', 'comment', 'next', 'done'];

    return (
      <div id='search' className='evaluate' >
        <EvaluateHeader />
        {((searchResult && hasSearchResults) &&
          <SearchToolBar
            tab={tab}
            hasSearchResults={hasSearchResults}
            hasErrorOnDataModel={hasErrorOnDataModel}
            searchResult={searchResult}
            term={term}
            handleChangeResource={this.handleChangeResource}
            handleChangeTab={this.handleChangeTab}
            hideSpecificResults={true}
          ></SearchToolBar>) || ''
        }

        {((show === 'results_search'
          && (!searchResult || !searchResult.verses)
          && <div className="loading-be">
            <img alt="imagem-ilustrativa" src="/images/loading_be.gif" />
            <p> Pesquisando a palavra <span className="be-color-blue be-featured">{term}</span> na Bíblia...</p>
            <img alt="imagem-ilustrativa" src="/images/biblia_loading.gif" />
          </div>) || '')
        }

        {
          ((show === 'explicability_search'
            && <SearchExplicability url={urlGraph} word={term}></SearchExplicability>)
            || '')
        }

        {
          ((show === 'explicability_model' && !dataModel
            && <div className="empty-state">
              <img alt="imagem-ilustrativa" src="/images/loading_be.gif" />
              <p>
                Carregando dados...
              </p>
            </div>) || '')
        }

        {
          ((show === 'explicability_model' && dataModel && hasErrorOnDataModel
            && <div className="empty-state">
              <img alt="imagem-ilustrativa" src="/images/error.gif" />
              <p>
                Ops. Um erro ocorreu!
              </p>
            </div>) || '')
        }

        {
          (((show === 'results_search' && hasSearchResults)
            && (<div className='content results'>

              <h2 className="be-title-page">
                Resultados do termo <span> {term}</span> {t('SEARCH_RESULTS.TITLE_PART_2')}
              </h2>
              <p> Avalie os resultados abaixo: </p>
              <Results
                verses={searchResult && searchResult.verses}
                infoTerm={searchResult && searchResult.info}
                resourceType={resourceType}
                hideCards={true}
              ></Results>
            </div>)) || '')
        }
        {
          (((show === 'results_search' && searchResult && !hasSearchResults)
            && <div className="empty-state">
              <img alt="imagem-ilustrativa" src="/images/lupa.png" />
              <p>
                Nenhum resultado encontrado para a consulta "<span className="be-color-blue be-featured">{term}</span>".
              </p>
            </div>) || '')
        }

        {(((searchResult && searchResult.verses)) &&
          <div id='area-evaluate-questions' className={classAreaEvaluate[question]} >
            <div className='content'>
              {question < 4 ?
                <div>
                  <p> Analise os resultados e responda: </p>
                  <h4 className="be-title-page"> {questions[question] && questions[question].text} </h4>
                </div>
                : ''}
              {question === 5 ?
                <h4 className="be-title-page final-title"> Obrigada por participar! </h4> : ''
              }

              {question === 0 ?
                <FormControl component="fieldset" className='form-evaluate'>
                  <RadioGroup aria-label="likedAnswer" className='field' name="likedAnswer" value={likedAnswer} onChange={e => this.handleChange(e, 'likedAnswer')}>
                    <FormControlLabel className='item' value={'1'} control={<Radio />} label="Sim" />
                    <FormControlLabel className='item' value={'2'} control={<Radio />} label="Um pouco" />
                    <FormControlLabel className='item' value={'3'} control={<Radio />} label="Não" />
                  </RadioGroup>
                </FormControl> : ''}

              {question === 1 ?
                <FormControl component="fieldset" className='form-evaluate'>
                  <RadioGroup aria-label="understandExplanation" className='field' name="understandExplanation" value={understandExplanation} onChange={e => this.handleChange(e, 'understandExplanation')}>
                    <FormControlLabel className='item' value={'1'} control={<Radio />} label="Sim, entendi" />
                    <FormControlLabel className='item' value={'2'} control={<Radio />} label="Entendi alguma coisa" />
                    <FormControlLabel className='item' value={'3'} control={<Radio />} label="Não entendi muito bem" />
                    <FormControlLabel className='item' value={'4'} control={<Radio />} label="Não faço ideia" />
                  </RadioGroup>
                </FormControl> : ''}

              {question === 2 ?
                <FormControl component="fieldset" className='form-evaluate'>
                  <RadioGroup aria-label="trustInMethod" className='field' name="trustInMethod" value={trustInMethod} onChange={e => this.handleChange(e, 'trustInMethod')}>
                    <FormControlLabel className='item' value={'1'} control={<Radio />} label="Sim" />
                    <FormControlLabel className='item' value={'2'} control={<Radio />} label="Talvez" />
                    <FormControlLabel className='item' value={'3'} control={<Radio />} label="Não" />
                  </RadioGroup>
                </FormControl> : ''}

              {question === 3 ?
                <div className='comment-evaluate'>
                  <textarea className='comment' value={comment} aria-label="minimum height" rowsMin={3} onChange={e => this.handleChangeComment(e)} placeholder="Comente o que quiser :)" />
                  <div className='area-button-comment'>
                    <Button className='button-evaluation' onClick={e => this.sendComment(comment, 'comment')}>
                      Enviar
                    </Button>
                  </div>
                </div> : ''}

              {question === 4 ?
                <div className='finished-evaluate'>
                  <Button className='button-evaluation' onClick={() => this.next()}>
                    Terminar avaliação
                  </Button>
                  {(wordsToEvaluate && wordsToEvaluate.length) ?
                    <Button className='button-evaluation' onClick={() => this.starNewEvaluation()}>
                      Avaliar outra consulta
                    </Button>
                    : ''}
                </div> : ''}

            </div>
            <div id='footer'>
              <p> © 2022 Biblexplorer. Todos os direitos reservados. </p>
            </div>
          </div>)
          || ''}
      </div >
    );
  }
}

StepSearchEvaluation.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ clickShowAdvancedQuery, saveSearchData, saveModelData }, dispatch);

const mapStateToProps = store => ({
  show: store.searchState.show,
  searchResult: store.searchState.searchResult,
  dataModel: store.searchState.dataModel
});

export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(StepSearchEvaluation))));
