import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";

import { withTranslation } from 'react-i18next';
import SocialNetworks from '../socialNetworks/SocialNetworks';

import '../../styles/styles.css';
import './Footer.css';

class Footer extends Component {
  render() {
    const { t } = this.props;
    let hideFooter = false;
    const pathName = this.props.location.pathname;
    if (pathName.includes('evaluate')) {
      hideFooter = true;
    }

    return (
      <div>
        <div>
          {!hideFooter ?
            <div id='footer'>
              <SocialNetworks />
              <div className='copyright'>
                <p> {t('FOOTER.TEXT_COPYRIGHT')} </p>
              </div>
            </div> : ''
          }
        </div>
      </div >
    );
  }
}

Footer.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object
};

export default withRouter(withTranslation()(Footer));